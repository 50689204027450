import { Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useStore } from '../stores/useStore';

export const OrderDetails = observer((props: any) => {
    const { mainStore } = useStore()
    const [data, setData] = useState<any>()
    const [loading, setLoading] = useState(true)
    const [errorMsg, setErrorMsg] = useState(null)
    const [activeIndex, setActiveIndex] = useState(0);
    const [products, setProducts] = useState<any>();
    const [allOrderStatus, setAllOrderStatus] = useState<any>([]);
    const [formData, setFormData] = useState({});
    const [refresh, setRefresh] = useState(false)
    const [productTotal, setProductTotal] = useState([]);
    const [orderHistory, setOrderHistory] = useState<any>([]);
    const { id } = useParams();
    const history = useHistory();

    useEffect(() => {
        mainStore.getOrder(Number(id))
            .then(([success, data]) => {
                if (success) {
                    setData(data)
                    setFormData(data)
                    const products = data?.products?.map((item: any) => item)
                    const productTotal = data?.totals
                    setProductTotal(productTotal)
                    setProducts(products)
                } else {
                    setErrorMsg(data)
                }
            })

            .then(() => {
                mainStore.getStatus()
                    .then(([success, data]) => {
                        if (success) {
                            let allOrderStatus = data.data.all_status.map((item: any) => ({ id: item.id, name: item.name }))
                            setAllOrderStatus(allOrderStatus)
                        }
                        else {
                            setErrorMsg(data)
                        }
                    })
            })

            .then(() => {
                mainStore.getOrderHistory(Number(id))
                    .then(([success, data]) => {
                        if (success) {
                            console.log("added")
                            const orderHistory = data.data.map((item: any) => item)
                            setOrderHistory(orderHistory)
                        }
                        else {
                            setErrorMsg(data)
                        }
                    })
            })
            .finally(() => {
                setLoading(false)
                setRefresh(false)
            })

    }, [refresh])


    const productTemplate = (rowData: any) => {
        return <>
            <Link to={`/productform/edit/${rowData.product_id}`} onClick={() => { mainStore.stopLoading = false; mainStore.isCategories = false; mainStore.isDressing = false; mainStore.isTopping = false; mainStore.isApiCall = true; mainStore.isOption = false }} >{rowData.name}</Link>
            <p>{rowData.option.map((item: any) => <li> {item.name + "  " + ":" + "  " + item.value}</li>)}</p>

        </>
    }

    return (

        <div className="p-grid dashboard">
            {loading ? <div className="p-col-12">Loading...</div> :
                errorMsg ? <div className='p-col-12 error-msg'>{errorMsg}</div> :
                    <>
                        <div className="p-col-12">
                            <Card>
                                <Formik
                                    initialValues={formData}
                                    enableReinitialize={true}
                                    validationSchema={yup.object({
                                        order_status_id: yup.number(),
                                        notify: yup.boolean(),
                                        comment: yup.string()
                                    })}
                                    onSubmit={(values: any, { setSubmitting }) => {
                                        // values.notify = values.notify === true ? "1" : "0"
                                        // values.order_id = data.order_id
                                        // values.loyalty_card = data.loyalty_card
                                        // values.total_loyalty_points = data.loyalty_points_redeemed
                                        mainStore.updateOrder(values)
                                            .then(([success, data]) => {
                                                if (success) {
                                                    setRefresh(true)
                                                } else {
                                                    setErrorMsg(data)
                                                }
                                            })
                                    }}
                                >
                                    {formik =>
                                        <Form>
                                            <div className='p-grid'>
                                                <div className="p-col-12">
                                                    <div className="p-grid">
                                                        <div className="p-col">
                                                            <div className='page-heading'>
                                                                <i className="pi pi-arrow-left" style={{ 'fontSize': '1em' }} onClick={() => history.goBack()}></i>
                                                                <div className='inline-spacer' />Order Details
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='p-col-12'>
                                                    <div>
                                                        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                                            <TabPanel header="Order Details">
                                                                <h2></h2>
                                                                <div className='p-grid'>
                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Order ID:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>{data.order_id}</label>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Invoice No.:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>	{data.invoice_prefix}</label>
                                                                            </div>

                                                                        </div>
                                                                    </div>



                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Customer:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>	{data.customer}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Customer Group:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>	{data.customer_group_id}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>E-Mail:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>	{data.email}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Telephone:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>	{data.telephone}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Total:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>€{Number(data.total).toFixed(2)}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Order Status:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>	{data.order_status}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }} >
                                                                                <label>Store Name:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>{data.store_name}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>IP Address:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>	{data.ip}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Date Added:	</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>{moment(data.date_added).format('DD-MM-YYYY HH:hh:mm')}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Date Modified:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>{moment(data.date_modified).format('DD-MM-YYYY HH:hh:mm')}</label>

                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </TabPanel>

                                                            <TabPanel header="Payment Details">
                                                                <h2></h2>
                                                                <div className='p-grid'>
                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>First Name:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>{data.payment_firstname}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Last Name:	</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>{data.payment_lastname}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Address 1:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>{data.payment_address_1}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Address 2:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>{data.payment_address_2}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>City:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>{data.payment_city}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Postcode:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>{data.payment_postcode}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Region / State:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>{data.payment_zone}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Region / State Code:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>{data.payment_zone_code}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Country:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>{data.payment_country}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Payment Method:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>{data.payment_method}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>

                                                            <TabPanel header="Delivery Address">
                                                                <h2></h2>
                                                                <div className='p-grid'>
                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>First Name:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>	{data.shipping_firstname}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Last Name:	</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>{data.shipping_lastname}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Address 1:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>{data.shipping_address_1}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Address 2:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>{data.shipping_address_2}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>City:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>{data.shipping_zone}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Postcode:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>{data.shipping_postcode}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Delivery Time</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>{data.requested_time}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Delivery Method:</label>
                                                                            </div>
                                                                            <div className='p-col-8'>
                                                                                <label>{data.shipping_method}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='p-col-12 p-md-6' >
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                                                <label>Telephone:</label>
                                                                            </div>
                                                                            <div className='p-col-8' >
                                                                                <label>{data.telephone}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>

                                                            < TabPanel header="Products">
                                                                <div className="p-col-12">
                                                                    <DataTable value={products} responsive={true} emptyMessage='No Products available' >
                                                                        <Column sortable={true} field="name" body={productTemplate} header="Product" style={{ width: '30%' }} />
                                                                        <Column sortable={true} field="model" header="Model" />
                                                                        <Column sortable={true} field="quantity" header="Quantity" />
                                                                        <Column sortable={true} field="price" header="Unit Price" />
                                                                        <Column sortable={true} field="total" header="Total" />
                                                                    </DataTable>
                                                                    {productTotal?.map((item: any) => <div className='p-grid p-justify-end' style={{ margin: '20px' }}>{item.title}    :<b>€{Number(item.value).toFixed(2)}</b></div>)}
                                                                </div>

                                                            </TabPanel>

                                                            <TabPanel header="Order Status " >
                                                                <h2></h2>
                                                                <div className='p-grid '>
                                                                    <div className="p-col-12">
                                                                        <DataTable value={orderHistory} responsive={true} emptyMessage='No Details available' paginatorPosition="bottom" rows={5} paginator={true} >
                                                                            <Column sortable={true} field="date_added" body={(rowData: any) => moment(rowData['date_added']).format('DD/MM/YYYY')} header="Date Added" />
                                                                            <Column sortable={true} field="comment" header="Comment" />
                                                                            <Column sortable={true} field="status" header="Status" />
                                                                            <Column sortable={true} field="notify" body={(rowData: any) => rowData['notify'] == "1" ? "Yes" : "No"} header="Customer Notified" />
                                                                        </DataTable>

                                                                    </div>
                                                                    <div className='p-col-12 p-md-6 p-fluid'>
                                                                        <div className='p-grid form-control-group'>
                                                                            <div className='p-col-12'>
                                                                                <label>Order Status:</label>
                                                                            </div>
                                                                            <div className='p-col-12'>
                                                                                <Field name="order_status_id" as={Dropdown} optionLabel="name" optionValue="id" options={allOrderStatus} />
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-12'>
                                                                                <label>Notify Customer:	</label>
                                                                            </div>
                                                                            <div className='p-col-12'>
                                                                                <Field name="notify" type='checkbox' as={Checkbox} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='p-col-12 p-md-6'>
                                                                        <div className='p-grid p-fluid form-control-group'>
                                                                            <div className='p-col-12'>
                                                                                <label>Comment:</label>
                                                                            </div>
                                                                            <div className='p-col-12'>
                                                                                <Field name="comment" as={InputTextarea} />
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='p-col-12'>
                                                                    <div className="p-grid form-action-container">
                                                                        <div className="p-col ">
                                                                            <Button type='submit' disabled={formik.isSubmitting} label="Add History" className='p-button-primary' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>

                                                        </TabView>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>}
                                </Formik>
                            </Card>
                        </div>
                    </>
            }
        </div >
    )
})
