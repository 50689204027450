import { ErrorMessage, Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { SelectButton } from 'primereact/selectbutton';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { useStore } from '../stores/useStore';

const FormErrorMsg = styled.div`
 color: red;
`
export const EndOfDayReport = observer((props: any) => {
    const { mainStore } = useStore()
    const [data, setData] = useState<any>(null)
    const [formData, setFormData] = useState({});
    const [orderData, setOrderData] = useState<any>(null)
    const [paymentData, setPaymentData] = useState<any>(null)
    const [deliveryData, setDeliveryData] = useState<any>(null)
    const [productData, setProductData] = useState<any>(null)
    const [completeOrderData, setCompleteOrderData] = useState<any>(null)
    const [inCompleteOrderData, setIncompleteOrderData] = useState<any>(null)
    const [filter_date_start, setStartDate] = useState<any>(null)
    const [filter_date_end, setEndDate] = useState<any>(null)
    const [filter_time_start, setStartTime] = useState<any>(null)
    const [filter_time_end, setEndTime] = useState<any>(null)
    const [loading, setLoading] = useState(true)
    const [errorMsg, setErrorMsg] = useState(null)
    const [refresh, setRefresh] = useState(true)
    const [dateRange, setDateRange] = useState(false)
    const [value, setValue] = useState("Todays Report");
    const [reportFlag, setReportFlag] = useState(false)
    const [paymentTotal, setPaymentTotal] = useState<any>(null)
    const [deliveryTotal, setDeliveryTotal] = useState<any>(null)

    useEffect(() => {
        if (refresh) {
            setLoading(true)
            mainStore.getTodaySalesReport()
                .then(([success, data]) => {
                    if (success) {
                        setData(data)
                        const order = data?.data?.orderdata?.map((item: any) => item)
                        setOrderData(order)
                        const incompleteOrder = data?.data?.orderdata?.filter((item: any) => item.order_status_id !== "5")
                        setIncompleteOrderData(incompleteOrder)
                        const payment = data?.data?.payments?.map((item: any) => item)
                        setPaymentData(payment)
                        const delivery = data?.data?.shipping?.map((item: any) => item)
                        setDeliveryData(delivery)
                        const completeorder = data?.data?.order_status_total?.filter((item: any) => item.order_status_id === "5")
                        setCompleteOrderData(completeorder)
                        const product = data?.data?.product_report?.map((item: any) => item)
                        setProductData(product)
                    }
                    else {
                        setErrorMsg(data)
                    }
                })

                .finally(() => {
                    setLoading(false)
                    setRefresh(false)
                })
        }
    }, [refresh])

    if (dateRange === true) {
        mainStore.getEndOfDaySalesReport(filter_date_start, filter_date_end, filter_time_start, filter_time_end)
            .then(([success, data]) => {
                if (success) {
                    setDateRange(false)
                    setData(data)
                    const order = data?.data?.orderdata?.map((item: any) => item)
                    setOrderData(order)
                    const incompleteOrder = data?.data?.orderdata?.filter((item: any) => item.order_status_id !== "5")
                    setIncompleteOrderData(incompleteOrder)
                    const payment = data?.data?.payments?.map((item: any) => item)
                    setPaymentData(payment)
                    const delivery = data?.data?.shipping?.map((item: any) => item)
                    setDeliveryData(delivery)
                    const completeorder = data?.data?.order_status_total?.filter((item: any) => item.order_status_id === "5")
                    setCompleteOrderData(completeorder)
                    const product = data?.data?.product_report?.map((item: any) => item)
                    setProductData(product)
                }

                else {
                    setErrorMsg(data)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    // function dateSubmit() {
    //     let startDate = filter_date_start;
    //     setStartDate(startDate)
    //     let endDate = filter_date_end;
    //     setEndDate(endDate)
    //     let startTime = filter_time_start === null ? moment().startOf('day').format('HH:mm:ss') : moment(filter_time_start).format("HH:mm:ss");
    //     setStartTime(startTime)
    //     let endTime = filter_time_end === null ? moment().endOf('day').format("HH:mm:ss") : moment(filter_time_end).format("HH:mm:ss");
    //     setEndTime(endTime)
    //     mainStore.getEndOfDaySalesReport(startDate, endDate, startTime, endTime)
    //         .then(([success, data]) => {
    //             if (success) {
    //                 setReportFlag(false)
    //                 setDateRange(true)
    //                 setLoading(true)
    //             }
    //             else {
    //                 setErrorMsg(data)
    //             }

    //         })
    // }

    function dateSelector(status: any) {
        let time_start = moment().startOf('day').format('HH:mm:ss')
        setStartTime(time_start)
        let time_end = moment().endOf("day").format('HH:mm:ss')
        setEndTime(time_end)
        switch (status) {
            case 'Todays Report':
                setRefresh(true)
                setReportFlag(false)
                break;

            case 'Yesterdays Report':
                let yesterday_start = moment().subtract(1, 'days').format('YYYY-MM-DD')
                let yesterday_end = moment().subtract(1, 'days').format('YYYY-MM-DD')
                setStartDate(yesterday_start)
                setEndDate(yesterday_end)
                setDateRange(true)
                setReportFlag(false)
                break;

            case 'This Week Report':
                let thisweek_start = moment().startOf('week').format('YYYY-MM-DD')
                let thisweek_end = moment().format('YYYY-MM-DD')
                setStartDate(thisweek_start)
                setEndDate(thisweek_end)
                setDateRange(true)
                setReportFlag(false)
                break;

            case 'Last Week Report':
                let lastweek_start = moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD')
                let lastweek_end = moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')
                setStartDate(lastweek_start)
                setEndDate(lastweek_end)
                setDateRange(true)
                setReportFlag(false)
                break;

            case 'This Month Report':
                let month_start = moment().startOf('month').format('YYYY-MM-DD')
                let month_end = moment().endOf('month').format('YYYY-MM-DD')
                setStartDate(month_start)
                setEndDate(month_end)
                setDateRange(true)
                setReportFlag(false)
                break;

            case 'X Report':
                mainStore.getSaveReport()
                    .then(([success, data]) => {
                        if (success) {
                            setReportFlag(false)
                            setData(data)
                            const order = data?.data?.orderdata?.map((item: any) => item)
                            setOrderData(order)
                            const incompleteOrder = data?.data?.orderdata?.filter((item: any) => item.order_status_id !== "5")
                            setIncompleteOrderData(incompleteOrder)
                            const payment = data?.data?.payments?.map((item: any) => item)
                            setPaymentData(payment)
                            const delivery = data?.data?.shipping?.map((item: any) => item)
                            setDeliveryData(delivery)
                            const completeorder = data?.data?.order_status_total?.filter((item: any) => item.order_status_id === "5")
                            setCompleteOrderData(completeorder)
                            const product = data?.data?.product_report?.map((item: any) => item)
                            setProductData(product)
                        }
                        else {
                            setErrorMsg(data)
                        }

                    })
                    .finally(() => {
                        setLoading(false)
                    })
                break;

            case 'Z Report':
                mainStore.getSavedReport()
                    .then(([success, data]) => {
                        if (success) {
                            setReportFlag(true)
                            setData(data)
                            const order = data?.data?.orderdata?.map((item: any) => item)
                            setOrderData(order)
                            const incompleteOrder = data?.data?.orderdata?.filter((item: any) => item.order_status_id !== "5")
                            setIncompleteOrderData(incompleteOrder)
                            const payment = data?.data?.payment_report?.map((item: any) => item)
                            setPaymentData(payment)
                            const totalPayment = data?.data?.payment_total
                            setPaymentTotal(totalPayment)
                            const delivery = data?.data?.shipping_report?.map((item: any) => item)
                            setDeliveryData(delivery)
                            const totalDeliveryPayment = data?.data?.shipping_total
                            setDeliveryTotal(totalDeliveryPayment)
                            const completeorder = data?.data?.order_status_total?.filter((item: any) => item.order_status_id === "5")
                            setCompleteOrderData(completeorder)
                            const product = data?.data?.product_report?.map((item: any) => item)
                            setProductData(product)
                        }
                        else {
                            setErrorMsg(data)
                        }

                    })
                    .finally(() => {
                        setLoading(false)
                    })
                break;

            default:
                return null;
        }

    }

    const header = (
        <div className="table-header " style={{ textAlign: "center" }}>
            Orders
        </div>
    );

    const incompleteHeader = (
        <div className="table-header " style={{ textAlign: "center" }}>
            Incomplete Orders
        </div>
    );

    const footer = (
        <div className="p-grid ">
            <div className="p-col-12">
                <div className="p-grid p-justify-between">
                    <div className="p-col-10">
                        Total Amount
                    </div>
                    <div className="p-col-2" style={{ textAlign: "right" }}>

                        {reportFlag === false ? data?.data?.totalamount : paymentTotal}
                    </div>
                </div>

            </div>
        </div>
    );

    const deliveryFooter = (
        <div className="p-grid ">
            <div className="p-col-12">
                <div className="p-grid p-justify-between">
                    <div className="p-col-10">
                        Total Amount
                    </div>
                    <div className="p-col-2" style={{ textAlign: "right" }}>
                        {reportFlag === false ? data?.data?.totalamount : deliveryTotal}
                    </div>
                </div>

            </div>
        </div>
    );

    const options = ['Todays Report', 'Yesterdays Report', "This Week Report", "Last Week Report", "This Month Report", "X Report", "Z Report"];

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="p-grid p-justify-between">
                    <div className="p-col-8 p-md-8">
                        <div className='page-heading'>Sales Report</div>
                    </div>
                    <div className="p-col-4 p-sm-2 p-md-2 p-lg-1 right p-fluid" >
                        <Link to="/ZReportList">
                            <Button label="List" className="p-button-primary" />
                        </Link>
                    </div>
                </div>
            </div>

            <div className="p-col-12">
                <Card>
                    {loading ? <div>Loading...</div> :
                        errorMsg ? <div className='error-msg'>{errorMsg} <Button className='p-button-secondary' label='Cancel' onClick={(e) => { setErrorMsg(null); setRefresh(true); setValue(options[0]) }} /> </div> :
                            <div className='p-grid'>

                                <div className='p-grid p-col-12 p-justify-center' style={{ marginBottom: "10px" }}>
                                    <SelectButton value={value} options={options} onChange={(e) => { setValue(e.value); dateSelector(e.value); setLoading(true) }} />
                                    <div className='inline-spacer' />

                                </div>

                                <Formik
                                    initialValues={{ filter_date_start: "", filter_date_end: "", filter_time_start: "", filter_time_end: "" }}
                                    enableReinitialize={true}
                                    validationSchema={yup.object({
                                        filter_date_start: yup.date()
                                            .required("Required"),
                                        filter_date_end: yup.date().min(yup.ref('filter_date_start'), "End date can't be before start date")
                                            .required("Required"),
                                        filter_time_start: yup.date()
                                            .required("Required"),
                                        filter_time_end: yup.date()
                                            .required("Required")
                                    })}

                                    // // 
                                    // startDate: date(),
                                    // endDate: date().min(
                                    //     yup.ref('startDate'),
                                    //     "end date can't be before start date"
                                    //   )

                                    onSubmit={(values: any, { setSubmitting }) => {
                                        setLoading(true)
                                        let startDate = (moment(values.filter_date_start).format("YYYY-MM-DD"));
                                        setStartDate(startDate)
                                        let endDate = (moment(values.filter_date_end).format("YYYY-MM-DD"));
                                        setEndDate(endDate)
                                        let startTime = (moment(values.filter_time_start).format("HH:mm:ss"));
                                        setStartTime(startTime)
                                        let endTime = (moment(values.filter_time_end).format("HH:mm:ss"));
                                        setEndTime(endTime)

                                        mainStore.getEndOfDaySalesReport(startDate, endDate, startTime, endTime)
                                            .then(([success, data]) => {
                                                if (success) {
                                                    setData(data)
                                                    const order = data?.data?.orderdata?.map((item: any) => item)
                                                    setOrderData(order)
                                                    const incompleteOrder = data?.data?.orderdata?.filter((item: any) => item.order_status_id !== "5")
                                                    setIncompleteOrderData(incompleteOrder)
                                                    const payment = data?.data?.payments?.map((item: any) => item)
                                                    setPaymentData(payment)
                                                    const delivery = data?.data?.shipping?.map((item: any) => item)
                                                    setDeliveryData(delivery)
                                                    const completeorder = data?.data?.order_status_total?.filter((item: any) => item.order_status_id === "5")
                                                    setCompleteOrderData(completeorder)
                                                    const product = data?.data?.product_report?.map((item: any) => item)
                                                    setProductData(product)
                                                }
                                                else {
                                                    setErrorMsg(data)
                                                }
                                            })
                                            .finally(() => {
                                                setLoading(false)
                                            })

                                    }}
                                >
                                    {formik =>
                                        <Form>
                                            <div className="p-col-12 " style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                <div className='p-grid p-justify-between'>

                                                    <div className='p-col-12 p-sm-6 p-md-6 p-lg-2'>
                                                        <div className='p-grid p-fluid form-control-group '>
                                                            <div className='p-col-3' >
                                                                <label>Start Date:</label>
                                                            </div>
                                                            <div className='p-col-9'>
                                                                <Field name="filter_date_start" dateFormat="dd-mm-yy" maxDate={moment().toDate()} as={Calendar} showIcon readOnlyInput />
                                                                {/* <Calendar name="filter_date_start" onSelect={(e: any) => setStartDate(moment(e.value).format("YYYY-MM-DD"))} dateFormat="yy/mm/dd" required /> */}
                                                            </div>
                                                            <div className='p-col-12'>
                                                                <ErrorMessage name='filter_date_start' component={FormErrorMsg} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='p-col-12 p-sm-6 p-md-6 p-lg-2'>
                                                        <div className='p-grid p-fluid form-control-group'>
                                                            <div className='p-col-3'>
                                                                <label>Start Time:</label>
                                                            </div>
                                                            <div className='p-col-9'>
                                                                <Field name="filter_time_start" as={Calendar} timeOnly showSeconds readOnlyInput showIcon icon="pi pi-clock" />
                                                                {/* <Calendar name="filter_time_start" value={filter_time_start} onChange={(e: any) => setStartTime(e.value)} timeOnly showTime showSeconds hourFormat="24" /> */}
                                                            </div>
                                                            <div className='p-col-12'>
                                                                <ErrorMessage name='filter_time_start' component={FormErrorMsg} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='p-col-12 p-sm-6 p-md-4 p-lg-2'>
                                                        <div className='p-grid p-fluid form-control-group'>
                                                            <div className='p-col-3' >
                                                                <label>End Date:</label>
                                                            </div>
                                                            <div className='p-col-9'>
                                                                <Field name="filter_date_end" dateFormat="dd-mm-yy" maxDate={moment().toDate()} as={Calendar} showIcon readOnlyInput />
                                                                {/* <Calendar name="filter_date_end" onSelect={(e: any) => setEndDate(moment(e.value).format("YYYY-MM-DD"))} dateFormat="yy/mm/dd" /> */}

                                                            </div>
                                                            <div className='p-col-12'>
                                                                <ErrorMessage name='filter_date_end' component={FormErrorMsg} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='p-col-12 p-sm-6 p-md-4 p-lg-2'>
                                                        <div className='p-grid p-fluid form-control-group'>
                                                            <div className='p-col-3' >
                                                                <label>End Time:</label>
                                                            </div>
                                                            <div className='p-col-9'>
                                                                <Field name="filter_time_end" as={Calendar} timeOnly showSeconds readOnlyInput showIcon icon="pi pi-clock" />
                                                                {/* <Calendar name="filter_time_end" value={filter_time_end} onChange={(e: any) => setEndTime(e.value)} timeOnly showTime showSeconds hourFormat="24" /> */}

                                                            </div>
                                                            <div className='p-col-12'>
                                                                <ErrorMessage name='filter_time_end' component={FormErrorMsg} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='p-col-12 p-md-2 p-lg-2 '>
                                                        <div className='p-grid p-fluid form-control-group '>
                                                            <Button label="Filter" type="submit"
                                                                // onClick={() => {
                                                                //     dateSubmit();
                                                                // }}
                                                                disabled={formik.isSubmitting}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </Form>}
                                </Formik>
                                <div className="p-grid" >
                                    <div className="pcol-12 p-md-5">
                                        <div className="p-grid p-dir-col">
                                            <div className="p-col-12 p-md-12">
                                                <Card title="Demo" >
                                                    <div className='p-grid '>
                                                        <div className='p-col-12' >
                                                            <div className='p-grid '>
                                                                <div className='p-col-4' >Store Name :</div>
                                                                <div className='p-col-8'><b> {data?.data?.store_name}</b> </div>
                                                            </div>
                                                        </div>

                                                        <div className='p-col-12'>
                                                            <div className='p-grid '>
                                                                <div className='p-col-4' >Date Start :</div>
                                                                <div className='p-col-8'><b>{data?.date_start}</b> </div>

                                                            </div>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <div className='p-grid '>
                                                                <div className='p-col-4'>Date End : </div>
                                                                <div className='p-col-8'><b>{data?.date_end}</b> </div>

                                                            </div>
                                                        </div>

                                                        {(reportFlag === false) ?
                                                            <div className='p-col-12'>
                                                                <div className='p-grid '>
                                                                    <div className='p-col-4' >Report Taken :</div>
                                                                    <div className='p-col-8'><b>{data?.data?.report_token}</b> </div>
                                                                </div>
                                                            </div> : ""}

                                                        <div className='p-col-12'>
                                                            <div className='p-grid '>
                                                                <div className='p-col-4' >User</div>
                                                                <div className='p-col-8'><b>{reportFlag === true ? data.data.user_name : data.data.user} </b></div>
                                                            </div>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <div className='p-grid '>
                                                                <div className='p-col-4' >System</div>
                                                                <div className='p-col-8'><b>{reportFlag === true ? data.data.system_name : data?.data?.system}</b></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>

                                            </div>

                                            <div className="p-col-12 p-md-12">
                                                <DataTable value={paymentData} footer={footer} emptyMessage='No Payment details available' responsive={true}>
                                                    <Column field="name" header="Payment" style={{ width: '50%' }} />
                                                    <Column field="count" header="No:" />
                                                    <Column field="total" header="Total" bodyStyle={{ textAlign: 'right' }} />
                                                </DataTable>
                                            </div>

                                            <div className="p-col-12 p-md-12">
                                                <DataTable value={deliveryData} footer={deliveryFooter} emptyMessage='No delivery details available' responsive={true}>
                                                    <Column field="name" header="Delivery" style={{ width: '50%' }} />
                                                    <Column field="count" header="No:" />
                                                    <Column field="total" header="Total" bodyStyle={{ textAlign: 'right' }} />
                                                </DataTable>
                                            </div>

                                            <div className="p-col-12 p-md-12">
                                                <DataTable value={productData} emptyMessage='No Product available' responsive={true}>
                                                    <Column field="product_name" header="Product Name" style={{ width: '50%' }} />
                                                    <Column field="qty" header="Quantity" bodyStyle={{ textAlign: 'right' }} />
                                                    <Column field="price" header="Price" bodyStyle={{ textAlign: 'right' }} />
                                                </DataTable>
                                            </div>

                                            <div className="p-col-12 p-md-12">
                                                <DataTable value={completeOrderData} emptyMessage='No Orders available' responsive={true}>
                                                    <Column field="name" header="Completed Order Total" />
                                                    <Column field="total" header="Price" bodyStyle={{ textAlign: 'right' }} />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-7 ">
                                        <div className="p-grid p-dir-col">
                                            <div className="p-col-12 p-md-12">
                                                <DataTable value={orderData} header={header} emptyMessage='No Orders available' paginatorPosition="bottom" rows={10} paginator={true} responsive={true}>
                                                    <Column field="order_id" header="Order ID" />
                                                    <Column field="store_name" header="Store" />
                                                    <Column field="shipping" header="Shipping " />
                                                    <Column field="time" body={(rowData: any) => moment(rowData.time).format('DD-MM-YYYY hh:mm:ss')} header="Requested Time" />
                                                    <Column field="payment" header="Payment" />
                                                    <Column field="amount" header="Total" bodyStyle={{ textAlign: 'right' }} />
                                                </DataTable>
                                            </div>
                                            <div className="p-col-12 p-md-12">
                                                <DataTable value={inCompleteOrderData} header={incompleteHeader} emptyMessage='No Orders available' paginatorPosition="bottom" rows={10} paginator={true} responsive={true}>
                                                    <Column field="order_id" header="Order ID" />
                                                    <Column field="store_name" header="Store" />
                                                    <Column field="shipping" header="Shipping " />
                                                    <Column field="time" body={(rowData: any) => moment(rowData.time).format('DD-MM-YYYY hh:mm:ss')} header="Requested Time" />
                                                    <Column field="payment" header="Payment" />
                                                    <Column field="amount" header="Total" bodyStyle={{ textAlign: 'right' }} />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                    }
                </Card>
            </div>
        </div >
    )
})
