import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Button } from 'primereact/button'
import { observer } from "mobx-react-lite";
import { useStore } from '../stores/useStore'
import { Card } from 'primereact/card';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { CategoryForm } from './CategoryForm';
import { ProductForm } from './ProductForm';
import { Menu } from 'primereact/menu';
import { FiPlus } from 'react-icons/fi';
import { Deals } from './Deals';

export const MenuPage = observer((props: any) => {
    const [errorMsg, setErrorMsg] = useState(null)
    const { mainStore } = useStore()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<any>()
    const [categoryOpt, setCategoryOpt] = useState<any>([])
    const [category, setCategory] = useState<any>()
    const [categoryId, setCategoryId] = useState<any>()
    const [parentId, setParentId] = useState<any>()
    const [productId, setProductId] = useState<any>()
    const [refresh, setRefresh] = useState(true)
    const [visible, setVisible] = useState(false)
    const [filterParentData, setFilterParentData] = useState<any>()
    const [stores, setStores] = useState();
    const [storesOpt, setStoresOpt] = useState([]);
    const [propsParentId, setPropsParentId] = useState<any>();
    const bottomMenu = useRef(null);
    const middleMenu = useRef(null);
    // const myRef = useRef(null);

    // const executeScroll = () => (myRef as any).current.scrollIntoView();

    useEffect(() => {

        if (refresh) {
            setLoading(true)
            mainStore.getStores()
                .then(([success, data]) => {
                    if (success) {
                        let store = data?.map((item: any) => ({ name: item?.name, value: item?.store_id }))
                        // console.log("stores", store)
                        setStoresOpt(store)
                        setStores(store[0].value)
                        return mainStore.getMenus(0, store[0].value)
                    } else {
                        throw new Error(data)
                    }
                })
                .then(([success, data]) => {
                    if (success) {
                        // console.log("category", data.categories[0].categories)                       
                        let parentCategoryItems = data?.categories?.map((item: any) => ({ name: item?.name, value: item?.category_id }))
                        setCategory(parentCategoryItems[0]?.value)
                        setCategoryOpt(parentCategoryItems)
                        setData(data?.categories)
                        let filterData = data?.categories?.filter((item: any) => item?.category_id == parentCategoryItems[0]?.value)
                        setFilterParentData(filterData)
                    }
                })
                .catch(err => {
                    setErrorMsg(err)
                })
                .finally(() => {
                    setLoading(false)
                    setRefresh(false)
                })

        } else if (mainStore.refresh) {
            mainStore.getMenus(0, stores)
                .then(([success, data]) => {
                    if (success) {
                        let parentCategoryItems = data?.categories?.map((item: any) => ({ name: item?.name, value: item?.category_id }))
                        setCategory(parentCategoryItems[0]?.value)
                        setCategoryOpt(parentCategoryItems)
                        setData(data?.categories)
                        let filterData = data?.categories?.filter((item: any) => item?.category_id == parentCategoryItems[0]?.value)
                        setFilterParentData(filterData)
                        mainStore.refresh = false
                        // executeScroll()
                    } else {
                        setErrorMsg(data)
                    }
                })
        }

    }, [refresh, mainStore.refresh])

    const handleParentCategoryChange = (id: any) => {
        let filterData = data?.filter((item: any) => item?.category_id == id)
        setFilterParentData(filterData)
    }

    const handleStoreChange = (id: any) => {
        setLoading(true)
        mainStore.getMenus(0, id)
            .then(([success, data]) => {
                if (success) {
                    let parentCategoryItems = data?.categories?.map((item: any) => ({ name: item?.name, value: item?.category_id }))
                    setCategory(parentCategoryItems[0]?.value)
                    setCategoryOpt(parentCategoryItems)
                    setData(data?.categories)
                    let filterData = data?.categories?.filter((item: any) => item.category_id == parentCategoryItems[0]?.value)
                    setFilterParentData(filterData)
                } else {
                    setErrorMsg(data)
                }
            }).finally(() => {
                setLoading(false)
            })
    }

    // console.log("stores out", stores)    

    let parentCategoryName: any = ''
    let child1CategoryName: any = ''
    let child2CategoryName: any = ''
    let child3CategoryName: any = ''
    let child4CategoryName: any = ''

    const handleParentCategoryName = (id: any) => {
        data?.map((item: any) => {
            if (item.category_id == id) {
                parentCategoryName = item.name
            }
        })
    }

    const handleChild1CategoryName = (id: any) => {
        data?.map((item: any) => {
            if (item?.categories?.length >= 1) {
                item?.categories?.map((item: any) => {
                    if (item?.category_id == id) {
                        child1CategoryName = item.name
                    }
                })
            }
        })
    }

    const handleChild2CategoryName = (id: any) => {

        data?.map((item: any) => {
            if (item?.categories?.length >= 1) {
                item?.categories?.map((item: any) => {

                    if (item?.categories?.length >= 1) {
                        item?.categories?.map((item: any) => {

                            if (item.category_id == id) {
                                child2CategoryName = item.name
                            }

                        })
                    }
                })
            }
        })
    }

    const handleChild3CategoryName = (id: any) => {
        data?.map((item: any) => {

            if (item?.categories.length >= 1) {
                item?.categories?.map((item: any) => {

                    if (item?.categories?.length >= 1) {
                        item?.categories?.map((item: any) => {

                            if (item?.categories?.length >= 1) {
                                item?.categories?.map((item: any) => {

                                    if (item?.category_id == id) {
                                        child3CategoryName = item.name
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })
    }

    const handleChild4CategoryName = (id: any) => {
        data?.map((item: any) => {

            if (item?.categories.length >= 1) {
                item?.categories?.map((item: any) => {

                    if (item?.categories?.length >= 1) {
                        item?.categories?.map((item: any) => {

                            if (item?.categories?.length >= 1) {
                                item?.categories?.map((item: any) => {

                                    if (item?.categories?.length >= 1) {
                                        item?.categories?.map((item: any) => {

                                            if (item?.category_id == id) {
                                                child4CategoryName = item.name
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })
    }

    const findLastCategoryId = (data: any) => {
        if (data.categories.length > 0) {
            findLastCategoryId(data.categories[data.categories.length - 1])
        } else {
            setPropsParentId(data.category_id)
            mainStore.menuAddDialogBox = true;
        }

    }

    const onHide = () => {
        setVisible(false)
    }
    // console.log("menu values 1", data)

    const bottomItems = [
        {
            label: 'Add Category',
            command: () => {
                setPropsParentId(category); mainStore.menuAddDialogBox = true; mainStore.menuAddCategory = true
            }
        },
        {
            label: 'Add Sub Category',
            command: () => {
                findLastCategoryId(filterParentData[0]); mainStore.menuAddCategory = true
            }
        },
        {
            label: 'Add Product',
            command: () => {
                findLastCategoryId(filterParentData[0]); mainStore.menuAddProduct = true
            }
        }
    ]

    const middleItems = [
        {
            label: 'Add Category',
            command: () => {
                setPropsParentId(parentId); mainStore.menuAddDialogBox = true; mainStore.menuAddCategory = true
            }
        },
        {
            label: 'Add Sub Category',
            command: () => {
                setPropsParentId(categoryId); mainStore.menuAddDialogBox = true; mainStore.menuAddCategory = true
            }
        },
        {
            label: 'Add Product',
            command: () => {
                setPropsParentId(categoryId); mainStore.menuAddDialogBox = true; mainStore.menuAddProduct = true
            }
        }
    ]

    return (
        <div className="p-grid ">
            <div className="p-col-12">
                <div className="p-d-flex p-flex-column" >

                    {loading ? <div>Loading...</div> :
                        errorMsg ? <div className='error-msg'>{errorMsg}</div> :

                            <div className="p-grid p-fluid">
                                <div className="p-grid p-col-12">
                                    {/* <div className="p-col-4">
                            <Card title="Menu" style={{ marginTop: "10px", align: "center" }}>
                                {menuData?.map((item: any, index: any) =>
                                    <Button label={item?.name} href="#6" className={index % 2 == 0 ? "p-button-info" : "p-button-primary "} style={{ marginTop: "5px" }} />
                                )}
                            </Card>

                        </div> */}

                                    <div className="p-col-12">

                                        <Fragment>

                                            <ScrollPanel style={{ width: '100%', height: '520px' }}>

                                                <div className="p-grid p-justify-between">
                                                    <div className="p-col-12 p-md-4 p-lg-6 ">
                                                        <div className='page-heading'>  Menu</div>
                                                    </div>

                                                    <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-fluid" >

                                                        <div className="p-grid p-fluid">
                                                            <div className="p-col-3 p-sm-3 p-md-3 p-lg-3 ">
                                                                <div>Store</div>
                                                            </div>
                                                            <div className="p-col-9 p-sm-9 p-md-8 p-lg-9">
                                                                <Dropdown value={stores} options={storesOpt} optionLabel="name" onChange={(e) => { setStores(e.value); handleStoreChange(e.value) }} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-fluid">

                                                        <div className="p-grid">
                                                            <div className="p-col-3 p-sm-3 p-md-4 p-lg-4">
                                                                Category
                                                            </div>
                                                            <div className="p-col-9 p-sm-9 p-md-8 p-lg-8">
                                                                <Dropdown value={category} options={categoryOpt} optionLabel="name" onChange={(e) => { setCategory(e.value); handleParentCategoryChange(e.value) }} placeholder={!category ? ' No data available' : ''} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                {data?.length == 0 ?

                                                    <Card>
                                                        <div className="p-grid p-fluid">
                                                            <div style={{ marginLeft: '5px' }}>
                                                                Add New Category
                                                             </div>
                                                            <div style={{ marginLeft: '10px' }}>
                                                                <FiPlus name="name" color="red" size="20px" onClick={() => { setPropsParentId(''); mainStore.menuAddDialogBox = true; mainStore.menuAddCategory = true }} />
                                                            </div>
                                                        </div>
                                                    </Card> :


                                                    <Fragment>
                                                        {filterParentData?.map((item: any, index: any) =>
                                                            <Fragment>

                                                                {/* ------------------------------- Parent Category ------------------------------------------ */}

                                                                <div className="p-d-flex p-flex-column card" style={{ marginTop: '10px', height: '50px', background: '#b2ebf2' }} >
                                                                    <div className='p-grid '>
                                                                        <div className='p-col-10 p-md-11'>
                                                                            {/* {console.log("div index", index + "-->" + item.name)} */}
                                                                            <div style={{ fontWeight: 'normal', fontSize: '15px', color: "#d84315" }}>{item?.name}</div>
                                                                        </div>
                                                                        {/* <div className='p-col-2 p-md-1'>
                                                            <div><Button style={{ fontSize: "10px" }} icon="pi pi-plus" className="p-button-rounded p-button-success" onClick={() => console.log("event")} /></div>
                                                        </div> */}
                                                                        <div className='p-col-2 p-md-1'>
                                                                            <div>
                                                                                <a onClick={() => { setCategoryId(item?.category_id); mainStore.menuCategory = true; mainStore.menuDialogBox = true }} >Edit</a>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/*--------------------- Products of Parent category --------------------------------------- */}


                                                                {item?.products?.map((item: any) =>
                                                                    <div className="p-d-flex p-flex-column card" style={{ marginTop: '10px', height: '50px' }} >
                                                                        <div className='p-grid p-align-center'>
                                                                            <div className='p-col-10 p-md-11'>
                                                                                <div>{item?.name} <b>{item?.deal > 0 ? "(Deal)" : ''}</b></div>
                                                                            </div>
                                                                            <div className='p-col-2 p-md-1'>
                                                                                <div>
                                                                                    {item?.deal > 0 ?
                                                                                        <a onClick={() => { setProductId(item?.product_id); mainStore.menuDeal = true; mainStore.menuDialogBox = true }} >Edit</a> :
                                                                                        <a onClick={() => { setProductId(item?.product_id); mainStore.menuProduct = true; mainStore.menuDialogBox = true }} >Edit</a>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                {/* ---------------------- Child 1 Category of Parent Category ----------------------------- */}



                                                                {item?.categories?.map((item1: any) =>
                                                                    <Fragment>
                                                                        {handleParentCategoryName(item1?.parent_id)}

                                                                        <div className="p-d-flex p-flex-column card" style={{ marginTop: '10px', height: '50px', background: '#b2ebf2' }} >

                                                                            <div className='p-grid p-align-center'>
                                                                                <div className='p-col-8 p-md-10'>
                                                                                    <div style={{ fontWeight: 'normal', fontSize: "15px", color: "#d84315", }}>

                                                                                        {parentCategoryName + ">" + item1?.name}

                                                                                    </div>
                                                                                </div>

                                                                                <div className='p-col-2 p-md-1'>
                                                                                    <div>
                                                                                        <a onClick={(event: any) => { setCategoryId(item1.category_id); setParentId(item1.parent_id); (middleMenu as any).current.toggle(event); }} aria-controls="popup_middleMenu" aria-haspopup style={{ color: "#e65100" }}>Add</a>
                                                                                    </div>
                                                                                </div>

                                                                                <div className='p-col-2 p-md-1'>
                                                                                    <div>
                                                                                        <a onClick={() => { setCategoryId(item1?.category_id); mainStore.menuCategory = true; mainStore.menuDialogBox = true }} style={{ color: "#e65100" }} >Edit</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        {/* ----------------------  Product of Child 1 Category ----------------------------- */}

                                                                        {item1?.products?.map((item: any) =>
                                                                            <div className="p-d-flex p-flex-column card" style={{ marginTop: '10px', height: '50px' }} >
                                                                                <div className='p-grid p-align-center'>
                                                                                    <div className='p-col-10 p-md-11'>
                                                                                        <div>{item?.name} <b>{item?.deal > 0 ? "(Deal)" : ''}</b></div>
                                                                                    </div>

                                                                                    <div className='p-col-2 p-md-1'>
                                                                                        <div>
                                                                                            {item?.deal > 0 ?
                                                                                                <a onClick={() => { setProductId(item?.product_id); mainStore.menuDeal = true; mainStore.menuDialogBox = true }} >Edit</a> :
                                                                                                <a onClick={() => { setProductId(item?.product_id); mainStore.menuProduct = true; mainStore.menuDialogBox = true }} >Edit</a>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}

                                                                        {/* ---------------------- Child 2 Category of Child 1 Category ----------------------------- */}


                                                                        {item1?.categories?.map((item2: any) =>

                                                                            <Fragment>
                                                                                {handleChild1CategoryName(item2?.parent_id)}

                                                                                <div className="p-d-flex p-flex-column card" style={{ marginTop: '10px', background: '#b2ebf2' }} >
                                                                                    <div className='p-grid p-align-center'>
                                                                                        <div className='p-col-8 p-md-10'>
                                                                                            <div style={{ fontWeight: 'normal', fontSize: "15px", color: "#d84315" }}>

                                                                                                {parentCategoryName + ">" + child1CategoryName + ">" + item2?.name}

                                                                                            </div>
                                                                                        </div>

                                                                                        <div className='p-col-2 p-md-1'>
                                                                                            <div>
                                                                                                <a onClick={(event: any) => { setCategoryId(item2.category_id); setParentId(item2.parent_id); (middleMenu as any).current.toggle(event); }} aria-controls="popup_middleMenu" aria-haspopup style={{ color: "#e65100" }}>
                                                                                                    Add
                                                                                        </a>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className='p-col-2 p-md-1'>
                                                                                            <div>
                                                                                                <a onClick={() => { setCategoryId(item2?.category_id); mainStore.menuCategory = true; mainStore.menuDialogBox = true }} style={{ color: "#e65100" }} >Edit</a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                {/* ---------------------- Product of Child 2 Category ----------------------------- */}

                                                                                {item2?.products?.map((item: any) =>
                                                                                    <div className="p-d-flex p-flex-column card" style={{ marginTop: '10px', height: '50px' }} >
                                                                                        <div className='p-grid p-align-center'>
                                                                                            <div className='p-col-10 p-md-11'>
                                                                                                <div>{item?.name} <b>{item?.deal > 0 ? "(Deal)" : ''}</b></div>
                                                                                            </div>

                                                                                            <div className='p-col-2 p-md-1'>
                                                                                                <div>
                                                                                                    {item?.deal > 0 ?
                                                                                                        <a onClick={() => { setProductId(item?.product_id); mainStore.menuDeal = true; mainStore.menuDialogBox = true }} >Edit</a> :
                                                                                                        <a onClick={() => { setProductId(item?.product_id); mainStore.menuProduct = true; mainStore.menuDialogBox = true }} >Edit</a>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )}

                                                                                {/* ---------------------- Child 3 Category of Child 2 Category ----------------------------- */}

                                                                                {item2?.categories?.map((item3: any) =>

                                                                                    <Fragment>
                                                                                        {handleChild2CategoryName(item3?.parent_id)}

                                                                                        {/* {console.log("item'3", item3)} */}

                                                                                        <div className="p-d-flex p-flex-column card" style={{ marginTop: '10px', background: '#b2ebf2' }} >
                                                                                            <div className='p-grid p-align-center'>
                                                                                                <div className='p-col-12 p-md-10'>
                                                                                                    <div style={{ fontWeight: 'normal', fontSize: "15px", color: "#d84315" }} id={item3.category_id}>

                                                                                                        {parentCategoryName + ">" + child1CategoryName + ">" + child2CategoryName + ">" + item3?.name}

                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='p-col-3   p-md-1'>
                                                                                                    <div>
                                                                                                        <a onClick={(event: any) => { setCategoryId(item3.category_id); setParentId(item3.parent_id); (middleMenu as any).current.toggle(event); }} aria-controls="popup_middleMenu" aria-haspopup style={{ color: "#e65100" }} >Add</a>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='p-col-3 p-md-1'>
                                                                                                    <div>
                                                                                                        <a onClick={() => { setCategoryId(item3?.category_id); mainStore.menuCategory = true; mainStore.menuDialogBox = true }} style={{ color: "#e65100" }} >Edit</a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        {/* ---------------------- Product of Child 3 Category ----------------------------- */}

                                                                                        {item3?.products?.map((item: any) =>
                                                                                            <div className="p-d-flex p-flex-column card" style={{ marginTop: '10px', height: '50px' }} >
                                                                                                <div className='p-grid p-align-center'>
                                                                                                    <div className='p-col-10 p-md-11'>
                                                                                                        <div>{item?.name} <b>{item?.deal > 0 ? "(Deal)" : ''}</b></div>
                                                                                                    </div>

                                                                                                    <div className='p-col-2 p-md-1'>
                                                                                                        <div>
                                                                                                            {item?.deal > 0 ?
                                                                                                                <a onClick={() => { setProductId(item?.product_id); mainStore.menuDeal = true; mainStore.menuDialogBox = true }} >Edit</a> :
                                                                                                                <a onClick={() => { setProductId(item?.product_id); mainStore.menuProduct = true; mainStore.menuDialogBox = true }} >Edit</a>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}

                                                                                        {/* ---------------------- Child 4 Category of Child 3 Category ----------------------------- */}

                                                                                        {item3?.categories?.map((item4: any) =>

                                                                                            <Fragment>

                                                                                                {handleChild3CategoryName(item4?.parent_id)}

                                                                                                <div className="p-d-flex p-flex-column card" style={{ marginTop: '10px', background: '#b2ebf2' }} >
                                                                                                    <div className='p-grid p-align-center'>
                                                                                                        <div className='p-col-12 p-md-10'>
                                                                                                            <div style={{ fontWeight: 'normal', fontSize: "15px", color: "#d84315", marginTop: "10px" }}>

                                                                                                                {parentCategoryName + ">" + child1CategoryName + ">" + child2CategoryName + ">" + child3CategoryName + ">" + item4?.name}

                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='p-col-3 p-md-1'>
                                                                                                            <div>
                                                                                                                <a onClick={(event: any) => { setCategoryId(item4?.category_id); setParentId(item4?.parent_id); (middleMenu as any).current.toggle(event); }} aria-controls="popup_middleMenu" aria-haspopup style={{ color: "#e65100" }}>Add</a>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className='p-col-3 p-md-1'>
                                                                                                            <div>
                                                                                                                <a onClick={() => { setCategoryId(item4?.category_id); mainStore.menuCategory = true; mainStore.menuDialogBox = true }} style={{ color: "#e65100" }}>Edit</a>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                {/* ---------------------- Product of Child 4 Category ----------------------------- */}


                                                                                                {item4?.products?.map((item: any) =>
                                                                                                    <div className="p-d-flex p-flex-column card" style={{ marginTop: '10px', height: '50px' }} >
                                                                                                        <div className='p-grid p-align-center'>
                                                                                                            <div className='p-col-10 p-md-11'>
                                                                                                                <div>{item4?.name} <b>{item?.deal > 0 ? "(Deal)" : ''}</b></div>
                                                                                                            </div>

                                                                                                            <div className='p-col-2 p-md-1'>
                                                                                                                <div>
                                                                                                                    {item?.deal > 0 ?
                                                                                                                        <a onClick={() => { setProductId(item?.product_id); mainStore.menuDeal = true; mainStore.menuDialogBox = true }} >Edit</a> :
                                                                                                                        <a onClick={() => { setProductId(item?.product_id); mainStore.menuProduct = true; mainStore.menuDialogBox = true }} >Edit</a>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )}


                                                                                                {/* ------------------- Category and Product(Optional)---------------------- */}


                                                                                                {item4?.categories?.map((item5: any) =>
                                                                                                    <Fragment>
                                                                                                        {handleChild4CategoryName(item5.parent_id)}
                                                                                                        <div className="p-d-flex p-flex-column card" style={{ marginTop: '10px', background: '#b2ebf2' }} >
                                                                                                            <div className='p-grid p-align-center'>
                                                                                                                <div className='p-col-12 p-md-10'>
                                                                                                                    <div style={{ fontWeight: 'normal', fontSize: "15px", color: "#d84315", marginTop: "10px" }}>

                                                                                                                        {parentCategoryName + ">" + child1CategoryName + ">" + child2CategoryName + ">" + child3CategoryName + ">" + child4CategoryName + ">" + item5?.name}

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className='p-col-3 p-md-1'>
                                                                                                                    <div>
                                                                                                                        <a onClick={(event: any) => { setCategoryId(item5.category_id); setParentId(item5.parent_id); (middleMenu as any).current.toggle(event); }} aria-controls="popup_middleMenu" aria-haspopup style={{ color: "#e65100" }}>Add</a>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                                <div className='p-col-3 p-md-1'>
                                                                                                                    <div>
                                                                                                                        <a onClick={() => { setCategoryId(item5?.category_id); mainStore.menuCategory = true; mainStore.menuDialogBox = true }} style={{ color: "#e65100" }}>Edit</a>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        {item5?.products?.map((item: any) =>
                                                                                                            <div className="p-d-flex p-flex-column card" style={{ marginTop: '10px', height: '50px' }} >
                                                                                                                <div className='p-grid p-align-center'>
                                                                                                                    <div className='p-col-10 p-md-11'>
                                                                                                                        <div>{item?.name} <b>{item?.deal > 0 ? "(Deal)" : ''}</b></div>
                                                                                                                    </div>

                                                                                                                    <div className='p-col-2 p-md-1'>
                                                                                                                        <div>
                                                                                                                            {item?.deal > 0 ?
                                                                                                                                <a onClick={() => { setProductId(item?.product_id); mainStore.menuDeal = true; mainStore.menuDialogBox = true }} >Edit</a> :
                                                                                                                                <a onClick={() => { setProductId(item?.product_id); mainStore.menuProduct = true; mainStore.menuDialogBox = true }} >Edit</a>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}

                                                                                                    </Fragment>
                                                                                                )}

                                                                                            </Fragment>
                                                                                        )}

                                                                                    </Fragment>
                                                                                )}

                                                                            </Fragment>

                                                                        )}

                                                                    </Fragment>

                                                                    /*-------------- End of Child 1 Category -----------*/

                                                                )}
                                                            </Fragment>

                                                            /* ------------- End of Parent Category ------------- */
                                                        )}
                                                        {/* <div ref={myRef}></div> */}

                                                    </Fragment>

                                                }

                                            </ScrollPanel>

                                            {data?.length != 0 &&
                                                <div className="p-grid fluid">
                                                    <div className="p-col-12 fluid">
                                                        <div className="p-col-6 p-md-2" style={{ float: "right" }} >
                                                            {/* <Button label="Add Product / Category" className="p-button-primary" onClick={() => setVisible(true)} /> */}
                                                            <Menu model={middleItems} popup ref={middleMenu} id="popup_middleMenu" />
                                                            <Menu model={bottomItems} popup ref={bottomMenu} id="popup_bottomMenu" />
                                                            <Button label="Add" className="p-button-secondary" onClick={(event: any) => (bottomMenu as any).current.toggle(event)} aria-controls="popup_bottomMenu" aria-haspopup />
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                        </Fragment>
                                        {/* } */}

                                    </div>
                                </div>
                            </div>
                    }
                </div>

            </div>

            <div className="p-grid">

                {/* --------------------------------- Edit Category / Product --------------------------------------- */}


                <Dialog visible={mainStore.menuDialogBox} style={{ width: '90vw' }} onHide={() => onHide()} showHeader={false}>
                    {
                        (mainStore.menuCategory && <CategoryForm id={categoryId} />) || (mainStore.menuProduct && <ProductForm id={productId} /> || mainStore.menuDeal && <Deals id={productId} />)
                    }
                </Dialog>

                {/* -------------------------------- Add Category / Sub Category / Product ------------------------------------------ */}

                <Dialog visible={mainStore.menuAddDialogBox} style={{ width: '90vw' }} onHide={() => onHide()} showHeader={false}>
                    {
                        (mainStore.menuAddCategory && <CategoryForm parentId={propsParentId} storeId={stores} />) || (mainStore.menuAddProduct && <ProductForm parentId={propsParentId} storeId={stores} />)
                    }
                </Dialog>

                {/* -------------------------------- Choose Add Category / Product -------------------------------------- */}

                <Dialog header="Add " visible={visible} style={{ width: '250px' }} showHeader onHide={() => onHide()}>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <div className="p-grid">
                                <div className="p-col-12 ">
                                    <Button label="Add Category" onClick={() => { setVisible(false); mainStore.menuAddCategory = true; mainStore.menuAddDialogBox = true }} />
                                </div>
                                <div className="p-col-12 ">
                                    <Button label="Add Sub Category" onClick={() => { setVisible(false); mainStore.menuAddCategory = true; mainStore.menuAddDialogBox = true }} />
                                </div>
                                <div className="p-col-12 ">
                                    <Button label="Add Product" onClick={() => { setVisible(false); mainStore.menuAddProduct = true; mainStore.menuAddDialogBox = true }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

            </div>
        </div >
    );
})