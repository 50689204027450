import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from '../stores/useStore';

export const ZReportList = observer((props: any) => {
    const { mainStore } = useStore()
    const [errorMsg, setErrorMsg] = useState(null)
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<any>()
    const [refresh, setRefresh] = useState(true)
    const [emptyFlag, setEmptyFlag] = useState(true);


    useEffect(() => {
        if (refresh) {
            setLoading(true)
            mainStore.getZReports()
                .then(([success, data]) => {
                    if (success) {
                        setData(data)
                    } else {
                        setErrorMsg(data)
                    }
                    if (data?.message === "No data found!!") {
                        setEmptyFlag(false)
                    }
                })

                .finally(() => {
                    setLoading(false)
                    setRefresh(false)
                })
        }
    }, [refresh])

    const actionTemplate = (rowData: any) => {
        return <div>
            <Link to={`/zreport/${rowData.report_id}`}>View</Link>
        </div>
    }

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className='page-heading'>Z Reports</div>
            </div>
            <div className="p-col-12">
                <Card>
                    {loading ? <div>Loading...</div> :
                        errorMsg ? <div className='error-msg'>{errorMsg} <Button className='p-button-secondary' label='Cancel' onClick={(e) => setErrorMsg(null)} /> </div> :
                            <div className='p-grid'>
                                <div className="p-col-12">
                                    <DataTable value={data} emptyMessage='No Reports available' responsive={true}>
                                        <Column sortable={true} field="report_id" header="Report ID" filter filterPlaceholder="Search by ID" />
                                        <Column sortable={true} field="report_from_time" body={(rowData: any) => moment(rowData.report_from_time).format('DD-MM-YYYY hh:mm:ss')} header="From Time" filter filterPlaceholder="Search by time" filterMatchMode="contains" />
                                        <Column sortable={true} field="report_end_time" body={(rowData: any) => moment(rowData.report_end_time).format('DD-MM-YYYY hh:mm:ss')} header="To Time" filter filterPlaceholder="Search by time" filterMatchMode="contains" />
                                        <Column sortable={true} field="report_taken_time" body={(rowData: any) => moment(rowData.report_taken_time).format('DD-MM-YYYY hh:mm:ss')} header="Report Taken Time" filter filterPlaceholder="Search by time" filterMatchMode="contains" />
                                        <Column sortable={true} field="system_id" header="System ID" filter filterPlaceholder="Search by ID" />
                                        <Column sortable={true} field="user_id" header="User ID" filter filterPlaceholder="Search by ID" />
                                        <Column body={actionTemplate} />
                                    </DataTable>
                                </div>
                            </div>
                    }
                </Card>
            </div>
        </div>
    )

})
