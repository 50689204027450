import { ErrorMessage, Field, Form, Formik, FieldArray } from 'formik';
import { observer } from 'mobx-react-lite';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import * as yup from 'yup';
import { useStore } from '../stores/useStore';
import { MultiSelect } from 'primereact/multiselect';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { FiXCircle, FiPlus } from 'react-icons/fi';

const FormErrorMsg = styled.div`
 color: red;
`

export const OptionForm = observer((props: any) => {
    const [errorMsg, setErrorMsg] = useState(null)
    const { mainStore } = useStore();
    const history = useHistory();
    const { id } = useParams<any>();
    const editMode = (id && !mainStore.option) ? true : false;
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState<any>();
    const [frmkerrorMsg, setFrmkErrorMsg] = useState<any>();


    useEffect(() => {
        setLoading(true)
        if (editMode) {
            mainStore.getOption(Number(id))
                .then(([success, data]) => {
                    if (success) {
                        setFormData(tranformFormData(data))
                    }
                })
                .finally(() => setLoading(false))
        } else {
            setFormData({ option_id: 0, option_description: { 1: { name: null } }, type: null, sort_order: 0, option_values: [] })
            setLoading(false)
        }
    }, [])

    function tranformFormData(data: any) {
        for (const key in data.options) {
            data[key] = data.options[key]
        }
        delete data.options
        return data
    }

    const handleCancel = () => {
        if (mainStore.visible == true) {
            mainStore.visible = false
            mainStore.option = false
        } else {
            history.goBack()
        }
    }

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="p-grid p-justify-between">
                    <div className="p-col">
                        <div className='page-heading'>{editMode ? 'Edit' : 'New'} Option</div>
                    </div>
                </div>
            </div>

            <div className="p-col-12" >
                <Formik
                    initialValues={formData}
                    enableReinitialize={true}
                    validateOnMount={true}
                    // validate={values => {
                    //     if (values.option_values.length === 0) {
                    //       return { option_values: "Must enter at least one item" };
                    //     }
                    //   }}
                    validationSchema={yup.object({
                        option_description: yup.object({ 1: yup.object({ name: yup.string().required('Required').nullable() }) }),
                        type: yup.string().required('Required').nullable(),
                        option_value: yup.array().of(
                            yup.object({
                                option_value_description: yup.object({ 1: yup.object({ name: yup.string().required('Required').nullable() }) })
                            })
                        )
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        if (values.option_values.length == 0 || values.option_values[0]?.option_value_description[1]?.name == undefined || null) {
                            setSubmitting(false)
                            setFrmkErrorMsg("Please add option value")
                        } else {
                            setFrmkErrorMsg("")
                            // if (editMode) {
                            mainStore.updateOption(values)
                                .then(([success, data]) => {
                                    if (success) {
                                        if (mainStore.option == true) {
                                            mainStore.optionValue = ''
                                            mainStore.optionValue = data?.id.toString()
                                            mainStore.visible = false;
                                            mainStore.option = false
                                            mainStore.isOption = true
                                        } else {
                                            history.push('/options')
                                        }
                                    } else {
                                        setErrorMsg(data)
                                    }
                                })
                        }
                        // } else {
                        //     mainStore.updateOption(values)
                        //         .then(([success, data]) => {
                        //             if (success) {
                        //                 history.push('/options')
                        //             } else {
                        //                 setErrorMsg(data)
                        //             }
                        //         })
                        // }
                    }}>
                    {formik =>
                        <Card>
                            <Form>
                                {loading ? <div>Loading...</div> :
                                    errorMsg ? <div className='error-msg'>{errorMsg}</div> :
                                        <div className='p-grid'>
                                            <div className='p-col-12 p-md-3'>
                                                <div className='p-grid p-fluid form-control-group'>
                                                    <div className='p-col-12'>
                                                        <label>Option Name</label>
                                                    </div>
                                                    <div className='p-col-12'>
                                                        <Field name='option_description.1.name' as={InputText} />
                                                    </div>
                                                    <div className='p-col-12'>
                                                        <ErrorMessage name='option_description.1.name' component={FormErrorMsg} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='p-col-12 p-md-3'>
                                                <div className='p-grid p-fluid form-control-group'>
                                                    <div className='p-col-12'>
                                                        <label>For</label>
                                                    </div>
                                                    <div className='p-col-12'>
                                                        <Field name='option_description.1.optionfor' as={InputText} />
                                                    </div>
                                                    <div className='p-col-12'>
                                                        <ErrorMessage name='option_description.1.optionfor' component={FormErrorMsg} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='p-col-12 p-md-3'>
                                                <div className="p-grid p-fluid form-control-group">
                                                    <div className="p-col-12">
                                                        <label>Type</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <Field name='type' as={Dropdown} options={[
                                                            { label: 'Select', value: 'select' },
                                                            { label: 'Radio', value: 'radio' },
                                                            { label: 'Checkbox', value: 'checkbox' }
                                                        ]} />
                                                    </div>
                                                    <div className="p-col-12">
                                                        <ErrorMessage name='type' component={FormErrorMsg} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='p-col-12 p-md-3'>
                                                <div className="p-grid p-fluid form-control-group">
                                                    <div className="p-col-12">
                                                        <label>Sort Order</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <Field name='sort_order' as={InputText} />
                                                    </div>
                                                    <div className="p-col-12">
                                                        <ErrorMessage name='sort_order' component={FormErrorMsg} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='p-col-12 dynamic-form'>
                                                <div className='p-grid options-table'>
                                                    <div className='p-col-12 table-head'>
                                                        <div className='p-grid p-fluid'>
                                                            <div className='p-col-12 p-md-4'>
                                                                <label>Value Name</label>
                                                            </div>
                                                            <div className='p-col-12 p-md-2'>
                                                                <label>Short Code</label>
                                                            </div>
                                                            <div className='p-col-12 p-md-3'>
                                                                <label>Sort Order</label>
                                                            </div>
                                                            <div className='p-col-12 p-md-2'>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <FieldArray name="option_values">
                                                        {arrayHelpers => <>
                                                            {formik.values && formik.values['option_values']?.map((item: any, index: number) => (
                                                                <div className='p-col-12 table-body'>
                                                                    <div className='p-grid  p-fluid'>
                                                                        <div className='p-col-12 p-md-4'>
                                                                            <div className='p-grid form-control-group'>
                                                                                <div className='p-col-12  responsive-label'>
                                                                                    <label>Name</label>
                                                                                </div>
                                                                                <div className='p-col-12'>
                                                                                    <Field name={`option_values.${index}.option_value_description.1.name`} as={InputText} Required />
                                                                                </div>
                                                                                <div className='p-col-12'>
                                                                                    <ErrorMessage name={`option_values.${index}.option_value_description.1.name`} component={FormErrorMsg} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='p-col-12 p-md-2'>
                                                                            <div className='p-grid form-control-group'>
                                                                                <div className='p-col-12  responsive-label'>
                                                                                    <label>Short Code</label>
                                                                                </div>
                                                                                <div className='p-col-12'>
                                                                                    <Field name={`option_values.${index}.option_value_description.1.shortcode`} as={InputText} />
                                                                                </div>
                                                                                <div className='p-col-12'>
                                                                                    <ErrorMessage name={`option_values.${index}.option_value_description.1.shortcode`} component={FormErrorMsg} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='p-col-12 p-md-3'>
                                                                            <div className='p-grid form-control-group'>
                                                                                <div className='p-col-12  responsive-label'>
                                                                                    <label>Sort Order</label>
                                                                                </div>
                                                                                <div className='p-col-12'>
                                                                                    <Field name={`option_values.${index}.sort_order`} as={InputText} />
                                                                                </div>
                                                                                <div className='p-col-12'>
                                                                                    <ErrorMessage name={`option_values.${index}.sort_order`} component={FormErrorMsg} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='p-col-12 p-md-2 p-col-align-center'>
                                                                            <div className='p-grid form-control-group'>
                                                                                <div className='p-col-12 icon-container'>
                                                                                    <div onClick={(e) => arrayHelpers.remove(index)}>
                                                                                        <FiXCircle />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='p-col-12' style={{ height: '.5em' }}>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}

                                                            <div className='p-col-12'>
                                                                <div className='icon-container'>
                                                                    <div> New Option Value </div>
                                                                    <div onClick={(e) => arrayHelpers.push({ option_value_description: { 1: { name: null } } })}>
                                                                        <FiPlus />
                                                                    </div>
                                                                    <div className='p-col-12'>
                                                                        <span style={{ color: "red" }}>{frmkerrorMsg}</span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </>
                                                        }
                                                    </FieldArray>
                                                </div>

                                            </div>

                                            <div className='p-col-12'>
                                                <div className="p-grid">
                                                    <div className="p-col form-action-container">
                                                        <Button type='submit' disabled={formik.isSubmitting} label={editMode ? 'Update' : 'Save'} className='p-button-primary' />
                                                        <Button type='button' label="Cancel" onClick={() => handleCancel()} className='p-button-secondary' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </Form>
                        </Card>
                    }
                </Formik>
            </div>
        </div>
    )
})

