import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import { useStore } from '../stores/useStore';
import { Link } from 'react-router-dom';
import moment from 'moment'
import { boolean } from 'yup';
import { useLocalStore, observer } from 'mobx-react-lite'
import { AnyAaaaRecord } from 'dns';
import { isNull } from 'util';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const Dashboard = observer((props: any) => {
    const { mainStore } = useStore()
    const [data, setData] = useState<any>(null)
    const [loading, setLoading] = useState(true)
    const [errorMsg, setErrorMsg] = useState(null)
    const [lastWeekSale, setLastWeekSale] = useState<any>(null)
    const localStore = useLocalStore(() => ({ today_settings: [undefined], basic_settings: [undefined], basic_settings_delivery: [undefined] }))

    useEffect(() => {
        if (!data || mainStore.newOrder) {
            mainStore.getDashBoardData()
                .then(([success, data]) => {
                    if (success) {
                        let dayDataLabel = []
                        let dayData = []
                        for (const [key, value] of Object.entries(data.day_wise_results)) {
                            dayDataLabel.push(key.toUpperCase().slice(0, 3))
                            dayData.push(Number(value))
                        }
                        let chartData: any = {
                            labels: dayDataLabel,
                            datasets: [{
                                label: 'Sales',
                                backgroundColor: '#42A5F5',
                                data: dayData
                            }]
                        }
                        setLastWeekSale(chartData)
                        localStore.today_settings = Object.values(data.basic_settings_today).map((item: any) => { item.value = item.value ? Number(item.value) : 0; return item })
                        localStore.basic_settings = Object.values(data.basic_settings).map((item: any) => { item.value = item.value ? Number(item.value) : 0; return item })
                        localStore.basic_settings_delivery = Object.values(data.basic_settings_delivery).map((item: any) => { item.value = item.value ? Number(item.value) : 0; return item })
                        setData(data)

                    } else {
                        setErrorMsg(data)
                    }
                })
                .finally(() => {
                    setLoading(false)
                    mainStore.newOrder = false
                })
        }

    }, [mainStore.newOrder])

    const actionTemplate = (rowData: any) => {
        return <div>
            <Link to={`/basicorderdetails/${rowData.order_id}`}>View</Link>
        </div>
    }
    var newOrderList: any = []
    let newOrderID = localStorage.getItem('orders_id')
    if (typeof newOrderID === 'string') {
        newOrderList = JSON.parse(newOrderID);
    }

    const badgeTemplate = (rowData: any) => {
        return (
            <>{rowData.order_id}
                <div className='inline-spacer' />
                {!newOrderList?.includes(rowData.order_id) && rowData.status === "Pending" ?
                    <i className="pi pi-circle-on" style={{ color: "red", 'fontSize': '1em' }}></i> : ""}
            </>
        );
    }



    return (

        <div className="p-grid dashboard">
            {loading ? <div className="p-col-12">Loading...</div> :
                errorMsg ? <div className='p-col-12 error-msg'>{errorMsg}</div> :
                    <>
                        <div className="p-col-12">
                            <Card>
                                <div className='p-grid'>
                                    <div className='p-col-12'>
                                        <div className='title'>Recent Orders</div>
                                    </div>
                                    <div className='p-col-12'>
                                        <DataTable value={data.orders} paginator={true} rows={5} responsive={true} emptyMessage='No orders yet'>
                                            {/* <Column field='display_id' header='Order No.' sortable={true} /> */}
                                            <Column sortable={true} body={badgeTemplate} header="Order ID" />
                                            <Column body={(rowData: any) => moment(rowData.order_requested_time).format('DD-MM-YYYY hh:mm a')} header='Time' sortable={true} />
                                            <Column field='customer' header='Customer' sortable={true} />
                                            <Column field='total' header='Total' sortable={true} />
                                            <Column field='status' header='Status' sortable={true} />
                                            <Column body={actionTemplate} style={{ width: '120px' }} />
                                        </DataTable>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Card>
                                <div className='p-grid'>
                                    <div className='p-col-12'>
                                        <div className='title'>Overview</div>
                                    </div>
                                    <div className='p-col-12'>
                                        <div className='p-grid p-fluid'>
                                            <div className='p-col-8'>Total Sales Today</div>
                                            <div className='p-col-4 right amount'>
                                                {data.total_sale_today}
                                            </div>
                                            <div className='p-col-8'>Total Sales This Week</div>
                                            <div className='p-col-4 right amount'>
                                                {data.total_sale_week}
                                            </div>
                                            <div className='p-col-8'>Total Sales This Year</div>
                                            <div className='p-col-4 right amount'>
                                                {data.total_sale_year}
                                            </div>
                                            <div className='p-col-8'>Total Sales</div>
                                            <div className='p-col-4 right amount'>
                                                {data.total_sale}
                                            </div>
                                            <div className='p-col-12'>
                                                <hr></hr>
                                            </div>
                                            <div className='p-col-8'>Total Orders Today</div>
                                            <div className='p-col-4 right amount'>
                                                {data.todays_total_order}
                                            </div>
                                            <div className='p-col-8'>Total Orders</div>
                                            <div className='p-col-4 right amount'>
                                                {data.total_order}
                                            </div>
                                            <div className='p-col-12'>
                                                <hr></hr>
                                            </div>
                                            <div className='p-col-8'>No. of Customers</div>
                                            <div className='p-col-4 right amount'>
                                                {data.total_customer}
                                            </div>
                                            <div className='p-col-8'>Customers Waiting Approval</div>
                                            <div className='p-col-4 right amount'>
                                                {data.total_customer_approval}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Card style={{ height: '100%' }}>
                                <div className='p-grid'>
                                    <div className='p-col-12'>
                                        <div className='title'>Weekly Sale</div>
                                    </div>
                                    <div className='p-col-12'>
                                        <Chart type="bar" data={lastWeekSale} />
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Card>
                                <div className='p-grid'>
                                    <div className='p-col-12'>
                                        <div className='title'>Today's Setting</div>
                                    </div>
                                    {localStore.today_settings.map((item: any) => (
                                        <div className='p-col-12'>
                                            <div className='p-grid p-fluid'>
                                                <div className='p-col-8'>{item.text_entry}</div>
                                                <div className='p-col-4'>
                                                    {item.text_value ? <>
                                                        <label className='status-label'>{item.value ? <span style={{ color: 'green' }}>Enabled</span> : <span>Disabled</span>}</label>
                                                        <InputSwitch checked={Boolean(item.value)} onChange={(e) => { item.value = e.value ? 1 : 0; mainStore.updateBasicConfig(item) }} />
                                                    </> :
                                                        <div className="p-inputgroup">
                                                            <InputText keyfilter="int" value={item.value} onChange={(e: any) => { item.value = e.target.value }} />
                                                            <Button icon="pi pi-check" className="p-button-primary" onClick={(e) => { mainStore.updateBasicConfig(item) }} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Card>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Card>
                                <div className='p-grid'>
                                    <div className='p-col-12'>
                                        <div className='title'>Basic Settings</div>
                                    </div>
                                    {localStore.basic_settings.map((item: any) => (
                                        <div className='p-col-12'>
                                            <div className='p-grid p-fluid'>
                                                <div className='p-col-8'>{item.text_entry}</div>
                                                <div className='p-col-4'>
                                                    {item.text_value ? <>
                                                        <label className='status-label'>{item.value ? <span style={{ color: 'green' }}>Enabled</span> : <span>Disabled</span>}</label>
                                                        <InputSwitch checked={Boolean(item.value)} onChange={(e) => { item.value = e.value ? 1 : 0; mainStore.updateBasicConfig(item) }} />
                                                    </> :
                                                        <div className="p-inputgroup">
                                                            <InputText keyfilter="int" value={item.value} onChange={(e: any) => { item.value = e.target.value }} />
                                                            <Button icon="pi pi-check" className="p-button-primary" onClick={(e) => { mainStore.updateBasicConfig(item) }} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <div className='p-col-12'>
                                        <div className='title'>Delivery Settings</div>
                                    </div>
                                    {localStore.basic_settings_delivery.map((item: any) => (
                                        <div className='p-col-12'>
                                            <div className='p-grid p-fluid'>
                                                <div className='p-col-8'>{item.text_entry}</div>
                                                <div className='p-col-4'>
                                                    {item.text_value ? <>
                                                        <label className='status-label'>{item.value ? <span style={{ color: 'green' }}>Enabled</span> : <span>Disabled</span>}</label>
                                                        <InputSwitch checked={Boolean(item.value)} onChange={(e) => { item.value = e.value ? 1 : 0; mainStore.updateBasicConfig(item) }} />
                                                    </> :
                                                        <div className="p-inputgroup">
                                                            <InputText keyfilter="int" value={item.value} onChange={(e: any) => { item.value = e.target.value }} />
                                                            <Button icon="pi pi-check" className="p-button-primary" onClick={(e) => { mainStore.updateBasicConfig(item) }} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </Card>
                        </div>
                    </>
            }
        </div >
    )
})
