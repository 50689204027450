import React, {Component} from 'react';
import { Card } from 'primereact/card';

export class EmptyPage extends Component {

    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <Card>
                        <h1>Empty Page</h1>
                        <p>Use this page to start from scratch and place your custom content.</p>
                    </Card>
                </div>
            </div>
        );
    }
}