import { action, computed, observable } from 'mobx';
import moment from 'moment';
import apiClient from './apiClient';
import axios from 'axios'
import { Capacitor } from '@capacitor/core';

export class UserSessionStore {

    @observable
    user: any = null

    @observable
    device_token: any = localStorage.getItem('device_token')

    @computed
    get isAuthenticated() {
        return !!this.user
    }
    @action
    getPlatforms() {
        Capacitor.getPlatform() == ('android' || 'ios') ? localStorage.setItem('platform', 'app') : localStorage.setItem('platform', 'web');
    }


    @action
    authenticateUsingToken() {
        return apiClient.get('/admnapi/jsonp.php?method=getuser')
            .then(action((res: any) => {
                if (res.data.status == 1) {
                    this.user = res.data
                    return true
                } else {
                    throw Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                // console.log(errorMsg)
                return false
            }))
    }

    @action
    authenticate(username: string, password: string) {
        let token: any = localStorage.getItem('device_token');
        let platform: any = localStorage.getItem('platform')
        let data = new FormData()
        data.set('username', username)
        data.set('password', password)
        data.set('device_token', token)
        data.set('platform', platform)
        return apiClient.post('/admnapi/jsonp.php?method=login', data)
            .then(action((res: any) => {
                if (res.data.status == 1) {
                    localStorage.setItem('access', res.data.login_token)
                } else {
                    throw Error(res.data.error)
                }
                return apiClient.get('/admnapi/jsonp.php?method=getuser')
            }))
            .then(action((res: any) => {
                this.user = res.data
                return [true, 'Authenticated']
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    logout() {
        localStorage.removeItem('access')
        this.user = null
    }


    @action
    setSiteConfig(data: any) {
        this.getPlatforms()
        localStorage.setItem('site', JSON.stringify(data))
    }

    @action
    getSiteConfig() {
        let config = localStorage.getItem('site')
        if (config) {
            return JSON.parse(config)
        } else {
            return null
        }
    }

    @action
    removeSiteConfig() {
        localStorage.removeItem('site')
    }

    @action
    testSiteConfig(seckey: string) {
        return axios.get(`https://api.etakeawaymax.co.uk/adminpwa.php?securitykey=${seckey}`)
            .then(res => {
                if (res.data?.site) {
                    return [true, res.data]
                } else {
                    return [false, 'Invalid Security Key']
                }
            })
            .catch(err => {
                return [false, 'Couldn not connect to the site']
            })
    }
}