import axios from 'axios'

const apiClient = axios.create({
    // baseURL: process.env.REACT_APP_MAIN_API_BASE_URL,
    timeout: 150000
})

// apiClient.interceptors.request.use((config) => {
//     return new Promise(resolve => setTimeout(() => resolve(config), 500));
// });

async function getSiteUrl() {
    let storedConfig  = localStorage.getItem('site')
    if(storedConfig){
        let config = JSON.parse(storedConfig)
        return config.siteurl 
    }else{
        return ''
    }
}

async function getAccessToken() {
    return localStorage.getItem('access');
}

// Request interceptor for auth header
apiClient.interceptors.request.use(async (config) => {
    // config.headers['Authorization'] = 'Bearer ' + await getAccessToken();
    // return config;
    config.baseURL = await getSiteUrl()
    // console.log('Interceptor => ', config.baseURL)
    config.params = { ...config.params, login_token: await getAccessToken() }
    return config
});


export default apiClient;