import { ErrorMessage, Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { Button } from "primereact/button";
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import React, { useEffect, useState } from 'react';
import { FiMinusCircle, FiXCircle } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { ImageManager } from '../components/ImageManager';
import { useStore } from '../stores/useStore';

const FormErrorMsg = styled.div`
 color: red;
`

export const CategoryForm = observer((props: any) => {
    const [errorMsg, setErrorMsg] = useState(null)
    const { mainStore } = useStore();
    const history = useHistory();
    const { id }: any = useParams();
    const editMode = (id && !mainStore.visible) || props?.id ? true : false;
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState<any>();
    const [categories, setCategories] = useState([]);
    const [stores, setStores] = useState([]);
    const [imgRefresh, setImgRefresh] = useState(false)

    useEffect(() => {
        setLoading(true)
        if (editMode) {
            mainStore.getCategory(Number(props.id ? props.id : id))
                .then(([success, data]) => {
                    if (success) {
                        setFormData(data)
                        mainStore.imageURL = data?.thumb ? data?.thumb : '/defaultImage.png';
                    }
                    return mainStore.getCategories()
                })
                .then(([success, data]) => {
                    if (success) {
                        setCategories(data.category)
                    }
                    return mainStore.getStores()
                })
                .then(([success, data]) => {
                    if (success) {
                        setStores(data)
                    }
                })
                .finally(() => setLoading(false))
        } else {
            mainStore.getCategories()
                .then(([success, data]) => {
                    if (success) {
                        if (props.parentId || props.storeId) {
                            mainStore.imageURL = '/defaultImage.png'
                            setCategories(props.parentId ? data?.category : [])
                            setFormData({ category_id: null, parent_id: props?.parentId, category_store: [props?.storeId], status: '1', category_description: { 1: { name: null } } })
                        } else {
                            setCategories(data?.category)
                            mainStore.imageURL = '/defaultImage.png'
                            setFormData({ category_id: null, parent_id: null, status: '0', category_description: { 1: { name: null } } })

                        }
                    }
                    return mainStore.getStores()
                })
                .then(([success, data]) => {
                    if (success) {
                        setStores(data)
                    }
                })
                .finally(() => setLoading(false))
        }
    }, [])

    useEffect(() => {
        mainStore.imageURL = '/defaultImage.png'
        mainStore.imagePath = '';
        setImgRefresh(false)

    }, [imgRefresh])


    const handleCancel = () => {
        if (mainStore.visible == true) {
            mainStore.visible = false
            mainStore.categories = false
        } else if (mainStore.menuDialogBox == true) {
            mainStore.menuDialogBox = false
            mainStore.menuCategory = false
        } else if (mainStore.menuAddDialogBox == true) {
            mainStore.menuAddDialogBox = false
            mainStore.menuAddCategory = false
        }
        else {
            history.goBack()
        }
    }

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="p-grid p-justify-between">
                    <div className="p-col">
                        <div className='page-heading'>{editMode ? 'Edit' : 'New'} Category</div>
                    </div>
                </div>
            </div>
            <div className="p-col-12" >
                <Formik
                    initialValues={formData}
                    enableReinitialize={true}
                    validateOnMount={true}
                    validate={(values) => {
                        const errors: any = {};
                        if (values.parent_id && values.category_id == values.parent_id) {
                            errors.parent_id = 'Parent category must be different from current category';
                        }
                        return errors;
                    }}
                    validationSchema={yup.object({
                        category_description: yup.object({
                            1: yup.object({
                                name: yup.string().required('Required').nullable()
                            })
                        }),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        if (mainStore.imagePath) {
                            values.image = "data/" + mainStore?.imagePath
                        } else {
                            values.image = ''
                        }
                        if (editMode) {
                            mainStore.updateCategory(values)
                                .then(([success, data]) => {
                                    if (success) {
                                        if (mainStore.menuCategory == true) {
                                            mainStore.menuDialogBox = false;
                                            mainStore.menuCategory = false
                                            mainStore.refresh = true
                                        } else {
                                            history.push('/categories')
                                        }

                                    } else {
                                        setErrorMsg(data)
                                    }
                                })
                        } else {
                            mainStore.updateCategory(values)
                                .then(([success, data]) => {
                                    if (success) {
                                        if (mainStore.categories == true) {
                                            mainStore.categoryValue = data.id
                                            mainStore.visible = false;
                                            mainStore.categories = false
                                            mainStore.isCategories = true;
                                        } else if (mainStore.menuAddDialogBox == true) {
                                            mainStore.menuAddDialogBox = false;
                                            mainStore.menuAddCategory = false
                                            mainStore.refresh = true
                                        }
                                        else {
                                            history.push('/categories')
                                        }

                                    } else {
                                        setErrorMsg(data)
                                    }
                                })
                        }
                    }}>
                    {formik =>
                        <Card>
                            <Form>
                                {loading ? <div>Loading...</div> :
                                    errorMsg ? <div className='error-msg'>{errorMsg}</div> :
                                        <div className='p-grid'>
                                            <div className='p-col-12 p-md-6'>
                                                <div className='p-grid p-fluid form-control-group'>
                                                    <div className='p-col-12'>
                                                        <label>Name</label>
                                                    </div>
                                                    <div className='p-col-12'>
                                                        <Field name='category_description.1.name' as={InputText} />
                                                    </div>
                                                    <div className='p-col-12'>
                                                        <ErrorMessage name='category_description.1.name' component={FormErrorMsg} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='p-col-12 p-md-6'>
                                                <div className="p-grid p-fluid form-control-group">
                                                    <div className="p-col-12">
                                                        <label>Stores</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <Field name='category_store' as={MultiSelect} optionLabel='name' optionValue='store_id' options={stores} />
                                                    </div>
                                                    <div className="p-col-12">
                                                        <ErrorMessage name='stores' component={FormErrorMsg} />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className='p-col-12 p-md-6'>
                                                <div className="p-grid p-fluid form-control-group">
                                                    <div className="p-col-12">
                                                        <label>Description</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <Field name='category_description.1.description' as={InputText} />
                                                    </div>
                                                    <div className="p-col-12">
                                                        <ErrorMessage name='category_description.1.description' component={FormErrorMsg} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='p-col-12 p-md-6'>
                                                <div className="p-grid p-fluid form-control-group">
                                                    <div className="p-col-12">
                                                        <label>Parent Category</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <Field name='parent_id' as={Dropdown} optionLabel="name" optionValue="category_id" options={categories} />
                                                    </div>
                                                    <div className="p-col-12">
                                                        <ErrorMessage name='parent_id' component={FormErrorMsg} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='p-col-12 p-md-6'>
                                                <div className='p-grid p-fluid form-control-group'>
                                                    <div className='p-col-12'>
                                                        <label>Short Code</label>
                                                    </div>
                                                    <div className='p-col-12'>
                                                        <Field name='category_description.1.shortcode' as={InputText} />
                                                    </div>
                                                    <div className='p-col-12'>
                                                        <ErrorMessage name='category_description.1.shortcode' component={FormErrorMsg} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='p-col-4 p-md-2'>
                                                <div className="p-grid p-fluid form-control-group">
                                                    <div className="p-col-12">
                                                        <label>Enabled</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <Field name='status' type='checkbox' as={Checkbox} value={formik.values['status'] ? '1' : '0'} checked={formik.values['status'] == '1' ? true : false} />
                                                    </div>
                                                    <div className="p-col-12">
                                                        <ErrorMessage name='status' component={FormErrorMsg} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='p-col-8 p-md-4'>
                                                <div className="p-grid p-fluid form-control-group">
                                                    <div className="p-col-12">
                                                        <label>Sort Order</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <Field name='sort_order' as={InputText} />
                                                    </div>
                                                    <div className="p-col-12">
                                                        <ErrorMessage name='sort_order' component={FormErrorMsg} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='p-col-12 p-md-3'>
                                                <div className="p-grid p-fluid ">
                                                    <div className="p-col-12 " style={{ position: 'relative' }}>
                                                        <div>
                                                            <FiMinusCircle size='20px' fontWeight='bold' style={{ position: 'absolute', right: '5px' }} onClick={() => setImgRefresh(true)} />
                                                        </div>

                                                        <img src={mainStore.imageURL} alt="" style={{ width: '100%', height: '120px', objectFit: 'cover', objectPosition: '50% 50%' }} onClick={() => mainStore.fileManagerDialogue = true} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='p-col-12'>
                                                <div className="p-grid form-action-container">
                                                    <div className="p-col ">
                                                        <Button type='submit' disabled={formik.isSubmitting} label={editMode ? 'Update' : 'Save'} className='p-button-primary' />
                                                        <Button type='button' label="Cancel" onClick={() => handleCancel()} className='p-button-secondary' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </Form>
                        </Card>
                    }
                </Formik>
            </div >
            <Dialog header="Image Manager" visible={mainStore.fileManagerDialogue} style={{ width: '90vw' }} onHide={() => mainStore.fileManagerDialogue = false} showHeader={true}>
                <ImageManager />
            </Dialog>
        </div >
    )
})

