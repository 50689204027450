import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { useStore } from '../stores/useStore';
import { Plugins } from '@capacitor/core';
import { Message } from 'primereact/message';

export const TableOrderList = observer((props: any) => {
    const { mainStore } = useStore()
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMsg, setErrorMsg] = useState(null)
    const [data, setData] = useState<any>([])
    const [refresh, setRefresh] = useState(true)
    const [emptyFlag, setEmptyFlag] = useState(true);
    let [count, setCount] = useState<any>(0);
    const [globalFilter, setGlobalFilter] = useState(null)
    const [allOrderStatus, setAllOrderStatus] = useState<any>([]);
    const [selectedStatus, setSelectedStatus] = useState<any>([])
    const [selectedOrderId, setSelectedOrderId] = useState<any>([])
    const [selectedDate, setSelectedDate] = useState<any>(new Date())
    const [pageNumber, setPageNumber] = useState<any>()
    const [totalPageCount, setTotalPageCount] = useState<any>()
    const [sortedData, setSortedData] = useState<any>()
    const { Network } = Plugins;

    useEffect(() => {

        if (refresh || mainStore.newTableOrder) {
            setLoading(true)
            mainStore.getOrders()
                .then(([success, data]) => {
                    if (success) {
                        const tableOrderData = data?.data.all_orders?.filter((item: any) => item.shipping_method === "Dine")
                        setData(tableOrderData)
                        localStorage.setItem("tableOrdersData", JSON.stringify(tableOrderData))
                        setPageNumber(data?.data.pagination?.page)
                        setTotalPageCount(data?.data.pagination?.total_page)
                    } else {
                        var temp: any = []
                        temp = localStorage.getItem("tableOrdersData")
                        setData(JSON.parse(temp))
                        setErrorMsg(data)
                    }
                })
                .then(() => {
                    mainStore.getStatus()
                        .then(([success, data]) => {
                            if (success) {
                                let allOrderStatus = data.data.all_status.map((item: any) => ({ id: item.id, name: item.name }))
                                setAllOrderStatus(allOrderStatus)
                            }
                            else {
                                var tempStatus: any = []
                                tempStatus = localStorage.getItem("allStatus")
                                setAllOrderStatus(JSON.parse(tempStatus))
                                setErrorMsg(data)
                            }
                        })
                })
                .finally(() => {
                    setLoading(false)
                    setRefresh(false)
                    mainStore.newTableOrder = false
                })
        }
    }, [refresh, mainStore.newTableOrder, mainStore])

    Network.addListener('networkStatusChange', (status) => {
        if (status.connected === false) {
            mainStore.isOffline = true
        } else {
            mainStore.isOffline = false
            setErrorMsg(null)
            setRefresh(true)

        }

    });

    const setPreviousDate = () => {
        const curDate = selectedDate
        curDate.setDate(curDate.getDate() - 1);
        var previousDate = curDate
        // var previousDate = moment(curDate.subtract(1, 'day').toDate()).format("YYYY-MM-DD")
        setSelectedDate(previousDate)
        mainStore.getOrdersWithDate(moment(previousDate).format("YYYY-MM-DD"))
            .then(([success, data]) => {
                if (success) {
                    const tableOrderData = data?.data.all_orders?.filter((item: any) => item.shipping_method === "Dine")
                    setData(tableOrderData)
                    localStorage.setItem("tableOrdersData", JSON.stringify(tableOrderData))
                } else {
                    var temp: any = []
                    temp = localStorage.getItem("tableOrdersData")
                    setData(JSON.parse(temp))
                    setErrorMsg(data)
                }
                if (data?.message === "No data found!!") {
                    setEmptyFlag(false)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function setNextDate() {
        const curDate = selectedDate
        curDate.setDate(curDate.getDate() + 1);
        var nextDate = curDate
        // var nextDate = moment(curDate.add(1, 'day').toDate()).format("YYYY-MM-DD")
        setSelectedDate(nextDate)
        mainStore.getOrdersWithDate(moment(nextDate).format("YYYY-MM-DD"))
            .then(([success, data]) => {
                if (success) {
                    const tableOrderData = data?.data.all_orders?.filter((item: any) => item.shipping_method === "Dine")
                    setData(tableOrderData)
                    localStorage.setItem("tableOrdersData", JSON.stringify(tableOrderData))
                } else {
                    var temp: any = []
                    temp = localStorage.getItem("tableOrdersData")
                    setData(JSON.parse(temp))
                    setErrorMsg(data)
                }
                if (data?.message === "No data found!!") {
                    setEmptyFlag(false)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const previousOrders = () => {
        if (count >= 0) {
            setEmptyFlag(true)
            setCount(--count)
            setSelectedDate(selectedDate)
            mainStore.getOrdersWithCount(count, moment(selectedDate).format("YYYY-MM-DD"))
                .then(([success, data]) => {
                    if (success) {
                        const tableOrderData = data?.data.all_orders?.filter((item: any) => item.shipping_method === "Dine")
                        setData(tableOrderData)
                        localStorage.setItem("tableOrdersData", JSON.stringify(tableOrderData))
                    } else {
                        var temp: any = []
                        temp = localStorage.getItem("tableOrdersData")
                        setData(JSON.parse(temp))
                        setErrorMsg(data)
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const nextOrders = () => {
        if (emptyFlag) {
            setCount(++count)
            setSelectedDate(selectedDate)
            mainStore.getOrdersWithCount(count, moment(selectedDate).format("YYYY-MM-DD"))
                .then(([success, data]) => {
                    if (success) {
                        const tableOrderData = data?.data.all_orders?.filter((item: any) => item.shipping_method === "Dine")
                        setData(tableOrderData)
                        localStorage.setItem("tableOrdersData", JSON.stringify(tableOrderData))
                    } else {
                        var temp: any = []
                        temp = localStorage.getItem("tableOrdersData")
                        setData(JSON.parse(temp))
                        setErrorMsg(data)
                    }
                    if (data?.message === "No data found!!") {
                        setEmptyFlag(false)
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    function getSelectedDateOrders(data: any) {
        setLoading(true)
        mainStore.getOrdersWithDate(data)
            .then(([success, data]) => {
                if (success) {
                    const tableOrderData = data?.data.all_orders?.filter((item: any) => item.shipping_method === "Dine")
                    setData(tableOrderData)
                    localStorage.setItem("tableOrdersData", JSON.stringify(tableOrderData))
                } else {
                    var temp: any = []
                    temp = localStorage.getItem("tableOrdersData")
                    setData(JSON.parse(temp))
                    setErrorMsg(data)
                }
                if (data?.message === "No data found!!") {
                    setEmptyFlag(false)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const deleteConfirm = (order_id: any) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: `Are you sure to delete table order "${order_id}"?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        mainStore.deleteOrder({ order_id: order_id })
                            .then(([success, data]) => {
                                if (success) {
                                    setSelectedOrderId([])
                                    let today = moment().format("YYYY-MM-DD")
                                    if (today === selectedDate) {
                                        setRefresh(true)
                                    } else {
                                        getSelectedDateOrders(moment(selectedDate).format("YYYY-MM-DD"))
                                    }
                                } else {
                                    setErrorMsg(data)
                                }
                            })
                    }
                },
                {
                    label: 'No',
                    onClick: () => void (0)
                }
            ]
        });
    };

    const header = (
        <div className="p-grid ">
            <div className="p-col-12">
                <div className="p-grid p-justify-between">
                    <div className="p-col-12 p-sm-6 p-md-4 p-lg-6 p-xl-8 ">
                        <i className="pi pi-search" style={{ margin: '4px 8px 0 0' }}></i>
                        <InputText type="search" onInput={(e: any) => setGlobalFilter(e.target.value)} placeholder="Search" />
                    </div>
                    <div className="p-col-12 p-sm-6 p-md-8 p-lg-6 p-xl-4 right" >
                        <Button icon="pi pi-angle-left" onClick={() => { setPreviousDate(); setLoading(true) }} />
                        <div className='inline-spacer' />
                        <Calendar value={selectedDate} dateFormat="dd-mm-yy" onChange={(e: any) => { setSelectedDate(e.value); getSelectedDateOrders(moment(e.value).format("YYYY-MM-DD")) }} maxDate={moment().toDate()} readOnlyInput inputStyle={{ textAlign: "center" }} touchUI />
                        <div className='inline-spacer' />
                        <Button icon="pi pi-angle-right" className="p-ml-2" onClick={() => { setNextDate(); setLoading(true) }} disabled={moment(selectedDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") ? true : false} />
                    </div>
                </div>
            </div>
        </div>
    );

    const footer = (
        <div className="p-grid ">
            <div className="p-col-12">
                <div className="p-grid p-justify-between">
                    <div className="p-col left">
                        <Button type="button" label="Previous" icon="pi pi-arrow-left" onClick={() => { previousOrders(); setLoading(true) }}
                            // disabled={count === 0 ? true : false}
                            disabled={pageNumber === 0 || 1 ? true : false}
                            className="p-button-text" />
                    </div>
                    <div className="p-col-3 p-md-2">
                        <p> Showing <b>{pageNumber} </b>of <b>{totalPageCount}</b></p>

                    </div>
                    <div className="p-col right" >
                        <Button type="button" label="Next" icon="pi pi-arrow-right" onClick={() => { nextOrders(); setLoading(true) }}
                            disabled={pageNumber === totalPageCount ? true : false}
                            // disabled={emptyFlag === false ? true : false}
                            className="p-button-text" />
                    </div>
                </div>
            </div>
        </div>
    );

    const actionTemplate = (rowData: any) => {
        return <div>
            <Link to={`/basicorderdetails/${rowData.order_id}`}>View</Link>
            <div className='inline-spacer' />
            <a href='#' onClick={() => { deleteConfirm([rowData.order_id]); return false }}>Delete</a>
        </div>
    }

    const statusBodyTemplate = (rowData: any) => {
        return (
            <>
                <span style={{
                    fontWeight: 400,
                    padding: "5px",
                    // borderRadius: "50px",
                    background: rowData.order_status_id === "1" && "#ffcdd2" || rowData.order_status_id === "2" && "#f8bbd0" ||
                        rowData.order_status_id === "3" && "#e1bee7" || rowData.order_status_id === "4" && "#d1c4e9" ||
                        rowData.order_status_id === "5" && "#c5cae9" || rowData.order_status_id === "6" && "#bbdefb" ||
                        rowData.order_status_id === "7" && "#b3e5fc" || rowData.order_status_id === "8" && "#b2ebf2" ||
                        rowData.order_status_id === "9" && "#b2dfdb" || rowData.order_status_id === "10" && "#c8e6c9" ||
                        rowData.order_status_id === "11" && "#dcedc8" || rowData.order_status_id === "12" && "#f0f4c3" ||
                        rowData.order_status_id === "13" && "#fff9c4" || rowData.order_status_id === "14" && "#ffecb3" ||
                        rowData.order_status_id === "15" && "#ffe0b2" || rowData.order_status_id === "16" && "#ffccbc" ||
                        rowData.order_status_id === "17" && "#d7ccc8" || rowData.order_status_id === "18" && "#f5f5f5" ||
                        rowData.order_status_id === "19" && "#cfd8dc" || "",

                    // color: rowData.order_status_id === "1" && "#d50000" || rowData.order_status_id === "2" && "#c51162" ||
                    //     rowData.order_status_id === "3" && "#aa00ff" || rowData.order_status_id === "4" && "#6200ea" ||
                    //     rowData.order_status_id === "5" && "#304ffe" || rowData.order_status_id === "6" && "#2962ff" ||
                    //     rowData.order_status_id === "7" && "#0091ea" || rowData.order_status_id === "8" && "#00b8d4" ||
                    //     rowData.order_status_id === "9" && "#00bfa5" || rowData.order_status_id === "10" && "#00c853" ||
                    //     rowData.order_status_id === "11" && "#64dd17" || rowData.order_status_id === "12" && "#aeea00" ||
                    //     rowData.order_status_id === "13" && "#ffd600" || rowData.order_status_id === "14" && "#ffab00" ||
                    //     rowData.order_status_id === "15" && "#ff6d00" || rowData.order_status_id === "16" && "#dd2c00" ||
                    //     rowData.order_status_id === "17" && "#3e2723" || rowData.order_status_id === "18" && "#212121" ||
                    //     rowData.order_status_id === "19" && "#263238" || ""

                }}>{rowData.status}</span>
            </>
        );
    }

    var newTableOrderList: any = []
    let newTableOrderID = localStorage.getItem('orders_id')
    if (typeof newTableOrderID === 'string') {
        newTableOrderList = JSON.parse(newTableOrderID);
    }

    const badgeTemplate = (rowData: any) => {
        return (
            <>{rowData.order_id}
                <div className='inline-spacer' />
                {!newTableOrderList?.includes(rowData.order_id) && rowData.status === "Pending" ?

                    <i className="pi pi-circle-on" style={{ color: "red", 'fontSize': '1em' }}></i> : ""}
            </>
        );
    }

    const deleteOrder = () => {
        let selectedOrdersId = selectedOrderId?.map((item: any) => item.order_id)
        deleteConfirm(selectedOrdersId)
    }

    const statusFilter = <MultiSelect placeholder="Select a Status" value={selectedStatus} optionLabel="name" optionValue="name" options={allOrderStatus} onChange={(e: any) => onStatusChange(e)} className="p-column-filter" />

    const onStatusChange = (e: any) => {
        sortedData.filter(e.value, 'status', 'in');
        setSelectedStatus(e.value);
    }

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="p-grid p-justify-between">
                    <div className="p-col">
                        <div className='page-heading'>Table Orders</div>
                    </div>
                    <div className="p-col right" >
                        <Button label="Delete" icon="pi pi-trash" onClick={() => deleteOrder()} disabled={selectedOrderId.length === 0 ? true : false} className="p-button-danger" />
                    </div>
                </div>
            </div>
            <div className="p-col-12">
                <Card>
                    {loading ? <div>Loading...</div> :
                        (errorMsg && !mainStore.isOffline) ? <div className='error-msg'>{errorMsg} <Button className='p-button-secondary' label='Cancel' onClick={(e) => setErrorMsg(null)} /> </div> :
                            <div className='p-grid'>
                                <div className="p-col-12">
                                    {mainStore.isOffline && <div className='error-msg'><Message severity="error" text="You are offline please check your internet connection!" /></div>}
                                    <DataTable ref={(el: any) => setSortedData(el)} value={data} dataKey="order_id" header={header} footer={footer} selection={selectedOrderId} onSelectionChange={(e: any) => setSelectedOrderId(e.value)} globalFilter={globalFilter} emptyMessage='No Table Orders available' responsive={true}>
                                        <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
                                        <Column sortable={true} field="order_id" body={badgeTemplate} header="Order ID" filter filterPlaceholder="Search by ID" />
                                        <Column sortable={true} field="table_name" header="Table No" filter filterPlaceholder="Search by Table.no" />
                                        <Column sortable={true} field="peoples" header="No of people" filter filterPlaceholder="Search by No of people" />
                                        {/* <Column sortable={true} field="order_id" header="Order ID" filter filterPlaceholder="Search by ID" /> */}
                                        <Column sortable={true} field="customer" header="Customer" filter filterPlaceholder="Search by Customer" />
                                        <Column sortable={true} field="telephone" header="Phone Number" filter filterPlaceholder="Search by Phone Number" />
                                        <Column sortable={true} field="total_text" header="Total" filter filterPlaceholder="Search by Total" filterMatchMode="contains" bodyStyle={{ textAlign: 'right' }} />
                                        <Column field="status" body={statusBodyTemplate} header="Status" filter filterElement={statusFilter} />
                                        <Column body={actionTemplate} />
                                    </DataTable>
                                </div>
                            </div>
                    }
                </Card>
            </div>
        </div >
    )
})