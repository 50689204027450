import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { SelectButton } from 'primereact/selectbutton';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { Fragment, useEffect, useState } from 'react';
import { FiPlus, FiXCircle } from 'react-icons/fi';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import useForceUpdate from '../stores/useForceUpdate';
import { useStore } from '../stores/useStore';

const FormErrorMsg = styled.div`
 color: red;
`
export const Deals = observer((props: any) => {
    const [errorMsg, setErrorMsg] = useState(null)
    const history = useHistory();
    const { mainStore } = useStore();
    const { id } = useParams<any>();
    const editMode = props?.id || id ? true : false;
    const [loading, setLoading] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState<any>()
    const [categories, setCategories] = useState([]);
    const [stores, setStores] = useState([]);
    const [options, setOptions] = useState([]);
    const [formData, setFormData] = useState({});
    const [productData, setProductData] = useState<any>()
    const [selectedBundleId, setSelectedBundleId] = useState<any>(0)
    const [selectedOptionId, setSelectedOptionId] = useState<any>()
    const forceUpdate = useForceUpdate()
    const [pdt, setPdt] = useState<any>()
    const [opt, setOpt] = useState<any>()
    const [filteredProductData, setFilteredProductData] = useState<any>([])
    const [selectedProduct, setSelectedProduct] = useState<any>([])
    const [update, setUpdate] = useState<any>(false)

    let currentProductIndex: any
    let productOptions: any = []

    useEffect(() => {
        setLoading(true)

        if (editMode) {
            mainStore.getProduct(Number(props.id ? props.id : id))
                .then(([success, data]) => {
                    if (success) {
                        // mainStore.imageURL = data?.thumb ? data?.thumb : '/defaultImage.png';
                        // data.product_option = data?.product_options
                        // console.log("data", data)
                        let bundled = data?.bundled_items?.map((item: any) => ({
                            name: item?.bundle_name,
                            quantity: item?.bundle_quantity,
                            sort_order: item?.sort_order,
                            ismainitem: item?.ismainitem,
                            product: item?.products,
                        }))
                        data.bundled = bundled
                        delete data['bundled_items']
                        // console.log("temp", data)
                        setFormData(data)
                        // setOptionValue(data.product_option)
                        return Promise.all([mainStore.getCategories(), mainStore.getStores(), mainStore.getOptions(), mainStore.getProducts()])
                    } else {
                        throw new Error(data)
                    }
                }).then(values => {
                    if (values[0][0]) {
                        setCategories(values[0][1]?.category)
                    }
                    if (values[1][0]) {
                        setStores(values[1][1])
                    }

                    if (values[2][0]) {
                        setOptions(values[2][1]?.options)
                    }
                    if (values[3][0]) {
                        let data = values[3][1]?.products?.map((el: any, key: any) => productOptions.push({ id: key, name: el.name, code: el.product_id }))
                        // console.log("data filter", data1)
                        setProductData(productOptions)
                    }
                })
                .catch(err => {
                    setErrorMsg(err)
                })
                .finally(() => setLoading(false))


        } else {
            setFormData({
                product_id: null,
                product_description: { 1: { name: null } }, price: null,
                status: '0',
                product_category: [],
                product_store: [],
                bundled: [
                    {
                        name: "",
                        quantity: "",
                        sort_order: "",
                        ismainitem: "0",
                        product: [],
                    },

                ],
                deal_condition_criteria: { qty: "", amt_type: "", type: "", amount: "" },
                suggestion_condition: null, dealsuggestion: "0",
            })


            Promise.all([mainStore.getCategories(), mainStore.getStores(), mainStore.getOptions(), mainStore.getProducts()])
                .then(values => {
                    if (values[0][0]) {
                        setCategories(values[0][1]?.category)
                    }
                    if (values[1][0]) {
                        setStores(values[1][1])
                    }

                    if (values[2][0]) {
                        setOptions(values[2][1]?.options)
                    }
                    if (values[3][0]) {

                        let data = values[3][1]?.products?.map((el: any, key: any) => productOptions.push({ id: key, name: el.name, code: el.product_id }))
                        setProductData(productOptions)
                    }
                })
                .catch(err => {
                    setErrorMsg(err)
                })
                .finally(() => setLoading(false))
        }

    }, [])

    useEffect(() => {
        setUpdate(false)

    }, [update])

    const dealsCondition = [
        { name: 'Normal', code: '' },
        { name: 'Individual price', code: 'I' },
        { name: 'Biggest item price', code: 'B' },
        { name: 'Smallest item price', code: 'S' },
        { name: 'Half & half item', code: 'H' },
        { name: 'Half & half with largest item price', code: 'L' }
    ]

    const suggestionConditions = [
        { name: 'Any Main Items In Cart', code: '0' },
        { name: 'All Main Items In Cart', code: '1' },
    ]

    const extraPriceSign = [
        { label: '+', id: '+' },
        { label: '-', id: '-' },
    ]

    const extraPriceCategory = [
        { name: 'F', code: 'F' },
        { name: '%', code: '%' },
    ]

    const AddOptions = (productItem: any, formik: any, bundledIndex: any) => {
        // console.log("product code", productItem, "code", productItem?.code)
        let product: any
        mainStore.getProduct(Number(productItem?.code))
            .then(([success, data]) => {
                if (success) {
                    product = [{ product_name: productItem.name, product_id: productItem.code, options: data?.product_options }]
                    formik.values?.bundled[bundledIndex]?.product?.push({
                        product_name: productItem?.name,
                        product_id: productItem?.code,
                        options:
                            data?.product_options?.map((item: any) => (
                                {
                                    name: item?.name,
                                    option_id: item?.option_id,
                                    product_option_id: item.product_option_id,
                                    product_option_value: item?.product_option_value?.map((el: any) => (
                                        {
                                            name: el?.name,
                                            product_option_value_id: el?.product_option_value_id
                                        }
                                    ))
                                }))

                    })
                    // console.log("data", data)
                    setSelectedProduct("")
                    setSelectedBundleId(bundledIndex)
                    setSelectedProductId(productItem?.code);
                    var productIndex = formik.values?.bundled[bundledIndex]?.product.length - 1
                    setPdt(productItem?.code + '_' + productIndex);
                }
                else {
                    setErrorMsg(data)
                }
            })
            .finally(() => forceUpdate())
    }

    const handleReset = () => {
        mainStore.menuDialogBox = false;
        mainStore.menuDeal = false;
        setSelectedBundleId(null);
        setSelectedProductId(null);
        setSelectedOptionId(null)
        setFormData({
            product_id: null,
            product_description: { 1: { name: '', shortcode: '' } },
            price: '',
            model: '',
            status: '0',
            product_category: [],
            product_store: [],
            bundled: [
                {
                    name: "",
                    quantity: "",
                    sort_order: "",
                    ismainitem: "0",
                    product: [],
                },

            ],
            dealsuggestion: '0',
            deal_condition_criteria: { qty: "", amt_type: "", type: "", amount: "" },
            suggestion_condition: null,
        })
    }


    const searchProduct = (product: any) => {
        setTimeout(() => {
            let filteredCountries;
            if (!product?.query?.trim()?.length) {
                filteredCountries = [...productData];
            }
            else {
                filteredCountries = productData?.filter((productItem: any) => {
                    return productItem?.name?.toLowerCase()?.startsWith(product?.query?.toLowerCase());
                });
            }

            setFilteredProductData(filteredCountries);
        }, 250);
    }


    // console.log("pdt", productData)

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="p-grid p-justify-between">
                    <div className="p-col">
                        <div className='page-heading'>
                            {mainStore?.menuDeal == false && editMode ? <i className="pi pi-arrow-left" style={{ 'fontSize': '1em' }} onClick={() => history.goBack()}></i> : ""}
                            {mainStore?.menuDeal == false && editMode ? <div className='inline-spacer' /> : ""}
                            {editMode ? 'View' : 'New'} Deals
                        </div>
                    </div>
                </div>
            </div>

            <div className='p-col-12'>
                <Formik
                    initialValues={formData}
                    enableReinitialize={true}
                    validationSchema={yup.object({
                        product_description: yup.object({ 1: yup.object({ name: yup.string().required('Required').nullable() }) }),
                        price: yup.number().required('Required').nullable(),
                        bundled: yup.array().of(yup.object({ name: yup.string().required('Required'), product: yup.array().required('Required') }))
                    })}
                    onSubmit={(values: any, { setSubmitting }) => {

                        // console.log("values 1", values)
                        values.bundled = values?.bundled?.map((bundleItem: any) => ({
                            name: bundleItem?.name,
                            quantity: bundleItem?.quantity,
                            sort_order: bundleItem?.sort_order,
                            ismainitem: bundleItem?.ismainitem,

                            product: bundleItem?.product?.map((productItem: any) => (

                                {
                                    product_id: productItem?.product_id,
                                    extraprice: productItem?.extra_price,

                                    options: [productItem?.options?.map((optionItem: any) => (
                                        optionItem?.product_option_value?.map((item: any) => (

                                            item?.product_option_value_id

                                        ))

                                    )).flat()][0]

                                    // options: productItem?.options?.map((optionItem: any) => (

                                    //     optionItem?.product_option_value?.map((valueItem: any) => {
                                    //         return (
                                    //             valueItem?.product_option_value_id
                                    //         )

                                    //     }))

                                    // )

                                }

                            ))

                        }))

                        mainStore.updateProduct(values)
                            .then(([success, data]) => {
                                if (success) {
                                    if (props?.id) {
                                        mainStore.menuDialogBox = false;
                                        mainStore.menuDeal = false;
                                        history.push('/menu')
                                    } else {
                                        history.push('/products')
                                    }

                                } else {
                                    setErrorMsg(data?.error)
                                }
                            })
                        // console.log("values", values)
                    }}
                >
                    {formik =>
                        <Card>
                            <Form>
                                {loading ? <div>Loading...</div> :
                                    errorMsg ? <div className='error-msg'>{errorMsg}</div> :
                                        <>
                                            {/* {console.log("formik", formik?.values?.bundled)} */}
                                            <div className='p-grid'>
                                                <div className='p-col-12 p-md-4 p-fluid'>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Name</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='product_description.1.name' as={InputText} />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='product_description.1.name' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='p-col-12 p-md-2 p-fluid'>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Short Code</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='product_description.1.shortcode' as={InputText} />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='product_description.1.shortCode' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='p-col-12 p-md-2 p-fluid'>
                                                    <div className='p-grid  form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Product Code</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='model' as={InputText} />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='model' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='p-col-12 p-md-2 p-fluid'>
                                                    <div className='p-grid  form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Product ID for API</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='ec_product_id' as={InputText} />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='ec_product_id' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='p-col-12 p-md-1 p-fluid'>
                                                    <div className='p-grid  form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Price</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='price' as={InputText} className='right' />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='price' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='p-col-12 p-md-1 p-fluid'>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Enabled</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='status' type='checkbox' as={Checkbox} value={formik.values['status'] ? '1' : '0'} checked={formik.values['status'] == '1' ? true : false} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='p-grid p-fluid'>
                                                <div className='p-col-12 p-md-6'>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Categories</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <div className="p-inputgroup">
                                                                <Field name='product_category' as={MultiSelect} optionLabel='name' optionValue='category_id' options={categories} filter={true} />
                                                            </div>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='product_category' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='p-col-12 p-md-6'>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Stores</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='product_store' as={MultiSelect} optionLabel='name' optionValue='store_id' options={stores} />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='product_store' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-grid p-fluid">
                                                <div className='p-col-12 p-md-6'>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Deal Condition</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='dealcondition' as={Dropdown} options={dealsCondition} optionValue='code' optionLabel="name" />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='dealcondition' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="p-grid p-fluid"> */}
                                                <div className='p-col-12 p-md-3'>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Enable suggestion in EPOS</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name="dealsuggestion" type='checkbox' as={Checkbox} checked={formik?.values?.dealsuggestion == "1" ? true : false} onChange={(e: any) => { formik.setFieldValue(`dealsuggestion`, e?.target?.checked == true ? "1" : "0") }} />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name="dealsuggestion" component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* </div> */}

                                                <div className='p-col-12 p-md-3'>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Suggestion Condition</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <div className="p-inputgroup">
                                                                <Field name='suggestion_condition' options={suggestionConditions} as={Dropdown} optionLabel='name' optionValue='code' />
                                                            </div>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='suggestion_condition' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-grid p-fluid">
                                                <div className='p-col-12 p-md-6'>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Quantity</label><br />
                                                            <label style={{ fontSize: "10px" }}>Quantity that can be applied for above deal condition</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='deal_condition_criteria.qty' as={InputText} keyfilter="int" />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='deal_condition_criteria.qty' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='p-col-12 p-md-6'>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Extra Price</label><br />
                                                            <label style={{ fontSize: "10px" }}>The Extra Price that can be added to deal price</label>
                                                        </div>
                                                        <div className='p-col-3 p-md-2'>
                                                            <Field name='deal_condition_criteria.amt_type' as={Dropdown} options={extraPriceSign} optionLabel="label" optionValue="id" />
                                                        </div>
                                                        <div className='p-col-3 p-md-2'>
                                                            <Field as={Dropdown} name='deal_condition_criteria.type' options={extraPriceCategory} optionLabel='name' optionValue='code' />
                                                        </div>
                                                        <div className='p-col-6 p-md-8'>
                                                            <Field name='deal_condition_criteria.amount' as={InputText} className='right' />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='deal_condition_criteria' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="p-grid p-fluid">
                                                <div className='p-col-12 '>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-7 p-sm-4 p-md-3 p-lg-2'>
                                                            <label>Enable suggestion in EPOS</label>
                                                        </div>
                                                        <div className='p-col-2 p-sm-1 p-md-1 p-lg-2'>
                                                            <Field name="dealsuggestion" type='checkbox' as={Checkbox} checked={formik?.values?.dealsuggestion == "1" ? true : false} onChange={(e: any) => { formik.setFieldValue(`dealsuggestion`, e?.target?.checked == true ? "1" : "0") }} />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name="dealsuggestion" component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className='p-col-12' style={{ height: '1.0em' }}> </div>

                                            <FieldArray name='bundled'>
                                                {arrayHelpers =>
                                                    <> {formik.values?.bundled?.length >= 0 &&
                                                        formik?.values?.bundled?.map((bundledItem: any, bundledIndex: any) => (
                                                            <Fragment>

                                                                <div style={{ position: 'relative', background: '#E5EAFA', padding: '10px', margin: '10px' }} key={bundledIndex}>
                                                                    {formik.values?.bundled?.length > 1 && <FiXCircle color='red' size='20px' fontWeight='bold' style={{ position: 'absolute', right: '0', top: '0' }} type="button" className="secondary" onClick={() => arrayHelpers.remove(bundledIndex)} />}
                                                                    <div className='p-grid p-fluid'>
                                                                        <div className='p-col-12' style={{ height: '2.0em' }}> </div>
                                                                        < div className='p-col-12 p-md-4'>
                                                                            <div className='p-grid form-control-group'>
                                                                                <div className='p-col-12'>
                                                                                    <label>Deal Item {bundledIndex + 1}</label>
                                                                                </div>
                                                                                <div className='p-col-12'>
                                                                                    <div className="p-inputgroup">
                                                                                        <Field name={`bundled.${bundledIndex}.name`} as={InputText} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='p-col-12'>
                                                                                    <ErrorMessage name={`bundled.${bundledIndex}.name`} component={FormErrorMsg} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className='p-col-12 p-md-2'>
                                                                            <div className='p-grid form-control-group'>
                                                                                <div className='p-col-12'>
                                                                                    <label>Quantity</label>
                                                                                </div>
                                                                                <div className='p-col-12'>
                                                                                    <div className="p-inputgroup">
                                                                                        <Field name={`bundled.${bundledIndex}.quantity`} as={InputText} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='p-col-12'>
                                                                                    <ErrorMessage name={`bundled.${bundledIndex}.quantity`} component={FormErrorMsg} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className='p-col-12 p-md-3'>
                                                                            <div className='p-grid form-control-group'>
                                                                                <div className='p-col-12'>
                                                                                    <label>Sort Order</label>
                                                                                </div>
                                                                                <div className='p-col-12'>
                                                                                    <Field name={`bundled.${bundledIndex}.sort_order`} as={InputText} />
                                                                                </div>
                                                                                <div className='p-col-12'>
                                                                                    <ErrorMessage name={`bundled.${bundledIndex}.sort_order`} component={FormErrorMsg} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className='p-col-12 p-md-3 '>
                                                                            <div className='p-grid form-control-group'>
                                                                                <div className='p-col-6 p-sm-6' >
                                                                                    <label >Main Deal Item</label> <br />
                                                                                    <label style={{ fontSize: "10px" }}>For Epos Suggestion</label>
                                                                                </div>
                                                                                <div className='p-col-2 p-sm-6'>
                                                                                    <Field name={`bundled.${bundledIndex}.ismainitem`} as={Checkbox} value={bundledItem?.ismainitem ? '1' : '0'} checked={bundledItem?.ismainitem == '1' ? true : false} />
                                                                                </div>
                                                                                <div className='p-col-12'>
                                                                                    <ErrorMessage name={`bundled.${bundledIndex}.ismainitem`} component={FormErrorMsg} />
                                                                                </div>
                                                                            </div>
                                                                        </div>



                                                                    </div>

                                                                    <div className='p-grid p-fluid'>
                                                                        <div className='p-col-12'>
                                                                            <div className='p-grid form-control-group'>
                                                                                <div className='p-col-12'>
                                                                                    <label>Choose a product</label>
                                                                                </div>
                                                                                <div className='p-col-12 p-md-6'>
                                                                                    {/* <Field as={Dropdown} name={`bundled.${bundledIndex}.product`} options={productData} placeholder="Select a Product"
                                                                                        onChange={(e: any) => AddOptions(e.value, formik, bundledIndex)} optionLabel="name" filter={true} filterBy="name" filterMatchMode="startsWith" /> */}

                                                                                    {/* <AutoComplete
                                                                                        dropdown
                                                                                        field="name"
                                                                                        // name={`bundled.${bundledIndex}.product`}
                                                                                        value={selectedProduct}
                                                                                        suggestions={filteredProductData}
                                                                                        completeMethod={searchProduct}
                                                                                        onSelect={(e: any) => AddOptions(e.value, formik, bundledIndex)}
                                                                                        onChange={(e) => { setSelectedProduct(e.value); console.log("bundled index", bundledIndex) }}
                                                                                        placeholder="Search a Product"
                                                                                    /> */}

                                                                                    <Field
                                                                                        dropdown
                                                                                        as={AutoComplete}
                                                                                        field="name"
                                                                                        name={`bundled.${bundledIndex}.product`}
                                                                                        // key={bundledIndex}
                                                                                        // id={selectedBundleId}
                                                                                        value={bundledIndex == selectedBundleId && selectedProduct}
                                                                                        suggestions={filteredProductData}
                                                                                        completeMethod={searchProduct}
                                                                                        onSelect={(e: any) => { AddOptions(e.value, formik, bundledIndex) }}
                                                                                        onChange={(e: any) => { setSelectedBundleId(bundledIndex); setSelectedProduct(e.value) }}
                                                                                        placeholder="Search a Product"
                                                                                    />
                                                                                    {/* 
                                                                                    <AutoComplete
                                                                                        dropdown
                                                                                        field="name"
                                                                                        value={selectedProduct}
                                                                                        suggestions={filteredProductData}
                                                                                        completeMethod={searchProduct}
                                                                                        onSelect={(e: any) => { AddOptions(e.value, formik, bundledIndex); setSelectedBundleId(bundledIndex) }}
                                                                                        onChange={(e) => { bundledIndex == setSelectedBundleId && setSelectedProduct(e.value); console.log("bundled index", bundledIndex) }}
                                                                                        placeholder="Search a Product"
                                                                                    /> */}

                                                                                </div>
                                                                                <div className='p-col-12'>
                                                                                    <ErrorMessage name={`bundled.${bundledIndex}.product`} component={FormErrorMsg} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {bundledItem?.product?.length !== 0 &&
                                                                        // <TabView>
                                                                        <div className='p-grid p-fluid'>
                                                                            <div className='p-col-12 p-md-4'>
                                                                                {bundledItem?.product?.map((productItem: any, productIndex: any) => <>
                                                                                    {/* <TabPanel header={productItem?.product_name}></TabPanel> */}
                                                                                    <Card style={{ marginTop: '5px', position: 'relative', background: (selectedBundleId == bundledIndex && productItem?.product_id + '_' + productIndex == pdt) && '#80deea' }}>
                                                                                        <span style={{ position: 'absolute', top: '0', right: '0' }}>  <FiXCircle color='red' size='1.1em' onClick={() => { formik?.values?.bundled[bundledIndex]?.product.splice(productIndex, 1); props?.id && setUpdate(true) }} /></span>
                                                                                        <div className='p-grid p-fluid'>
                                                                                            <div className='p-col-12 p-md-6'
                                                                                                onClick={(e: any) => {
                                                                                                    let productSelected = formik?.values?.bundled[bundledIndex]?.product?.filter((productElement: any, productKey: any) => productElement.product_id == productItem.product_id)
                                                                                                    // let currentBundleIndex = formik?.values?.bundled[bundledIndex]?.product?.map((productElement: any, productKey: any) => {
                                                                                                    //     if (productElement.product_id + '_' + productIndex == productItem.product_id + '_' + productIndex) {

                                                                                                    //         return bundledIndex

                                                                                                    //     }
                                                                                                    // })

                                                                                                    // console.log("currentBundleIndex", bundledIndex)
                                                                                                    // setSelectedBundleId(currentBundleIndex.join(' ').toString())
                                                                                                    setSelectedBundleId(bundledIndex)
                                                                                                    setSelectedProductId(productSelected[0]?.product_id);
                                                                                                    setPdt(productSelected[0]?.product_id + '_' + productIndex);


                                                                                                }}>
                                                                                                {productItem?.product_name}
                                                                                            </div>
                                                                                            <div className='p-col-12 p-md-6'>
                                                                                                <Field as={InputText} name={`bundled.${bundledIndex}.product.${productIndex}.extra_price`} placeholder='Extra price' />
                                                                                            </div>
                                                                                        </div>
                                                                                    </Card>
                                                                                </>
                                                                                )}
                                                                            </div>

                                                                            <div className='p-col-12 p-md-8' style={{ display: 'relative' }}>
                                                                                <div className='p-grid p-fluid' style={{ position: 'relative' }}>
                                                                                    {bundledItem?.product?.map((productItem: any, productIndex: any) => {
                                                                                        if (selectedBundleId == bundledIndex && productItem.product_id + '_' + productIndex == pdt) {
                                                                                            currentProductIndex = productIndex
                                                                                            return (
                                                                                                formik?.values?.bundled[bundledIndex]?.product[productIndex]?.options?.length == 0 ?
                                                                                                    <div className="p-col p-col-align-center" style={{ display: 'flex', top: '50%', left: '50%', color: 'grey', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                                                                                                        No options available for this product
                                                                                                    </div> :
                                                                                                    productItem?.options?.map((optionsItem: any, optionsIndex: any) =>
                                                                                                        <div className='p-col-6 p-md-4'>
                                                                                                            <Card key={optionsIndex} style={{ marginTop: '5px', position: 'relative', background: (optionsItem?.product_option_id + '_' + productIndex == opt && bundledIndex == selectedBundleId) && '#b3e5fc' }}>
                                                                                                                {/* <Card key={optionsIndex} style={{ marginTop: '5px', position: 'relative', border: (optionsItem?.product_option_id + '_' + productIndex == opt && bundledIndex == selectedBundleId) && 'thin #FF4500 solid' }}> */}
                                                                                                                {/* <div style={{ position: 'absolute', right: '0', top: '0' }} >
                                                                                                                    <FiXCircle color='red' onClick={() => { formik?.values?.bundled[bundledIndex]?.product[productIndex]?.options?.splice(optionsIndex, 1) }} />
                                                                                                                </div> */}
                                                                                                                <div onClick={() => {
                                                                                                                    setSelectedOptionId(formik?.values?.bundled[bundledIndex]?.product[productIndex]?.options[optionsIndex].product_option_id); setOpt(formik?.values?.bundled[bundledIndex]?.product[productIndex]?.options[optionsIndex].product_option_id + '_' + productIndex)
                                                                                                                }}>
                                                                                                                    {optionsItem?.name}
                                                                                                                </div>
                                                                                                            </Card>
                                                                                                        </div>

                                                                                                    ))
                                                                                        }

                                                                                        else {
                                                                                            return null
                                                                                        }

                                                                                    })}
                                                                                </div>

                                                                                {formik?.values?.bundled[bundledIndex]?.product[currentProductIndex]?.options?.map((optionValue: any, optionValueIndex: any) => {
                                                                                    if (selectedBundleId == bundledIndex && optionValue?.product_option_id + '_' + currentProductIndex == opt) {
                                                                                        return (
                                                                                            optionValue?.product_option_value?.length != 0 &&
                                                                                            <div style={{ padding: '10px' }}>
                                                                                                {/* borderWidth: 'thin', borderColor: '#FF4500', borderStyle: 'solid' */}

                                                                                                {optionValue?.product_option_value?.map((optionItem: any, optionKey: any) =>
                                                                                                    <div className='p-col-6 p-md-4' style={{ padding: '2px' }} key={optionKey} >
                                                                                                        {/* background: optionKey % 2 == 0 ? '#f2f2f2' : '#ffffff', */}

                                                                                                        <div className="p-grid">
                                                                                                            <div className="p-col-10 p-sm-6">
                                                                                                                {optionItem.name}
                                                                                                            </div>

                                                                                                            <div className="p-col-2 p-sm-3" >
                                                                                                                <FiXCircle color='red' size='1.4em' onClick={() => { formik?.values?.bundled[bundledIndex]?.product[currentProductIndex]?.options[optionValueIndex]?.product_option_value?.splice(optionKey, 1); props?.id && setUpdate(true) }} />
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        {/* <div className="p-col-6 p-md-4">
                                                                                                            <Field as={InputText} name={`bundled.${bundledIndex}.product.${currentProductIndex}.options.${optionValueIndex}.product_option_value.${optionKey}.extra_price`} placeholder='extra price' />
                                                                                                        </div> */}

                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    else {
                                                                                        return null
                                                                                    }
                                                                                })}

                                                                            </div>
                                                                        </div>

                                                                        // </TabView>


                                                                    }

                                                                </div>
                                                            </Fragment>
                                                        ))}

                                                        <div className="p-grid p-fluid" style={{ marginTop: '5px' }} >
                                                            <div style={{ marginLeft: '5px' }}> Add new deal item </div>
                                                            <div style={{ marginLeft: '10px' }} >
                                                                <FiPlus color="red" size="18px" onClick={() => arrayHelpers.push({
                                                                    name: "",
                                                                    quantity: "",
                                                                    sort_order: "",
                                                                    ismainitem: "0",
                                                                    product: [],
                                                                })} />
                                                            </div>
                                                        </div>
                                                    </>}
                                            </FieldArray>

                                            <div className='p-grid '>
                                                <div className="p-col form-action-container ">

                                                    {/* disabled={formik.isSubmitting} */}
                                                    <Button type='submit' disabled={formik.isSubmitting} label={editMode ? 'Update' : 'Save'} className='p-button-primary' />
                                                    <Button type='reset' onClick={() => handleReset()} label="Cancel" className='p-button-secondary' />
                                                </div>
                                            </div>
                                        </>
                                }
                            </Form>
                        </Card>
                    }
                </Formik>
            </div>
        </div >
    )
})


