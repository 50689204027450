import { Capacitor } from '@capacitor/core';
import { Plugins } from '@capacitor/core';
import classNames from 'classnames';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import { Messages } from 'primereact/messages';
import 'primereact/resources/primereact.min.css';
import React, { Component } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppTopbar } from './AppTopbar';
import { BasicOrderDetails } from './pages/BasicOrderDetails';
import { CategoryForm } from './pages/CategoryForm';
import { CategoryList } from './pages/CategoryList';
import { Dashboard } from './pages/Dashboard';
import { Deals } from './pages/Deals';
import { ImageManager } from './components/ImageManager';
import { DressingForm } from './pages/DressingForm';
import { DressingList } from './pages/DressingList';
import { EmptyPage } from './pages/EmptyPage';
import { EndOfDayReport } from './pages/EndOfDayReport';
import { MenuPage } from './pages/MenuPage';
import { OptionForm } from './pages/OptionForm';
import { OptionList } from './pages/OptionList';
import { OrderDetails } from './pages/OrderDetails';
import { OrderListPage } from './pages/OrderListPage';
import { ProductForm } from './pages/ProductForm';
import { ProductList } from './pages/ProductList';
import { TableOrderList } from './pages/TableOrderList';
import { ToppingForm } from './pages/ToppingForm';
import { ToppingList } from './pages/ToppingList';
import { ZReport } from './pages/ZReport';
import { ZReportList } from './pages/ZReportList';
import { useStore } from './stores/useStore';
import { HomeDelivery } from './pages/HomeDelivery';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            layoutMode: 'static',
            lightMenu: true,
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            isRTL: false,
            topbarColor: 'layout-topbar-indigo',
            inlineUser: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            rightPanelMenuActive: null,
            inlineUserMenuActive: false,
            menuActive: false,
            themeColor: 'blue',
            configDialogActive: false,

        };
        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
        this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
        this.onRightMenuButtonClick = this.onRightMenuButtonClick.bind(this);
        this.onRightMenuClick = this.onRightMenuClick.bind(this);
        this.onProfileMenuClick = this.onProfileMenuClick.bind(this);
        this.changeMenuMode = this.changeMenuMode.bind(this);
        this.changeMenuColor = this.changeMenuColor.bind(this);
        this.changeProfileMode = this.changeProfileMode.bind(this);
        this.changeOrientation = this.changeOrientation.bind(this);
        this.changeTopbarColor = this.changeTopbarColor.bind(this);
        this.changeTheme = this.changeTheme.bind(this);
        this.onConfigButtonClick = this.onConfigButtonClick.bind(this);
        this.onConfigCloseClick = this.onConfigCloseClick.bind(this);
        this.onConfigClick = this.onConfigClick.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.createMenu();
    }

    showInfo(notificationData) {
        this.messages.show({ life: 5000, severity: 'success', summary: notificationData });
    }

    onDocumentClick(event) {
        if (!this.topbarItemClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }

        if (!this.rightMenuClick) {
            this.setState({ rightPanelMenuActive: false });
        }

        if (!this.configClick) {
            this.setState({ configDialogActive: false });
        }

        if (!this.profileClick && this.isSlim() && !this.isMobile()) {
            this.setState({ inlineUserMenuActive: false })
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.setState({
                    menuActive: false
                })
            }

            if (this.state.overlayMenuActive || this.state.staticMenuMobileActive) {
                this.hideOverlayMenu();
            }

            this.setState({ menuHoverActive: false });
            this.unblockBodyScroll();
        }

        this.topbarItemClick = false;
        this.menuClick = false;
        this.rightMenuClick = false;
        this.profileClick = false;
        this.configClick = false;
    }
    onMenuButtonClick(event) {
        this.menuClick = true;
        this.setState(({
            topbarMenuActive: false,
            rightPanelMenuActive: false
        }));

        if (this.isOverlay()) {
            this.setState({
                overlayMenuActive: !this.state.overlayMenuActive
            });
        }

        if (this.isDesktop())
            this.setState({ staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive });
        else {
            this.setState({ staticMenuMobileActive: !this.state.staticMenuMobileActive });
            if (this.state.staticMenuMobileActive) {
                this.blockBodyScroll();
            } else {
                this.unblockBodyScroll();
            }
        }

        event.preventDefault();
    }

    onConfigButtonClick(event) {
        this.configClick = true;
        this.setState({ configDialogActive: !this.state.configDialogActive })
    }

    onConfigCloseClick() {
        this.setState({ configDialogActive: false })
    }

    onConfigClick() {
        this.configClick = true;
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.setState({ topbarMenuActive: !this.state.topbarMenuActive });
        this.hideOverlayMenu();
        event.preventDefault();
    }

    onTopbarItemClick(event) {
        this.topbarItemClick = true;

        if (this.state.activeTopbarItem === event.item)
            this.setState({ activeTopbarItem: null });
        else
            this.setState({ activeTopbarItem: event.item });

        event.originalEvent.preventDefault();
    }
    onMenuClick(event) {
        this.menuClick = true;
    }

    blockBodyScroll() {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    }

    unblockBodyScroll() {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }
    onRightMenuButtonClick(event) {
        this.rightMenuClick = true;
        this.setState({ rightPanelMenuActive: !this.state.rightPanelMenuActive });

        this.hideOverlayMenu();

        event.preventDefault();
    }

    onRightMenuClick(event) {
        this.rightMenuClick = true;
    }

    onProfileMenuClick(event) {
        this.profileClick = true;
        this.setState({ inlineUserMenuActive: !this.state.inlineUserMenuActive })
    }

    hideOverlayMenu() {
        this.setState({
            overlayMenuActive: false,
            staticMenuMobileActive: false
        })
    }
    onMenuItemClick(event) {
        if (!event.item.items) {
            this.hideOverlayMenu();
        }
        if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
            this.setState({
                menuActive: false
            })
        }
    }

    onRootMenuItemClick(event) {
        this.setState({
            menuActive: !this.state.menuActive
        });
    }

    isTablet() {
        const width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 896;
    }

    isMobile() {
        return window.innerWidth <= 1025;
    }

    isStatic() {
        return this.state.layoutMode === 'static';
    }

    isOverlay() {
        return this.state.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    isSlim() {
        return this.state.layoutMode === 'slim';
    }

    changeMenuMode(event) {
        this.setState({
            layoutMode: event.menuMode,
            staticMenuDesktopInactive: false,
            overlayMenuActive: false
        });
        if (event.menuMode === 'slim' || event.menuMode === 'horizontal') {
            this.setState({
                inlineUser: false,
                inlineUserMenuActive: false
            })
        }
    }

    changeMenuColor(event) {
        this.setState({ lightMenu: event.lightMenu })
    }

    changeProfileMode(event) {
        if (!event.inlineUser) {
            this.setState({
                inlineUser: event.inlineUser,
                inlineUserMenuActive: event.inlineUser
            })
        }
        else {
            if (!this.isHorizontal()) {
                this.setState({
                    inlineUser: event.inlineUser
                })
            }
        }
    }

    changeOrientation(event) {
        this.setState({ isRTL: event.isRTL })
    }

    changeTopbarColor(event) {
        this.setState({ topbarColor: event.topbarColor });
        const topbarLogoLink = document.getElementById('topbar-logo');
        topbarLogoLink.src = 'assets/layout/images/' + event.logo + '.svg';
    }

    changeTheme(event) {
        this.setState({ themeColor: event.theme })
        this.changeStyleSheetUrl('layout-css', event.theme, 'layout');
        this.changeStyleSheetUrl('theme-css', event.theme, 'theme');
    }

    changeStyleSheetUrl(id, value, prefix) {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');

        this.replaceLink(element, newURL);
    }

    isIE() {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
    }

    replaceLink(linkElement, href) {
        if (this.isIE()) {
            linkElement.setAttribute('href', href);
        }
        else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    }

    createMenu() {
        this.menu = [
            { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' },
            { label: 'Orders', icon: 'pi pi-fw pi-table', to: '/orderlist' },
            { label: 'Table Orders', icon: 'pi pi-fw pi-table', to: '/tableorders' },
            { label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/menu' },
            // { label: 'Home delivery', icon: 'pi pi-fw pi-directions', to: '/homedelivery' },
            {
                label: 'Catalog', icon: 'pi pi-fw pi-file',
                items: [
                    { label: 'Products', icon: 'pi pi-fw pi-copy', to: '/products' },
                    { label: 'Categories', icon: 'pi pi-fw pi-sliders-h', to: '/categories' },
                    { label: 'Options', icon: 'pi pi-fw pi-list', to: '/options' },
                    { label: 'Toppings', icon: 'pi pi-fw pi-list', to: '/toppings' },
                    { label: 'Dressings', icon: 'pi pi-fw pi-list', to: '/dressings' },
                    { label: 'Deals', icon: 'pi pi-fw pi-list', to: '/deals/new' }
                ]
            },
            { label: 'Reports', icon: 'pi pi-fw pi-list', to: '/endofdayreport' },
        ];
    }


    componentDidMount() {

        if (Capacitor.getPlatform() == 'android' | 'ios') {
            localStorage.setItem('platform', 'app')
            // console.log("andriod--------------------")
            // do something        

            Plugins.PushNotifications.register();

            // On succcess, we should be able to receive notifications
            Plugins.PushNotifications.addListener('registration',
                (token) => {
                    localStorage.setItem('device_token', token.value)
                }
            );

            // Some issue with your setup and push will not work
            Plugins.PushNotifications.addListener('registrationError',
                (error) => {
                    // alert('Error on registration: ' + JSON.stringify(error));
                }
            );

            // Show us the notification payload if the app is open on our device
            Plugins.PushNotifications.addListener('pushNotificationReceived',
                (notification) => {

                    // console.log("Notification", notification?.data?.body);                  
                    if (notification?.data) {
                        // window.messaging.onMessage((payload) => {

                        const notificationData = notification?.data?.body
                        this.showInfo(notificationData)

                        if (notificationData === "New Table Order Placed.") {
                            this.props.mainStore.newTableOrder = true
                        }

                        if (notificationData === "New Order Placed" || notificationData === "New Table Order Placed.") {
                            this.props.mainStore.newOrder = true
                        }
                    }
                }
            );

            // Method called when tapping on a notification
            Plugins.PushNotifications.addListener('pushNotificationActionPerformed',
                (notification) => {

                    if (notification) {

                        const notificationData = notification?.notification?.data?.body

                        if (notificationData === "New Table Order Placed.") {
                            this.props.mainStore.newTableOrder = true
                            this.props.mainStore.redirect = notification?.notification?.data?.link
                        }

                        if (notificationData === "New Order Placed") {
                            this.props.mainStore.newOrder = true
                            this.props.mainStore.redirect = notification?.notification?.data?.link
                        }
                    }

                }
            );
        } else {
            localStorage.setItem('platform', 'web')
            window.messaging.onMessage((payload) => {

                const notificationData = payload.data?.body
                this.showInfo(notificationData)
                if (notificationData === "New Table Order Placed.") {
                    this.props.mainStore.newTableOrder = true
                }
                if (notificationData === "New Order Placed" || notificationData === "New Table Order Placed.") {
                    this.props.mainStore.newOrder = true
                }


            });
            navigator.serviceWorker.onmessage = (event) => {
                const backgroundnotificationData = event.data?.data?.body
                if (backgroundnotificationData === "New Table Order Placed.") {
                    this.props.mainStore.newTableOrder = true
                }

                if (backgroundnotificationData === "New Order Placed" || backgroundnotificationData === "New Table Order Placed.") {
                    this.props.mainStore.newOrder = true
                }
            };
        }
    }

    render() {

        const layoutClassName = classNames('layout-wrapper', {
            'layout-horizontal': this.state.layoutMode === 'horizontal',
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-slim': this.state.layoutMode === 'slim',
            'layout-menu-light': this.state.lightMenu === true,
            'layout-menu-dark': this.state.lightMenu === false,
            'layout-overlay-active': this.state.overlayMenuActive,
            'layout-mobile-active': this.state.staticMenuMobileActive,
            'layout-static-inactive': this.state.staticMenuDesktopInactive,
            'layout-rtl': this.state.isRTL
        }, this.state.topbarColor);

        return (
            <div>

                <div className={layoutClassName} onClick={this.onDocumentClick}>
                    <AppTopbar topbarMenuActive={this.state.topbarMenuActive} activeTopbarItem={this.state.activeTopbarItem} inlineUser={this.state.inlineUser}
                        onRightMenuButtonClick={this.onRightMenuButtonClick} onMenuButtonClick={this.onMenuButtonClick}
                        onTopbarMenuButtonClick={this.onTopbarMenuButtonClick} onTopbarItemClick={this.onTopbarItemClick} />

                    <div className='layout-menu-container' onClick={this.onMenuClick}>
                        <div className="menu-scroll-content">
                            <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} onRootMenuItemClick={this.onRootMenuItemClick}
                                layoutMode={this.state.layoutMode} active={this.state.menuActive} />
                        </div>
                    </div>

                    <div className="layout-main">
                        <div className="layout-content">
                            <Messages ref={(el) => this.messages = el} />
                            <Switch>
                                <Route path="/" exact component={Dashboard} />
                                <Route path="/empty" component={EmptyPage} />
                                <Route path="/categories" component={CategoryList} />
                                <Route path="/categoryform/new" component={CategoryForm} />
                                <Route path="/categoryform/edit/:id" component={CategoryForm} />
                                <Route path="/options" component={OptionList} />
                                <Route path="/optionform/new" component={OptionForm} />
                                <Route path="/optionform/edit/:id" component={OptionForm} />
                                <Route path="/toppings" component={ToppingList} />
                                <Route path="/toppingform/new" component={ToppingForm} />
                                <Route path="/toppingform/edit/:id" component={ToppingForm} />
                                <Route path="/dressings" component={DressingList} />
                                <Route path="/dressingform/new" component={DressingForm} />
                                <Route path="/dressingform/edit/:id" component={DressingForm} />
                                <Route path="/products" component={ProductList} />
                                <Route path="/productform/new" component={ProductForm} />
                                <Route path="/productform/edit/:id" component={ProductForm} />
                                <Route path="/tableorders" component={TableOrderList} />
                                <Route path="/basicorderdetails/:id" component={BasicOrderDetails} />
                                <Route path="/orderdetails/:id" component={OrderDetails} />
                                <Route path="/orderlist" component={OrderListPage} />
                                <Route path="/endofdayreport" component={EndOfDayReport} />
                                <Route path="/endofdayreport/:id" component={EndOfDayReport} />
                                <Route path="/zreport/:id" component={ZReport} />
                                <Route path="/zreportlist" component={ZReportList} />
                                <Route path="/menu" component={MenuPage} />
                                <Route path="/deals/new" component={Deals} />
                                <Route path="/deals/edit/:id" component={Deals} />
                                <Route path="/imagemanager" component={ImageManager} />
                                <Route path="/homedelivery" component={HomeDelivery} />
                                <Route>
                                    <Redirect to="/404" />
                                </Route>
                            </Switch>
                        </div>
                    </div>

                    <AppFooter />
                    {this.props.mainStore.redirect &&
                        <Redirect to={this.props.mainStore.redirect} />}
                    <div className="layout-content-mask"></div>
                </div>
            </div>
        );
    }
}

const withMainStore = (Component) => {
    return (props) => {
        const { mainStore } = useStore()
        return <Component mainStore={mainStore} {...props} />;
    };
};

export default withMainStore(App);

