import React, { useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { useStore } from '../stores/useStore'
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import { InputText } from 'primereact/inputtext';

export const ToppingList = observer((props: any) => {
    const { mainStore } = useStore()
    const [loading, setLoading] = useState(true)
    const [errorMsg, setErrorMsg] = useState(null)
    const [data, setData] = useState<any>()
    const [refresh, setRefresh] = useState(true)
    const [globalFilter, setGlobalFilter] = useState(null)

    useEffect(() => {
        if (refresh) {
            setLoading(true)
            mainStore.getToppings()
                .then(([success, data]) => {
                    if (success) {
                        setData(data)
                    } else {
                        setErrorMsg(data)
                    }
                })
                .finally(() => {
                    setLoading(false)
                    setRefresh(false)
                })
        }
    }, [refresh])


    const deleteConfirm = (rowData: any) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: `Are you sure to delete "${rowData.name}"?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        mainStore.deleteTopping({ topping_id: [rowData.topping_id] })
                            .then(([success, data]) => {
                                if (success) {
                                    setRefresh(true)
                                } else {
                                    setErrorMsg(data)
                                }
                            })
                    }
                },
                {
                    label: 'No',
                    onClick: () => void (0)
                }
            ]
        });
    };

    const actionTemplate = (rowData: any) => {
        return <div>
            <Link to={`/toppingform/edit/${rowData.topping_id}`}>Edit</Link>
            <div className='inline-spacer' />
            <a href='#' onClick={() => { deleteConfirm(rowData); return false }}>Delete</a>
        </div>
    }
    const header = (
        <div style={{'textAlign':'left'}}>
            <i className="pi pi-search" style={{margin:'4px 8px 0 0'}}></i>
            <InputText type="search" onInput={(e:any) => setGlobalFilter(e.target.value)} placeholder="Search" />
        </div>
    );
    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="p-grid p-justify-between">
                    <div className="p-col">
                        <div className='page-heading'>Toppings</div>
                    </div>

                    <div className="p-col right" >
                        <Link to="/toppingform/new">
                            <Button label="New Topping" className="p-button-primary" />
                        </Link>
                    </div>
                </div>
            </div>
            <div className="p-col-12">
                <Card>
                    {loading ? <div>Loading...</div> :
                        errorMsg ? <div className='error-msg'>{errorMsg} <Button className='p-button-secondary' label='Cancel' onClick={(e) => setErrorMsg(null)} /> </div> :
                            <div className='p-grid'>
                                <div className="p-col-12">
                                    <DataTable value={data?.toppings} header={header} globalFilter={globalFilter} emptyMessage='No data available' paginatorPosition="bottom" rows={12} paginator={true} responsive={true}>
                                        <Column sortable={true} field="name" header="Name" />
                                        <Column sortable={true} field="swap_option" header="Swap Option" />
                                        <Column sortable={true} field="sort_order" header="Sort Order" style={{ width: '120px' }} />
                                        <Column body={actionTemplate} style={{ width: '120px' }} />
                                    </DataTable>
                                </div>
                            </div>
                    }
                </Card>
            </div>
        </div>
    )
})
