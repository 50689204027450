import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { useEffect, useState } from 'react';
import { FiMinusCircle, FiPlus, FiXCircle } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { ImageManager } from '../components/ImageManager';
import useForceUpdate from '../stores/useForceUpdate';
import { useStore } from '../stores/useStore';
import { CategoryForm } from './CategoryForm';
import { DressingForm } from './DressingForm';
import { OptionForm } from './OptionForm';
import { ToppingForm } from './ToppingForm';

const FormErrorMsg = styled.span`
 color: red;
 display: 'inline-block';
`

const ProductForm = observer((props: any) => {
    const [errorMsg, setErrorMsg] = useState(null)
    const { mainStore } = useStore();
    const history = useHistory();
    const { id }: any = useParams();
    const editMode = id || props?.id ? true : false;
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const [categories, setCategories] = useState([]);
    const [stores, setStores] = useState([]);
    const [toppings, setToppings] = useState([]);
    const [dressings, setDressings] = useState([]);
    const [options, setOptions] = useState([]);
    const [productOptions, setProductOptions] = useState<any>([])
    const forceUpdate = useForceUpdate()
    const [dressingValue, setDressingValue] = useState<any>([])
    const [toppingValue, setToppingValue] = useState<any>([])
    const [categoriesValue, setCategoriesValue] = useState<any>([])
    const [optionValue, setOptionValue] = useState<any>([])
    const [opt, setOpt] = useState(false)
    const [position, setPosition] = useState<any>()
    const [imgRefresh, setImgRefresh] = useState(false)

    useEffect(() => {

        setLoading(!mainStore.stopLoading)

        if (mainStore.isApiCall) {
            // console.log("productform props", props)
            if (editMode) {
                mainStore.getProduct(Number(props.id ? props.id : id))
                    .then(([success, data]) => {
                        if (success) {
                            mainStore.imageURL = data?.thumb ? data?.thumb : '/defaultImage.png';
                            data.product_option = data?.product_options
                            setFormData(data)
                            updateProductOptions(data?.product_option)
                            setCategoriesValue(data?.product_category)
                            setToppingValue(data?.product_toppings)
                            setDressingValue(data?.product_dressings)
                            // setOptionValue(data.product_option)
                            return Promise.all([mainStore.getCategories(), mainStore.getStores(), mainStore.getToppings(), mainStore.getDressings(), mainStore.getOptions()])
                        } else {
                            throw new Error(data)
                        }
                    })
                    .then(values => {
                        if (values[0][0]) {
                            setCategories(values[0][1]?.category)
                        }
                        if (values[1][0]) {
                            setStores(values[1][1])
                        }
                        if (values[2][0]) {
                            setToppings(values[2][1]?.toppings)
                        }
                        if (values[3][0]) {
                            setDressings(values[3][1]?.dressings)
                        }
                        if (values[4][0]) {
                            setOptions(values[4][1]?.options)
                        }
                    })
                    .catch(err => {
                        setErrorMsg(err)
                    })
                    .finally(() => setLoading(false))
            } else {
                if (mainStore.menuAddProduct) {
                    mainStore.imageURL = '/defaultImage.png';
                    setFormData({ product_id: null, product_description: { 1: { name: null } }, price: null, status: '1', product_option: [], product_store: [props.storeId] })
                    setCategoriesValue([props?.parentId])
                } else {
                    mainStore.imageURL = '/defaultImage.png';
                    setFormData({ product_id: null, product_description: { 1: { name: null } }, price: null, status: '0', product_option: [] })
                }
                Promise.all([mainStore.getCategories(), mainStore.getStores(), mainStore.getToppings(), mainStore.getDressings(), mainStore.getOptions()])
                    .then(values => {
                        if (values[0][0]) {
                            setCategories(values[0][1]?.category)
                        }
                        if (values[1][0]) {
                            setStores(values[1][1])
                        }
                        if (values[2][0]) {
                            setToppings(values[2][1]?.toppings)
                        }
                        if (values[3][0]) {
                            setDressings(values[3][1]?.dressings)
                        }
                        if (values[4][0]) {
                            setOptions(values[4][1]?.options)
                        }
                    })
                    .catch(err => {
                        setErrorMsg(err)
                    })
                    .finally(() => setLoading(false))
            }
        }

        if (mainStore.isOption) {
            mainStore.getOptions()
                .then(([success, data]) => {
                    if (success) {
                        setOpt(true)
                        data?.options?.map((item: any) => {
                            if (item.option_id == mainStore.optionValue.toString()) {
                                setOptionValue(item)
                            }
                        });
                        setOptions(data?.options)
                        mainStore.isOption = false
                        setOpt(false)
                    } else {
                        setErrorMsg(data)
                    }
                })
        }

        if (mainStore.isDressing) {
            mainStore.getDressings()
                .then(([success, data]) => {
                    if (success) {
                        dressingValue.push(mainStore.dressingValue.toString())
                        setDressings(data?.dressings)
                        mainStore.dressingValue = ''
                    } else {
                        setErrorMsg(data)
                    }
                })
        }

        if (mainStore.isTopping) {
            mainStore.getToppings()
                .then(([success, data]) => {
                    if (success) {
                        toppingValue.push(mainStore.toppingValue.toString())
                        setToppings(data?.toppings)
                        mainStore.toppingValue = ''
                    } else {
                        setErrorMsg(data)
                    }
                })
        }

        if (mainStore.isCategories) {
            mainStore.getCategories()
                .then(([success, data]) => {
                    if (success) {
                        categoriesValue.push(mainStore.categoryValue.toString())
                        setCategories(data?.category)
                        mainStore.categoryValue = ''
                    } else {
                        setErrorMsg(data)
                    }
                })
        }


    }, [mainStore.isApiCall, mainStore.dressing, mainStore.topping, mainStore.option, mainStore.stopLoading, mainStore.categories,])

    useEffect(() => {
        mainStore.imageURL = '/defaultImage.png'
        mainStore.imagePath = '';
        setImgRefresh(false)

    }, [imgRefresh])

    function parentOptions(index: number) {
        let options = [{ label: 'No', value: '0' }]
        for (let i = 0; i < index; i++) {
            options.push({ label: productOptions[i]?.name, value: productOptions[i]?.option_id })
        }
        return options
    }

    function parentOptionValues(id: number) {
        let options = [{ label: 'No', value: '0' }]
        let found = productOptions.find((elem: any) => elem.option_id == id)
        if (found) {
            found.values.forEach((element: any) => {
                options.push({ label: element.name, value: element.option_value_id })
            })
        }
        return options
    }

    function updateProductOptions(optionList: any) {
        let optionIds = optionList.map((item: any) => item.option_id)
        return mainStore.getProductOption(optionIds)
            .then(data => {
                setProductOptions(data)
                return data
            })
    }

    function addOption(formik: any, value: any) {
        setOpt(false)
        if (value.option_id) {
            formik.values.product_option.push({
                product_option_id: null, option_id: value.option_id,
                name: value.name, type: value.type,
                product_option_value: [],
                master_option: '0',
                required: "0",
                default: "0",
                master_option_value: "0",
                minimum: "0",
                maximum: "0",
                sort_order: "0"
            })
            updateProductOptions(formik.values.product_option)
                .then(res => {
                    setOptionValue([])
                    let newOption = formik.values.product_option.slice(-1)[0]
                    let productOption = res.slice(-1)[0]
                    productOption?.values.forEach((elem: any) => {
                        newOption.product_option_value.push({ option_value_id: elem.option_value_id, name: elem.name })
                    })
                }).finally(() => forceUpdate())

        }
    }

    const onHide = () => {
        mainStore.visible = false
        if (mainStore.dressing == true) {
            mainStore.dressing = false
        } else if (mainStore.topping == true) {
            mainStore.topping = false
        } else if (mainStore.option == true) {
            mainStore.option = false
        }
    }

    const handleCancel = () => {
        if (mainStore.menuDialogBox == true) {
            mainStore.menuDialogBox = false
            mainStore.menuProduct = false
        } else if (mainStore.menuAddDialogBox == true) {
            mainStore.menuAddDialogBox = false
            mainStore.menuAddProduct = false
        }
        else {
            history.goBack()
        }
    }

    return (
        <div className="p-grid product-form">
            <div className="p-col-12">
                <div className="p-grid p-justify-between">
                    <div className="p-col">
                        <div className='page-heading'>
                            {mainStore?.menuDialogBox == false && editMode ? <i className="pi pi-arrow-left" style={{ 'fontSize': '1em' }} onClick={() => history.goBack()}></i> : ""}
                            {mainStore?.menuDialogBox == false && editMode ? < div className='inline-spacer' /> : ""}
                            {editMode ? 'View' : 'New'} Product
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-col-12">
                <Formik
                    initialValues={formData}
                    enableReinitialize={true}
                    validationSchema={yup.object({
                        product_description: yup.object({ 1: yup.object({ name: yup.string().required('Required').nullable() }) }),
                        price: yup.number().required('Required').nullable()
                    })}
                    onSubmit={(values: any, { setSubmitting }) => {
                        if (mainStore.imagePath) {
                            values.image = "data/" + mainStore?.imagePath
                        } else {
                            values.image = ''
                        }
                        values.product_dressings = dressingValue
                        values.product_toppings = toppingValue
                        values.product_category = categoriesValue

                        // values['keyword'] = values['keyword'] ? values['keyword'] : '' //api error fix
                        // values['product_option'] = values['product_option'] 
                        mainStore.updateProduct(values)
                            .then(([success, data]) => {
                                if (success) {
                                    if (mainStore.menuDialogBox == true) {
                                        mainStore.menuDialogBox = false;
                                        mainStore.menuProduct = false;
                                        mainStore.refresh = true;
                                        mainStore.isApiCall = true;
                                    } else if (mainStore.menuAddDialogBox == true) {
                                        mainStore.menuAddDialogBox = false;
                                        mainStore.menuAddProduct = false;
                                        mainStore.refresh = true;
                                        mainStore.isApiCall = true;
                                    }
                                    else {
                                        mainStore.isApiCall = true
                                        history.push('/products')
                                    }
                                } else {
                                    setErrorMsg(data.error)
                                }
                            })
                    }}
                >
                    {formik =>
                        <Card>
                            <Form>
                                {loading ? <div>Loading...</div> :
                                    errorMsg ? <div className='error-msg'>{errorMsg}</div> :
                                        <>
                                            {(optionValue.length != 0 && opt == true) && addOption(formik, optionValue)}
                                            <div className='p-grid'>
                                                <div className='p-col-12 p-md-4 p-fluid'>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Name</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='product_description.1.name' as={InputText} />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='product_description.1.name' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='p-col-12 p-md-2 p-fluid'>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Short Code</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='product_description.1.shortcode' as={InputText} />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='product_description.1.shortCode' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='p-col-12 p-md-2 p-fluid'>
                                                    <div className='p-grid  form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Product Code</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='model' as={InputText} />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='model' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='p-col-12 p-md-2 p-fluid'>
                                                    <div className='p-grid  form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Product ID for API</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='ec_product_id' as={InputText} />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='ec_product_id' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className='p-col-12 p-md-1 p-fluid'>
                                                    <div className='p-grid  form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Price</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='price' as={InputText} className='right' />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='price' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='p-col-12 p-md-1 p-fluid'>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Enabled</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='status' type='checkbox' as={Checkbox} value={formik.values['status'] ? '1' : '0'} checked={formik.values['status'] == '1' ? true : false} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='p-grid p-fluid'>
                                                <div className='p-col-12 p-md-6'>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Categories</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <div className="p-inputgroup">
                                                                <Field name='product_category' as={MultiSelect} value={categoriesValue} optionLabel='name' optionValue='category_id' options={categories} filter={true} onChange={(e: any) => setCategoriesValue(e.value)} />
                                                                <Button icon="pi pi-plus" type='button' className="p-button-success" onClick={() => { mainStore.isApiCall = false; mainStore.isTopping = false; mainStore.isDressing = false; mainStore.isCategories = false; mainStore.visible = true; mainStore.categories = true; mainStore.stopLoading = true }} />
                                                            </div>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='product_category' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='p-col-12 p-md-6'>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Stores</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='product_store' as={MultiSelect} optionLabel='name' optionValue='store_id' options={stores} />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='product_store' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='p-grid p-fluid'>
                                                <div className='p-col-12 p-md-6'>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Toppings</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <div className="p-inputgroup">
                                                                <Field name='product_toppings' as={MultiSelect} value={toppingValue} optionLabel='name' optionValue='topping_id' options={toppings} filter={true} onChange={(e: any) => setToppingValue(e.value)} />
                                                                <Button icon="pi pi-plus" type='button' className="p-button-success" onClick={() => { mainStore.isApiCall = false; mainStore.isTopping = false; mainStore.isDressing = false; mainStore.isCategories = false; mainStore.visible = true; mainStore.topping = true; mainStore.stopLoading = true }} />
                                                            </div>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='product_toppings' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='p-col-12 p-md-6'>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Dressings</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <div className="p-inputgroup">
                                                                <Field name='product_dressings' as={MultiSelect} value={dressingValue} optionValue='dressing_id' optionLabel='name' options={dressings} filter={true} onChange={(e: any) => setDressingValue(e.value)} />
                                                                <Button icon="pi pi-plus" type='button' className="p-button-success" onClick={() => { mainStore.isApiCall = false; mainStore.isTopping = false; mainStore.isDressing = false; mainStore.isCategories = false; mainStore.visible = true; mainStore.dressing = true; mainStore.stopLoading = true; }} />
                                                            </div>

                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='product_dressings' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='p-grid p-fluid'>
                                                <div className='p-col-12 p-md-6'>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Options</label>
                                                        </div>

                                                        <div className='p-col-11'>
                                                            <div className="p-inputgroup ">
                                                                <Field as={Dropdown} optionLabel='name' filter={true} filterBy='name,optionfor'
                                                                    filterPlaceholder='Search'
                                                                    itemTemplate={(option: any) => <span key={option.option_id}>{option.name} <span key={option.option_id} style={{ color: '#999' }}>{option.optionfor ? `for ${option.optionfor}` : ''}</span></span>}
                                                                    options={options} placeholder="Select from list to add a new option"
                                                                    onChange={(e: any) => { addOption(formik, e.value) }}
                                                                />
                                                                < Button icon="pi pi-plus" type='button' className="p-button-success p-col-2" onClick={() => { mainStore.isApiCall = false; mainStore.visible = true; mainStore.option = true; mainStore.stopLoading = true; mainStore.isTopping = false; mainStore.isDressing = false; mainStore.isCategories = false; mainStore.optionValue = '' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                {formik.values.product_option?.length != 0 &&
                                                    <div className='options-tab-group'>
                                                        <TabView >
                                                            {formik.values.product_option?.map((item: any, index: number) => {
                                                                return (
                                                                    <TabPanel header={<div className='tab-name'><span>{item.name}</span><div className='tab-button' onClick={(e) => { formik.values.product_option.splice(index, 1); updateProductOptions(formik.values.product_option) }}><FiXCircle /></div></div>} contentStyle={{ position: 'relative' }}>
                                                                        <div className='p-grid'>
                                                                            <div className='p-col-12'>
                                                                                <div className='p-grid p-fluid'>
                                                                                    <div className='p-col-12 p-md-2'>
                                                                                        <div className='p-grid form-control-group'>
                                                                                            <div className='p-col-12'>
                                                                                                <label>Required</label>
                                                                                            </div>
                                                                                            <div className='p-col-12'>
                                                                                                <Field name={`product_option.${index}.required`} type='checkbox'
                                                                                                    value={item.required ? '1' : '0'} checked={item.required == '1' ? true : false}
                                                                                                    as={Checkbox} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='p-col-12 p-md-2'>
                                                                                        <div className='p-grid form-control-group'>
                                                                                            <div className='p-col-12'>
                                                                                                <label>Min. Qty</label>
                                                                                            </div>
                                                                                            <div className='p-col-12'>
                                                                                                <Field name={`product_option.${index}.minimum`} as={InputText} />
                                                                                            </div>
                                                                                            <div className='p-col-12'>
                                                                                                <ErrorMessage name={`product_option.${index}.minimum`} component={FormErrorMsg} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='p-col-12 p-md-2'>
                                                                                        <div className='p-grid form-control-group'>
                                                                                            <div className='p-col-12'>
                                                                                                <label>Max. Qty</label>
                                                                                            </div>
                                                                                            <div className='p-col-12'>
                                                                                                <Field name={`product_option.${index}.maximum`} as={InputText} />
                                                                                            </div>
                                                                                            <div className='p-col-12'>
                                                                                                <ErrorMessage name={`product_option.${index}.maximum`} component={FormErrorMsg} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='p-col-12 p-md-2'>
                                                                                        <div className='p-grid form-control-group'>
                                                                                            <div className='p-col-12'>
                                                                                                <label>Sort Order</label>
                                                                                            </div>
                                                                                            <div className='p-col-12'>
                                                                                                <Field name={`product_option.${index}.sort_order`} as={InputText} />
                                                                                            </div>
                                                                                            <div className='p-col-12'>
                                                                                                <ErrorMessage name={`product_option.${index}.sort_order`} component={FormErrorMsg} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='p-col-12 p-md-3'>
                                                                                        <div className='p-grid form-control-group'>
                                                                                            <div className='p-col-12'>
                                                                                                <label>Parent Option</label>
                                                                                            </div>
                                                                                            <div className='p-col-12'>
                                                                                                <Field name={`product_option.${index}.master_option`} as={Dropdown} options={parentOptions(index)} />
                                                                                            </div>
                                                                                            <div className='p-col-12'>
                                                                                                <ErrorMessage name={`product_option.${index}.master_option`} component={FormErrorMsg} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='p-col-12 p-md-1'>
                                                                                        <div className='p-grid form-control-group'>
                                                                                            <div className='p-col-12'>
                                                                                                <label>Default</label>
                                                                                            </div>
                                                                                            <div className='p-col-12'>
                                                                                                <Field name={`product_option.${index}.default`} type='checkbox'
                                                                                                    value={item.default ? '1' : '0'} checked={item.default == '1' ? true : false}
                                                                                                    as={Checkbox} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className='p-grid dynamic-form'>
                                                                            <div className='p-col-12 table-head'>
                                                                                <div className='p-grid p-fluid'>
                                                                                    <div className='p-col-12 p-md-3'>
                                                                                        <label>Option Value</label>
                                                                                    </div>
                                                                                    <div className='p-col-12 p-md-2'>
                                                                                        <label>Price</label>
                                                                                    </div>
                                                                                    <div className='p-col-12 p-md-3'>
                                                                                        <label>Parent Option</label>
                                                                                    </div>
                                                                                    <div className='p-col-12 p-md-2'>
                                                                                        <label>Product ID</label>
                                                                                    </div>
                                                                                    <div className='p-col-12 p-md-2'>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <FieldArray name={`product_option.${index}.product_option_value`}>
                                                                                {arrayHelpers => <>
                                                                                    {item.product_option_value.length != 0 && item.product_option_value?.map((valueItem: any, valueIndex: number) => (
                                                                                        <div key={`${index}-${valueIndex}`} className='p-col-12 table-body'>
                                                                                            <div className='p-grid p-fluid'>
                                                                                                <div className='p-col-12 p-md-3'>
                                                                                                    <div className='p-grid form-control-group'>
                                                                                                        <div className='p-col-12 responsive-label'>
                                                                                                            <label>Option Value</label>
                                                                                                        </div>
                                                                                                        <div className='p-col-12'>
                                                                                                            <Field name={`product_option.${index}.product_option_value.${valueIndex}.option_value_id`}
                                                                                                                as={Dropdown} optionLabel='name' optionValue='option_value_id' options={productOptions[index]?.values} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='p-col-12 p-md-2'>
                                                                                                    <div className='p-grid form-control-group'>
                                                                                                        <div className='p-col-12  responsive-label'>
                                                                                                            <label>Price</label>
                                                                                                        </div>
                                                                                                        <div className='p-col-12'>
                                                                                                            <Field name={`product_option.${index}.product_option_value.${valueIndex}.price`} as={InputText} className='right' />
                                                                                                        </div>
                                                                                                        <div className='p-col-12'>
                                                                                                            <ErrorMessage name={`product_option.${index}.product_option_value.${valueIndex}.price`} component={FormErrorMsg} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='p-col-12 p-md-3'>
                                                                                                    <div className='p-grid form-control-group'>
                                                                                                        <div className='p-col-12  responsive-label'>
                                                                                                            <label>Parent Option</label>
                                                                                                        </div>
                                                                                                        <div className='p-col-12'>
                                                                                                            <Field name={`product_option.${index}.product_option_value.${valueIndex}.master_option_value`}
                                                                                                                as={Dropdown} options={parentOptionValues(formik.values['product_option'][index].master_option)} />
                                                                                                        </div>
                                                                                                        <div className='p-col-12'>
                                                                                                            <ErrorMessage name={`product_option.${index}.product_option_value.${valueIndex}.master_option_value`} component={FormErrorMsg} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='p-col-12 p-md-2'>
                                                                                                    <div className='p-grid form-control-group'>
                                                                                                        <div className='p-col-12  responsive-label'>
                                                                                                            <label>Product ID</label>
                                                                                                        </div>
                                                                                                        <div className='p-col-12'>
                                                                                                            <Field name={`product_option.${index}.product_option_value.${valueIndex}.ec_product_id`} as={InputText} />
                                                                                                        </div>
                                                                                                        <div className='p-col-12'>
                                                                                                            <ErrorMessage name={`product_option.${index}.product_option_value.${valueIndex}.ec_product_id`} component={FormErrorMsg} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='p-col-12 p-md-2 p-col-align-center'>
                                                                                                    <div className='p-grid  form-control-group'>
                                                                                                        <div className='p-col-12 icon-container'>
                                                                                                            <div onClick={(e) => arrayHelpers.remove(valueIndex)}>
                                                                                                                <FiXCircle />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='p-col-12' style={{ height: '1.0em' }}>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                    <div className='p-col-12'>
                                                                                        <div className='icon-container'>
                                                                                            <div>
                                                                                                New Option Value
                                                                                    </div>
                                                                                            <div onClick={(e) => arrayHelpers.push({ product_option_value_id: null })}>
                                                                                                <FiPlus />
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                }
                                                                            </FieldArray>
                                                                        </div>

                                                                    </TabPanel>
                                                                )
                                                            })}
                                                        </TabView>
                                                    </div>
                                                }
                                                <div className='p-col-12 p-md-3'>
                                                    <div className="p-grid p-fluid ">
                                                        <div className="p-col-12 " style={{ position: 'relative' }}>
                                                            <FiMinusCircle size='20px' fontWeight='bold' style={{ position: 'absolute', right: '5px' }} onClick={() => setImgRefresh(true)} />
                                                            <img src={mainStore.imageURL} alt="" style={{ width: '100%', height: '120px', objectFit: 'cover', objectPosition: '50% 50%' }} onClick={() => mainStore.fileManagerDialogue = true} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className='p-grid '>
                                                <div className="p-col form-action-container ">
                                                    <Button type='submit' disabled={formik.isSubmitting} label={editMode ? 'Update' : 'Save'} className='p-button-primary' />
                                                    <Button type='button' label="Cancel" onClick={() => { handleCancel(); mainStore.isApiCall = true }} className='p-button-secondary' />
                                                </div>
                                            </div>

                                        </>
                                }
                            </Form>

                        </Card>
                    }

                </Formik>
            </div>
            <Dialog visible={mainStore.visible} style={{ width: '90vw' }} onHide={() => onHide()} showHeader={false}>
                {
                    mainStore.dressing && <DressingForm /> || mainStore.topping && <ToppingForm /> || mainStore.option && <OptionForm /> || mainStore.categories && <CategoryForm />
                }
            </Dialog>

            <Dialog header="Image Manager" visible={mainStore.fileManagerDialogue} style={{ width: '90vw' }} onHide={() => mainStore.fileManagerDialogue = false} >
                <ImageManager />
            </Dialog>
        </div >
    );
})

export { ProductForm };

