import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useStore } from './stores/useStore';
import { useHistory } from 'react-router';
import { observer } from "mobx-react-lite";


export const AppTopbar = observer((props) => {
    const { userSessionStore } = useStore();
    const history = useHistory();
    const [site, setSite] = useState();

    function onTopbarItemClick(event, item) {
        if (props.onTopbarItemClick) {
            props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    }

    useEffect(() => {
        let config = userSessionStore.getSiteConfig()
        if (config) {
            setSite(config.sitename)
        }

    }, [])


    return <div className="layout-topbar">
        <div style={{ padding: '12px 20px' }}>
            {/* <button className="p-link layout-right-panel-button layout-topbar-icon" onClick={ props.onRightMenuButtonClick}>
                <i className="pi pi-ellipsis-v"></i>
            </button> */}

            <button className="p-link layout-menu-button layout-topbar-icon" onClick={props.onMenuButtonClick}>
                <i className="pi pi-bars"></i>
            </button>

            <button className="p-link layout-topbar-logo" onClick={() => window.location = "/"}>
                <img id="topbar-logo" src="/assets/layout/images/logo-white.svg" alt="" />
            </button>

            <span className='site-name'> {site} </span>


            <ul className="topbar-menu">
                <li className={classNames('user-profile', { 'active-topmenuitem fadeInDown': props.activeTopbarItem === 'profile' })}>
                    {!props.inlineUser && <button className="p-link" onClick={(e) => onTopbarItemClick(e, 'profile')}>
                        {/* <img src="assets/layout/images/avatar.png" alt=""/> */}
                        <div className="layout-profile-userinfo">
                            <span className="layout-profile-name">{userSessionStore.user?.firstname + ' ' + userSessionStore.user?.lastname}</span>
                            <span className="layout-profile-role"></span>

                        </div>
                        <div className='profile-menu-button'>
                            <i className="pi pi-caret-down"></i>
                        </div>
                    </button>}

                    <ul className="fadeInDown">
                        {/* <li role="menuitem">
                            <button className="p-link">
                                <i className="pi pi-fw pi-user"></i>
                                <span>Profile</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button className="p-link">
                                <i className="pi pi-fw pi-cog"></i>
                                <span>Settings</span>
                            </button>
                        </li> */}
                        <li role="menuitem">
                            <button className="p-link" onClick={(e) => { userSessionStore.logout(); history.replace('/login') }}>
                                <i className="pi pi-fw pi-lock"></i>
                                <span>Log out</span>
                            </button>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>;
})

AppTopbar.defaultProps = {
    onMenuButtonClick: null,
    onTopbarMenuButtonClick: null,
    onTopbarItemClick: null,
    onRightMenuButtonClick: null,
    topbarMenuActive: false,
    activeTopbarItem: null,
    inlineUser: null,
    onThemeChange: null
}

AppTopbar.propTypes = {
    onMenuButtonClick: PropTypes.func.isRequired,
    onTopbarMenuButtonClick: PropTypes.func.isRequired,
    onTopbarItemClick: PropTypes.func.isRequired,
    onRightMenuButtonClick: PropTypes.func.isRequired,
    topbarMenuActive: PropTypes.bool.isRequired,
    activeTopbarItem: PropTypes.string,
    inlineUser: PropTypes.bool,
    onThemeChange: PropTypes.func
}

