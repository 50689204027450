import React, { useState, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useStore } from './stores/useStore'

const ProtectedRoute = (props: any) => {
    const { userSessionStore } = useStore();
    const [loading, setLoading] = useState(false)
    const [component, setComponent] = useState(props.children)

    useEffect(()=>{
        if (!userSessionStore.isAuthenticated) {
            setLoading(true)
            userSessionStore.authenticateUsingToken()
                .then(success => {
                    if (!success) {
                        setComponent(<Redirect to={{ pathname: "/login", state: { from: props.location } }} />)
                    }
                    setLoading(false)
                })
        }
    },[])
    
    return (loading ? <div style={{padding: '30px'}}> Loading... </div> : component)
}

export default ProtectedRoute