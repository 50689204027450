import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import styled from 'styled-components'
import { observer } from "mobx-react-lite";
import { useStore } from '../stores/useStore'
import { useHistory, Link } from 'react-router-dom'
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as yup from 'yup'

const FormErrorMsg = styled.span`
 color: red;
`

export const Login = observer((props: any) => {
    const [errorMsg, setErrorMsg] = useState(null)
    const { userSessionStore } = useStore();
    const history = useHistory()
    const [siteConfig, setSiteConfig] = useState<any>(null)
    const [siteTestResult, setSiteTestResult] = useState<any>(null)

    useEffect(() => {
        setSiteConfig(userSessionStore.getSiteConfig())
    }, [])

    return (
        <div className="login-body">
            {!siteConfig && <Formik
                initialValues={{ secKey: '' }}
                validationSchema={yup.object({
                    secKey: yup.string()
                        .required('Required')
                })}
                onSubmit={(values) => {
                    if (siteTestResult && siteTestResult[0]) {
                        setSiteConfig(siteTestResult[1]);
                        userSessionStore.setSiteConfig(siteTestResult[1])
                    } else {
                        userSessionStore.testSiteConfig(values.secKey)
                            .then(res => {
                                setSiteTestResult(res)
                            })
                    }
                }}>
                {formik =>
                    <Form>
                        <div className="card login-panel">
                            <div className="login-panel-content">
                                <div className="p-grid">
                                    <div className="p-col-12 logo-container">
                                        <img src="/assets/layout/images/logo.svg" alt="ETM" />
                                        <span className="guest-sign-in">Configure Application</span>
                                    </div>

                                    <div className="p-col-12 error-msg">
                                        <div>{errorMsg}</div>
                                    </div>

                                    <div className="p-col-12">
                                        <div className='p-grid form-control-group p-fluid'>
                                            <div className='p-col-12'>
                                                <label>Security Key</label>
                                            </div>
                                            <div className='p-col-12'>
                                                <Field name='secKey' as={InputText} onChange={(e: any) => { formik.handleChange(e); setSiteTestResult(null) }} />
                                            </div>
                                            <div className='p-col-12'>
                                                <ErrorMessage name='secKey' component={FormErrorMsg} />
                                            </div>
                                            <div className='p-col-12' style={{ color: 'grey' }}>
                                                <label>Get your security key from technical support</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div style={{ height: '30px', fontWeight: 'bold' }}>
                                            {siteTestResult && <span style={{ color: siteTestResult[0] ? 'green' : 'red' }}>{siteTestResult[0] ? 'Security Key is valid' : siteTestResult[1]}</span>}
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className='p-grid'>
                                            {siteTestResult && siteTestResult[0] ? <div className='p-col'>
                                                <Button type='submit' label="Proceed" className='p-button-success' icon="pi pi-cog" />
                                            </div> :
                                                <div className='p-col'>
                                                    <Button type='submit' className='p-button-primary' label="Validate" icon="pi pi-check" />
                                                </div>}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Form>
                }
            </Formik>}

            {siteConfig && <Formik
                initialValues={{ username: '', password: '' }}
                validationSchema={yup.object({
                    username: yup.string()
                        .max(30, 'Must be 30 characters or less')
                        .required('Required'),
                    password: yup.string()
                        .max(30, 'Must be 30 characters or less')
                        .required('Required')

                })}
                onSubmit={(values, { setSubmitting }) => {
                    userSessionStore.authenticate(values.username, values.password)
                        .then(([success, message]) => {
                            if (success) {
                                setErrorMsg(null)
                                history.push('/')
                            } else {
                                setErrorMsg(message)
                                setSubmitting(false)
                            }
                        })
                }}>
                {formik =>
                    <Form>
                        <div className="card login-panel">
                            <div className="login-panel-content">
                                <div className="p-grid">
                                    <div className="p-col-12 logo-container">
                                        <img src="/assets/layout/images/logo.svg" alt="ETM" />
                                    </div>
                                    <div className="p-col-12">
                                        <div className='site-name'> {siteConfig.sitename} </div>
                                        <div className="guest-sign-in">Welcome, please use the form to sign-in</div>
                                    </div>

                                    <div className="p-col-12 error-msg">
                                        <div>{errorMsg}</div>
                                    </div>

                                    <div className="p-col-12">
                                        <div className='p-grid form-control-group p-fluid'>
                                            <div className='p-col-12'>
                                                <label>Username</label>
                                            </div>
                                            <div className='p-col-12'>
                                                <Field name='username' as={InputText} />
                                            </div>
                                            <div className='p-col-12'>
                                                <ErrorMessage name='username' component={FormErrorMsg} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className='p-grid form-control-group p-fluid'>
                                            <div className='p-col-12'>
                                                <label>Password</label>
                                            </div>
                                            <div className='p-col-12'>
                                                <Field type='password' name='password' as={InputText} />
                                            </div>
                                            <div className='p-col-12'>
                                                <ErrorMessage name='password' component={FormErrorMsg} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className='p-grid form-action-container'>
                                            <div className='p-col '>
                                                <Button type='submit' disabled={formik.isSubmitting} label="Sign In" icon="pi pi-user" />
                                            </div>
                                            {siteConfig &&
                                                <div className='p-col right'>
                                                    <Button className="p-button-rounded p-button-secondary" tooltip='Reset Site Configuration'
                                                        icon='pi pi-undo'
                                                        onClick={(e) => {
                                                            userSessionStore.removeSiteConfig()
                                                            setSiteConfig(null)
                                                            setSiteTestResult(null)
                                                        }} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                }
            </Formik>
            }
        </div>
    );
})