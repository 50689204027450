import { action, computed, observable, values } from 'mobx';
import moment from 'moment';
import apiClient from './apiClient';
import qs from 'qs';

const form_url_encoded = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

export class MainStore {
    @observable
    imageURL: any;

    @observable
    imagePath: any;

    @observable
    fileManagerDialogue: boolean = false




    //---------------------- Menu Component flag----------------

    @observable
    refresh: boolean = false

    @observable
    menuDialogBox: boolean = false

    @observable
    menuCategory: boolean = false

    @observable
    menuProduct: boolean = false

    @observable
    menuDeal: boolean = false

    @observable
    menuAddDialogBox: boolean = false

    @observable
    menuAddCategory: boolean = false

    @observable
    menuAddProduct: boolean = false

    //--------------------- Product form Component flag --------------

    @observable
    visible: boolean = false

    @observable
    dressing: boolean = false

    @observable
    topping: boolean = false

    @observable
    option: boolean = false

    @observable
    categories: boolean = false

    @observable
    stopLoading: boolean = false

    @observable
    isDressing: boolean = false

    @observable
    isTopping: boolean = false

    @observable
    isOption: boolean = false

    @observable
    isCategories: boolean = false

    @observable
    isApiCall: boolean = true

    @observable
    dressingValue: any = []

    @observable
    categoryValue: any = []

    @observable
    toppingValue: any = []

    @observable
    optionValue: any = []

    // -----------------------------------------------------------------------------


    @observable
    newOrder: boolean = false

    @observable
    newTableOrder: boolean = false

    @observable
    isOffline: boolean = false

    @observable
    orderlistData: any = []

    @observable
    allStatus: any = []

    @observable
    status: any;

    @observable
    redirect: any


    // -------------------------------------------------------------------------------------------------------------------------
    // Home Delivery ---------
    @action
    getDeliverySettings(id: number) {
        return apiClient.post('/admnapi/jsonp.php?method=getdeliverysettings', id >= 0 ? { store_id: id } : '')
            .then(action((res: any) => {
                if (res?.data) {
                    return [true, res?.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    updateDeliverySettings(data: any) {
        return apiClient.post('/admnapi/jsonp.php?method=updatedeliverysettings', data)
            .then(action((res: any) => {
                if (res?.data) {
                    return [true, res?.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    // File manager functions-------------

    @action
    getFiles(dir: any) {
        return apiClient.post('/admnapi/jsonp.php?method=files', { directory: dir })
            .then(action((res: any) => {
                // console.log("files", res)
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    getAllFolders() {
        return apiClient.post('/admnapi/jsonp.php?method=folders')
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    create(dirName: any, newDirName: any) {
        return apiClient.post('/admnapi/jsonp.php?method=create', { directory: dirName, name: newDirName })
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    rename(name: any, path: any) {
        return apiClient.post('/admnapi/jsonp.php?method=rename', { name: name, path: path })
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    delete(data: any) {
        return apiClient.post('/admnapi/jsonp.php?method=delete', { path: data })
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    move(from: any, to: any) {
        return apiClient.post('/admnapi/jsonp.php?method=move', { from: from, to: to })
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    copy(curPath: any, newName: any) {
        return apiClient.post('/admnapi/jsonp.php?method=copy', { path: curPath, name: newName })
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    upload(data: any) {
        return apiClient.post('/admnapi/jsonp.php?method=upload', data)
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    //Menus api

    @action
    getMenus(catg_Id: any, str_Id: any) {
        return apiClient.post('/admnapi/jsonp.php?method=getMenus', { parent_id: catg_Id, store_id: str_Id })
            .then(action((res: any) => {
                // console.log("menus", res)
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    //category 

    @action
    getCategories() {
        return apiClient.get('/admnapi/jsonp.php?method=getCategories')
            .then(action((res: any) => {
                // console.log(res)
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }


    @action
    getCategory(id: number) {
        return apiClient.get('/admnapi/jsonp.php?method=getCategory', { params: { category_id: id } })
            .then(action((res: any) => {
                // console.log(res)
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    updateCategory(data: any) {
        return apiClient.post('/admnapi/jsonp.php?method=addCategory', data)
            .then(action((res: any) => {
                // console.log(res)
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    deleteCategory(data: any) {
        return apiClient.post('/admnapi/jsonp.php?method=deleteCategory', data)
            .then(action((res: any) => {
                // console.log(res)
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    // Option

    @action
    getOptions() {
        return apiClient.get('/admnapi/jsonp.php?method=getOptions')
            .then(action((res: any) => {
                // console.log(res)
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    getOption(id: number) {
        return apiClient.get('/admnapi/jsonp.php?method=getOption', { params: { option_id: id } })
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    updateOption(data: any) {
        return apiClient.post('/admnapi/jsonp.php?method=updateOption', data)
            .then(action((res: any) => {
                // console.log(res)
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    deleteOption(data: any) {
        return apiClient.post('/admnapi/jsonp.php?method=deleteOption', data)
            .then(action((res: any) => {
                // console.log(res)
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }


    // Store

    @action
    getStores() {
        return apiClient.get('/admnapi/jsonp.php?method=getStores')
            .then(action((res: any) => {
                if (!res.data.error) {
                    res.data.forEach((element: any) => {
                        element.store_id = element.store_id.toString()
                    });
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    // Topping

    @action
    getToppings() {
        return apiClient.get('/admnapi/jsonp.php?method=getToppings')
            .then(action((res: any) => {
                // console.log(res)
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    getTopping(id: number) {
        return apiClient.get('/admnapi/jsonp.php?method=getTopping', { params: { topping_id: id } })
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    updateTopping(data: any) {
        return apiClient.post('/admnapi/jsonp.php?method=updateTopping', data)
            .then(action((res: any) => {
                // console.log(res)
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    deleteTopping(data: any) {
        return apiClient.post('/admnapi/jsonp.php?method=deleteTopping', data)
            .then(action((res: any) => {
                // console.log(res)
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    // Dressing

    @action
    getDressings() {
        return apiClient.get('/admnapi/jsonp.php?method=getDressings')
            .then(action((res: any) => {
                // console.log(res)
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    getDressing(id: number) {
        return apiClient.get('/admnapi/jsonp.php?method=getDressing', { params: { dressing_id: id } })
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    updateDressing(data: any) {
        return apiClient.post('/admnapi/jsonp.php?method=updateDressing', data)
            .then(action((res: any) => {
                // console.log(res)
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }


    @action
    deleteDressing(data: any) {
        return apiClient.post('/admnapi/jsonp.php?method=deleteDressing', data)
            .then(action((res: any) => {
                // console.log(res)
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    // Product

    @action
    getProducts() {
        return apiClient.get('/admnapi/jsonp.php?method=getProducts')
            .then(action((res: any) => {
                // console.log(res)
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    getProduct(id: number) {
        return apiClient.get('/admnapi/jsonp.php?method=getProduct', { params: { product_id: id } })
            .then(action((res: any) => {
                // console.log(res)
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    getProductOption(ids: number[]) {
        let options: any = []
        let promises = ids.map(id => this.getOption(id))
        return Promise.all(promises)
            .then(values => {
                values.forEach(([success, data]) => {
                    let option: any = {}
                    option['option_id'] = data.options.option_id
                    option['name'] = data.options.name
                    option['values'] = data.option_values.map((item: any) => {
                        return { option_value_id: item.option_value_id, name: item.option_value_description[1].name }
                    })
                    options.push(option)
                })
                return options
            })
    }

    @action
    updateProduct(data: any) {
        return apiClient.post('/admnapi/jsonp.php?method=updateProduct', data)
            .then(action((res: any) => {
                // console.log(res)
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    deleteProduct(data: any) {
        return apiClient.post('/admnapi/jsonp.php?method=deleteProduct', data)
            .then(action((res: any) => {
                // console.log(res)
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    quickProductUpdate(data: any) {
        return apiClient.post('/admnapi/jsonp.php?method=quickProductedit', data)
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    // Dashboard

    @action
    getDashBoardData() {
        return apiClient.get('/admnapi/jsonp.php?method=getCommonData')
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    updateBasicConfig(item: any) {
        let data = qs.stringify({ store_id: item.store_id, group: item.group, key: item.key, value: item.value })
        return apiClient.post('/admnapi/jsonp.php?method=updateBasicConfing', data)
            .then(action((res: any) => {
                if (res.data.value) {
                    return [true, res.data]
                } else {
                    throw new Error('Error occured while updating data.')
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    // Order Details

    @action
    getOrders() {
        return apiClient.post('/admnapi/jsonp.php?method=getorders')
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    getOrdersWithCount(count: any, dateAdded: any) {
        let data = { count: count, filter_date_added: dateAdded }
        return apiClient.post('/admnapi/jsonp.php?method=getorders', data)
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    getOrdersWithDate(dateAdded: any) {
        let data = { filter_date_added: dateAdded }
        return apiClient.post('/admnapi/jsonp.php?method=getorders', data)
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    getOrder(id: number) {
        return apiClient.get('/admnapi/jsonp.php?method=getorder', { params: { order_id: id } })
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    deleteOrder(data: any) {
        return apiClient.post('/admnapi/jsonp.php?method=deleteOrder', data)
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    // Table Orders


    //getStatus

    @action
    getStatus() {
        return apiClient.get('/admnapi/jsonp.php?method=getorderstatus')
            .then(action((res: any) => {
                if (!res.data.error) {
                    let allOrderStatus = res.data.data.all_status.map((item: any) => ({ id: item.id, name: item.name }))
                    let allStatus = allOrderStatus
                    localStorage.setItem("allStatus", JSON.stringify(allStatus))
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    //getOrderHistory

    @action
    getOrderHistory(id: number) {
        return apiClient.get('/admnapi/jsonp.php?method=getOrderHistory', { params: { order_id: id } })
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }


    //Todays sales Report

    @action
    getTodaySalesReport() {
        return apiClient.post('/admnapi/jsonp.php?method=getEnofDaySales')
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    //End Of Day Report

    @action
    getEndOfDaySalesReport(date_start: any, date_end: any, time_start: any, time_end: any) {
        let data = { filter_date_start: date_start, filter_date_end: date_end, filter_time_start: time_start, filter_time_end: time_end }
        return apiClient.post('/admnapi/jsonp.php?method=getEnofDaySales', data)
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    getSaveReport() {

        return apiClient.get('/admnapi/jsonp.php?method=saveReport')
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }
    @action

    getSavedReport() {

        return apiClient.get('/admnapi/jsonp.php?method=savedReport')
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    getSavedZReport(report_id: any) {
        let data = { report_id: report_id }
        return apiClient.post('/admnapi/jsonp.php?method=savedReport', data)
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }
    @action
    getZReports() {
        return apiClient.get('/admnapi/jsonp.php?method=zReport')
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }
    @action
    updateOrder(data: any) {
        return apiClient.post('/admnapi/jsonp.php?method=updateOrder', data)
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }


    @action
    acceptOrderIdTimeNotUpdated(order_id: any, status_id: any, comment: any, notify: any, accepted: any) {
        let data = { order_id: order_id, order_status_id: status_id, comment: comment, notify: notify, ak: accepted }

        return apiClient.post('/admnapi/jsonp.php?method=updateOrder', data)
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    acceptOrderIdTimeUpdated(order_id: any, status_id: any, comment: any, notify: any, order_time: any, accepted: any) {
        let data = { order_id: order_id, order_status_id: status_id, comment: comment, notify: notify, ak: accepted, dt: moment(order_time).format('H:mm') }

        return apiClient.post('/admnapi/jsonp.php?method=updateOrder', data)
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }

    @action
    notUpdateOrderTime(order_id: any, status_id: any, comment: any, notify: any) {
        let data = { order_id: order_id, order_status_id: status_id, comment: comment, notify: notify }

        return apiClient.post('/admnapi/jsonp.php?method=updateOrder', data)
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }


    @action
    updateOrderTime(order_id: any, status_id: any, comment: any, notify: any, order_time: any) {
        let data = { order_id: order_id, order_status_id: status_id, comment: comment, notify: notify, dt: moment(order_time).format('H:mm') }

        return apiClient.post('/admnapi/jsonp.php?method=updateOrder', data)
            .then(action((res: any) => {
                if (!res.data.error) {
                    return [true, res.data]
                } else {
                    throw new Error(res.data.error)
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data : err.message
                return [false, errorMsg]
            }))
    }
}