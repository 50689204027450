import { ErrorMessage, Field, Form, Formik, FieldArray } from 'formik';
import { observer } from 'mobx-react-lite';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import * as yup from 'yup';
import { useStore } from '../stores/useStore';
import { MultiSelect } from 'primereact/multiselect';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { FiXCircle, FiPlus } from 'react-icons/fi';

const FormErrorMsg = styled.div`
 color: red;
`

export const ToppingForm = observer((props: any) => {
    const [errorMsg, setErrorMsg] = useState(null)
    const { mainStore } = useStore();
    const history = useHistory();
    const { id } = useParams();
    const editMode = (id && !mainStore.topping) ? true : false;
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState<any>();

    useEffect(() => {
        setLoading(true)
        if (editMode) {
            mainStore.getTopping(Number(id))
                .then(([success, data]) => {
                    if (success) {
                        setFormData(tranformFormData(data))
                    }
                })
                .finally(() => setLoading(false))
        } else {
            setFormData({ topping_id: 0, topping_description: { 1: { name: null } }, swap_option: "0", sort_order: 0, topping_value: [] })
            setLoading(false)
        }
    }, [])

    function tranformFormData(data: any) {
        // for (const key in data.options) {
        //     data[key] = data.options[key]
        // }
        data.topping_value = data.topping_values
        delete data.topping_values
        return data
    }

    const handleCancel = () => {
        if (mainStore.visible == true) {
            mainStore.stopLoading = true
            mainStore.visible = false
            mainStore.topping = false
        } else {
            history.goBack()
        }
    }

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="p-grid p-justify-between">
                    <div className="p-col">
                        <div className='page-heading'>{editMode ? 'Edit' : 'New'} Topping</div>
                    </div>
                </div>
            </div>
            <div className="p-col-12" >
                <Formik
                    initialValues={formData}
                    enableReinitialize={true}
                    validateOnMount={true}
                    validationSchema={yup.object({
                        topping_description: yup.object({ 1: yup.object({ name: yup.string().required('Required').nullable() }) }),
                        topping_value: yup.array().of(
                            yup.object({
                                topping_value_description: yup.object({ 1: yup.object({ name: yup.string().required('Required').nullable() }) })
                            })
                        )
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        mainStore.updateTopping(values)
                            .then(([success, data]) => {
                                if (success) {
                                    mainStore.toppingValue = data.id
                                    if (mainStore.topping == true) {
                                        mainStore.visible = false;
                                        mainStore.topping = false;
                                        mainStore.isTopping = true;
                                    } else {
                                        history.push('/toppings')
                                    }

                                } else {
                                    setErrorMsg(data)
                                }
                            })
                    }}>
                    {formik =>
                        <Card>
                            <Form>
                                {loading ? <div>Loading...</div> :
                                    errorMsg ? <div className='error-msg'>{errorMsg}</div> :
                                        <div className='p-grid'>
                                            {formik.values && <>
                                                <div className='p-col-12 p-md-3'>
                                                    <div className='p-grid p-fluid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Topping Name</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='topping_description.1.name' as={InputText} />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='topping_description.1.name' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='p-col-12 p-md-3'>
                                                    <div className='p-grid p-fluid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Swap Option</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='swap_option' type='checkbox' as={Checkbox} value={formik.values['swap_option'] ? '1' : '0'} checked={formik.values['swap_option'] == '1' ? true : false} />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='swap_option' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='p-col-12 p-md-3'>
                                                    <div className="p-grid p-fluid form-control-group">
                                                        <div className="p-col-12">
                                                            <label>Sort Order</label>
                                                        </div>
                                                        <div className="p-col-12">
                                                            <Field name='sort_order' as={InputText} />
                                                        </div>
                                                        <div className="p-col-12">
                                                            <ErrorMessage name='sort_order' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}
                                            <div className='p-col-12 dynamic-form'>
                                                <div className='p-grid options-table'>
                                                    <div className='p-col-12 table-head'>
                                                        <div className='p-grid p-fluid'>
                                                            <div className='p-col-12 p-md-3'>
                                                                <label>Value Name</label>
                                                            </div>
                                                            <div className='p-col-12 p-md-2'>
                                                                <label>Short Code</label>
                                                            </div>
                                                            <div className='p-col-12 p-md-2'>
                                                                <label>Price</label>
                                                            </div>
                                                            <div className='p-col-12 p-md-2'>
                                                                <label>Sort Order</label>
                                                            </div>
                                                            <div className='p-col-12 p-md-2'>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <FieldArray name="topping_value">
                                                        {arrayHelpers => <>
                                                            {formik.values && formik.values['topping_value']?.map((item: any, index: number) => (
                                                                <div className='p-col-12 table-body'>
                                                                    <div className='p-grid  p-fluid'>
                                                                        <div className='p-col-12 p-md-3'>
                                                                            <div className='p-grid form-control-group'>
                                                                                <div className='p-col-12  responsive-label'>
                                                                                    <label>Name</label>
                                                                                </div>
                                                                                <div className='p-col-12'>
                                                                                    <Field name={`topping_value.${index}.topping_value_description.1.name`} as={InputText} />
                                                                                </div>
                                                                                <div className='p-col-12'>
                                                                                    <ErrorMessage name={`topping_value.${index}.topping_value_description.1.name`} component={FormErrorMsg} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='p-col-12 p-md-2'>
                                                                            <div className='p-grid form-control-group'>
                                                                                <div className='p-col-12  responsive-label'>
                                                                                    <label>Short Code</label>
                                                                                </div>
                                                                                <div className='p-col-12'>
                                                                                    <Field name={`topping_value.${index}.topping_value_description.1.shortcode`} as={InputText} />
                                                                                </div>
                                                                                <div className='p-col-12'>
                                                                                    <ErrorMessage name={`topping_value.${index}.topping_value_description.1.shortcode`} component={FormErrorMsg} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='p-col-12 p-md-2'>
                                                                            <div className='p-grid form-control-group'>
                                                                                <div className='p-col-12  responsive-label'>
                                                                                    <label>Price</label>
                                                                                </div>
                                                                                <div className='p-col-12'>
                                                                                    <Field name={`topping_value.${index}.price`} as={InputText} className='right' />
                                                                                </div>
                                                                                <div className='p-col-12'>
                                                                                    <ErrorMessage name={`topping_value.${index}.price`} component={FormErrorMsg} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='p-col-12 p-md-2'>
                                                                            <div className='p-grid form-control-group'>
                                                                                <div className='p-col-12  responsive-label'>
                                                                                    <label>Sort Order</label>
                                                                                </div>
                                                                                <div className='p-col-12'>
                                                                                    <Field name={`topping_value.${index}.sort_order`} as={InputText} />
                                                                                </div>
                                                                                <div className='p-col-12'>
                                                                                    <ErrorMessage name={`topping_value.${index}.sort_order`} component={FormErrorMsg} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='p-col-12 p-md-2 p-col-align-center'>
                                                                            <div className='p-grid form-control-group'>
                                                                                <div className='p-col-12 icon-container'>
                                                                                    <div onClick={(e) => arrayHelpers.remove(index)}>
                                                                                        <FiXCircle />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='p-col-12' style={{ height: '.5em' }}>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}


                                                            <div className='p-col-12'>
                                                                <div className='icon-container'>
                                                                    <div> New Topping Value </div>
                                                                    <div onClick={(e) => arrayHelpers.push({ topping_value_description: { 1: { name: null } } })}>
                                                                        <FiPlus />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        }
                                                    </FieldArray>
                                                </div>

                                            </div>

                                            <div className='p-col-12'>
                                                <div className="p-grid">
                                                    <div className="p-col form-action-container">
                                                        <Button type='submit' disabled={formik.isSubmitting} label={editMode ? 'Update' : 'Save'} className='p-button-primary' />
                                                        <Button type='button' label="Cancel" onClick={() => handleCancel()} className='p-button-secondary' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </Form>
                        </Card>
                    }
                </Formik>
            </div>
        </div>
    )
})

