import React, { Component } from 'react';

export class AppFooter extends Component {

    render() {
        return null
        // return <div className="layout-footer">
        //     <div className="p-grid">
        //         <div className="p-col-6">
        //             {/* <img src="assets/layout/images/logo.svg" alt=""/> */}
        //         </div>
        //         <div className="p-col-6">
        //         </div>
        //     </div>
        // </div>
    }
}