import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import { useStore } from '../stores/useStore';

const FormErrorMsg = styled.div`
 color: red;
`
export const ZReport = observer((props: any) => {
    const { mainStore } = useStore()
    const [data, setData] = useState<any>(null)
    const [paymentData, setPaymentData] = useState<any>(null)
    const [deliveryData, setDeliveryData] = useState<any>(null)
    const [productData, setProductData] = useState<any>(null)
    const [loading, setLoading] = useState(true)
    const [errorMsg, setErrorMsg] = useState(null)
    const [refresh, setRefresh] = useState(true)
    const [paymentTotal, setPaymentTotal] = useState<any>(null)
    const [deliveryTotal, setDeliveryTotal] = useState<any>(null)
    const { id } = useParams<any>();
    const history = useHistory();

    useEffect(() => {
        if (refresh) {
            setLoading(true)
            mainStore.getSavedZReport(Number(id))
                .then(([success, data]) => {
                    if (success) {
                        setData(data)
                        const payment = data?.data?.payment_report?.map((item: any) => item)
                        setPaymentData(payment)
                        const totalPayment = data?.data?.payment_total
                        setPaymentTotal(totalPayment)
                        const delivery = data?.data?.shipping_report?.map((item: any) => item)
                        setDeliveryData(delivery)
                        const totalDeliveryPayment = data?.data?.shipping_total
                        setDeliveryTotal(totalDeliveryPayment)
                        const product = data?.data?.product_report?.map((item: any) => item)
                        setProductData(product)
                    }
                    else {
                        setErrorMsg(data)
                    }
                })

                .finally(() => {
                    setLoading(false)
                    setRefresh(false)
                })
        }
    }, [refresh])

    const footer = (
        <div className="p-grid ">
            <div className="p-col-12">
                <div className="p-grid p-justify-between">
                    <div className="p-col-10">
                        Total Amount
                    </div>
                    <div className="p-col-2" style={{ textAlign: "right" }}>

                        {paymentTotal}
                    </div>
                </div>

            </div>
        </div>
    );

    const deliveryFooter = (
        <div className="p-grid ">
            <div className="p-col-12">
                <div className="p-grid p-justify-between">
                    <div className="p-col-10">
                        Total Amount
                    </div>
                    <div className="p-col-2" style={{ textAlign: "right" }}>
                        {deliveryTotal}
                    </div>
                </div>

            </div>
        </div>
    );

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className='page-heading'>
                    <i className="pi pi-arrow-left" style={{ 'fontSize': '1em' }} onClick={() => history.goBack()}></i>
                    <div className='inline-spacer' />Z Report ID: #{id}
                </div>
            </div>

            <div className="p-col-12">
                <Card>
                    {loading ? <div>Loading...</div> :
                        errorMsg ? <div className='error-msg'>{errorMsg} <Button className='p-button-secondary' label='Cancel' onClick={(e) => { setErrorMsg(null); setRefresh(true); }} /> </div> :
                            <div className='p-grid'>

                                <div className="p-grid" >
                                    <div className="pcol-12 p-md-5">
                                        <div className="p-grid p-dir-col">
                                            <div className="p-col-12 p-md-12">
                                                <Card title="Demo" >
                                                    <div className='p-grid '>
                                                        <div className='p-col-12' >
                                                            <div className='p-grid '>
                                                                <div className='p-col-4' >Store Name :</div>
                                                                <div className='p-col-8'><b> {data?.data?.store_name}</b> </div>
                                                            </div>
                                                        </div>

                                                        <div className='p-col-12'>
                                                            <div className='p-grid '>
                                                                <div className='p-col-4' >Date Start :</div>
                                                                <div className='p-col-8'><b>{moment(data?.date_start).format('DD-MM-YYYY hh:mm:ss')}</b> </div>
                                                            </div>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <div className='p-grid '>
                                                                <div className='p-col-4'>Date End : </div>
                                                                <div className='p-col-8'><b>{moment(data?.date_end).format('DD-MM-YYYY hh:mm:ss')}</b></div>
                                                            </div>
                                                        </div>


                                                        <div className='p-col-12'>
                                                            <div className='p-grid '>
                                                                <div className='p-col-4' >Report Taken :</div>
                                                                <div className='p-col-8'><b>{moment(data?.data?.report_time).format('DD-MM-YYYY hh:mm:ss')} </b></div>
                                                            </div>
                                                        </div>

                                                        <div className='p-col-12'>
                                                            <div className='p-grid '>
                                                                <div className='p-col-4' >User</div>
                                                                <div className='p-col-8'><b>{data.data.user_name} </b></div>
                                                            </div>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <div className='p-grid '>
                                                                <div className='p-col-4' >System</div>
                                                                <div className='p-col-8'><b> {data.data.system_name}</b></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>

                                            <div className="p-col-12 p-md-12">
                                                <DataTable value={productData} emptyMessage='No Product available' responsive={true}>
                                                    <Column field="product_name" header="Product Name" style={{ width: '50%' }} />
                                                    <Column field="qty" header="Quantity" bodyStyle={{ textAlign: 'right' }} />
                                                    <Column field="price" header="Price" bodyStyle={{ textAlign: 'right' }} />
                                                </DataTable>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-7 ">
                                        <div className="p-grid p-dir-col">
                                            <div className="p-col-12 p-md-12">
                                                <DataTable value={paymentData} footer={footer} emptyMessage='No Payment details available' responsive={true}>
                                                    <Column field="name" header="Payment" style={{ width: '50%' }} />
                                                    <Column field="count" header="No:" />
                                                    <Column field="total" header="Total" bodyStyle={{ textAlign: 'right' }} />
                                                </DataTable>
                                            </div>
                                            <div className="p-col-12 p-md-12">
                                                <DataTable value={deliveryData} footer={deliveryFooter} emptyMessage='No delivery details available' responsive={true}>
                                                    <Column field="name" header="Delivery" style={{ width: '50%' }} />
                                                    <Column field="count" header="No:" />
                                                    <Column field="total" header="Total" bodyStyle={{ textAlign: 'right' }} />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                    }
                </Card>
            </div>
        </div >
    )
})
