import { observer } from "mobx-react-lite";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Tree } from 'primereact/tree';
import React, { Fragment, useEffect, useState } from 'react';
import { Message } from 'primereact/message';
import { useStore } from '../stores/useStore';

export const ImageManager = observer(() => {
    const [errorMsg, setErrorMsg] = useState<any>(null)
    const [dialogueErrorMsg, setDialogueErrorMsg] = useState<any>(null)
    const { mainStore } = useStore()
    const [loading, setLoading] = useState(true)
    const [scrollPanelLoading, setScrollPanelLoadingg] = useState(false)
    const [refresh, setRefresh] = useState(true)
    const [update, setUpdate] = useState(false)
    const [data, setData] = useState<any>()
    const [folders, setFolders] = useState<any>()
    const [dirName, setDirName] = useState<any>('')
    const [newDirName, setNewDirName] = useState<any>('')
    const [imagePath, setImagePath] = useState<any>()
    const [dialogueBox1, setDialogueBox1] = useState(false)
    const [dialogueBox2, setDialogueBox2] = useState(false)
    const [dialogueBox3, setDialogueBox3] = useState(false)
    const [dialogueBox4, setDialogueBox4] = useState(false)
    const [dialogueBox5, setDialogueBox5] = useState(false)
    const [dialogueBox6, setDialogueBox6] = useState(false)
    const [newName, setNewName] = useState<any>();
    const [currentImgName, setCurrentImgName] = useState();
    const [dialogueError, setDialogueError] = useState(false)
    const [value, setValue] = useState<any>('Image')
    const [pathOpt, setPathOpt] = useState<any>([])
    const [targetPath, setTargetPath] = useState<any>()
    const [delPath, setDelPath] = useState<any>()
    const [curPath, setCurPath] = useState<any>()
    const [copyName, setCopyName] = useState<any>()
    const [file, setFile] = useState<any>(null)
    const [treeNodes, setTreeNodes] = useState<any>([])
    const [img, setImg] = useState<any>([])
    const [isFolder, setIsFolder] = useState<boolean>(true)
    const [imgFileName, setImgFileName] = useState<any>()

    useEffect(() => {
        if (refresh) {
            setLoading(true)
            mainStore.getFiles(String(dirName))
                .then(([success, data]) => {
                    if (success) {
                        setData(data?.files)
                        var image = data?.files?.map((item: any) => ({ path: item?.path }))
                        setImg(image)
                        setCurPath('')
                        return mainStore.getAllFolders()
                    }
                    else {
                        throw new Error(data)
                    }
                })
                .then(([success, data]) => {
                    if (success) {
                        setFolders(data)
                        var nodes: any = [{ key: "Image", label: "Image", icon: icon, children: [] }]
                        for (let index = 1; index < data?.length; index++) {
                            const element = data[index];
                            nodes[0].children.push(createChildNode(element));
                        }
                        setTreeNodes(nodes)

                        let flattened = data?.flat(Infinity)
                        var temp = flattened?.map((item: any) => ({ name: item == '' ? 'Image' : item, value: item == '' ? '' : item }))
                        setPathOpt(temp)
                    }
                })
                .catch(err => {
                    setErrorMsg(err)
                })
                .finally(() => {
                    setLoading(false)
                    setRefresh(false)
                })
        } else if (update) {
            mainStore.getFiles(String(dirName))
                .then(([success, data]) => {
                    if (success) {
                        // setScrollPanelLoadingg(true)
                        setData(data?.files)
                        setUpdate(false)
                    }
                    else {
                        setErrorMsg(data)
                    }
                })

        }
    }, [refresh, update])

    const handleCreate = () => {
        mainStore.create(dirName, newDirName)
            .then(([success, data]) => {
                if (success) {
                    setDialogueBox3(false)
                    setNewDirName('')
                    folderUpdate()
                } else {
                    setDialogueErrorMsg(data)
                }
            })

    }

    const folderUpdate = () => {
        mainStore.getAllFolders()
            .then(([success, data]) => {
                if (success) {
                    setFolders(data)
                    var nodes: any = [{ key: "Image", label: "Image", icon: icon, children: [] }]
                    for (let index = 1; index < data?.length; index++) {
                        const element = data[index];
                        nodes[0].children.push(createChildNode(element));
                    }
                    let flattened = data?.flat(Infinity)
                    var temp = flattened?.map((item: any) => ({ name: item == '' ? 'Image' : item, value: item == '' ? '' : item }))
                    setPathOpt(temp)
                    setTreeNodes(nodes)
                }
                else {
                    setDialogueErrorMsg(data)
                }
            })
    }

    const icon = "pi pi-folder";

    const createChildNode = (arr: any) => {
        let key: any;
        let label: any;
        let children: any = [];
        if (arr?.length >= 1) {
            key = arr[0];
            label = key.includes("/") ? key.split("/").pop() : key;
            label = label.charAt(0).toUpperCase() + label.slice(1);
            for (let index = 1; index < arr.length; index++) {
                const element = arr[index];
                children.push(createChildNode(element));
            }
        }
        return { key: key, label: label, icon: icon, children: children };

    }


    const handleEdit = () => {
        mainStore.rename(newName, curPath)
            .then(([success, data]) => {
                if (success) {
                    if (isFolder) {
                        setDirName('')
                        setValue('Image')
                        folderUpdate()
                    }
                    setUpdate(true)
                    setDialogueBox1(false)
                    setNewName('')
                    setImagePath('')
                } else {
                    setDialogueErrorMsg(data)
                }
            })
    }

    const handleDelete = () => {
        mainStore.delete(delPath)
            .then(([success, data]) => {
                if (success) {
                    if (isFolder) {
                        setDirName('')
                        setValue('Image')
                        folderUpdate()
                        setUpdate(true)
                        setDelPath('')
                        setDialogueBox6(false)
                    } else {
                        setUpdate(true)
                        setDelPath('')
                        setDirName('')
                        folderUpdate()
                        setDialogueBox6(false)
                    }
                } else {
                    setDialogueErrorMsg(data)
                }
            })
    }

    const filterTargetOpt = () => {
        if (isFolder) {
            if (curPath == '') {
                setPathOpt([])
            } else {
                let flattened = folders?.flat(Infinity)
                var tempOpt = flattened?.map((item: any) => ({ name: item == '' ? 'Image' : item, value: item == '' ? '' : item }))
                var temp = tempOpt.filter((item: any) => item.name != curPath)
                setPathOpt(temp)
            }

        }
        setDialogueBox2(true);
        setErrorMsg(null)
    }


    const handleMove = () => {
        mainStore.move(curPath, targetPath)
            .then(([success, data]) => {
                if (success) {
                    if (isFolder) {
                        setDirName('')
                        setValue('Image')
                        folderUpdate()
                        setUpdate(true)
                        setDelPath('')
                        setDialogueBox2(false)
                    } else {
                        setUpdate(true)
                        setTargetPath('')
                        setCurPath('')
                        setDialogueBox2(false)
                    }
                } else {
                    setDialogueBox2(false)
                    setDialogueErrorMsg(data)
                }
            })

    }

    const handleCopy = () => {
        mainStore.copy(curPath, copyName)
            .then(([success, data]) => {
                if (success) {
                    if (isFolder) {
                        setDirName('')
                        setValue('Image')
                        folderUpdate()
                        setCopyName('')
                        setDialogueBox4(false)
                    } else {
                        setUpdate(true)
                        setCopyName('')
                        setDialogueBox4(false)
                    }

                } else {
                    setDialogueErrorMsg(data)
                }
            })
    }

    const onSubmit = async (e: any) => {
        e.preventDefault()
        let res = await uploadFile(file);
    }

    const onChange = (e: any) => {
        setImgFileName(e.target.files[0].name)
        setFile(e.target.files[0])
    }

    const uploadFile = async (file: any) => {

        const formData = new FormData();
        formData.append('image', file)
        formData.append('directory', dirName)

        mainStore.upload(formData)
            .then(([success, data]) => {
                if (success) {
                    setUpdate(true)
                    setDialogueBox5(false)
                } else {
                    setDialogueErrorMsg(data)
                }
            })
    }

    return (
        <div className="p-grid" >
            <div className="p-col-12">
                <div className="p-d-flex card">
                    {loading ? <div>Loading...</div> :
                        errorMsg ? <div style={{ color: 'red' }}>{errorMsg}</div> :
                            <Fragment>
                                <div className="p-d-flex card p-grid" >

                                    <div className="p-col-6 p-md-2">
                                        <Button label="New Folder" icon="pi pi-folder" style={{ background: "white", color: "#9a0007", border: 'none' }} onClick={() => { setDialogueBox3(true); setNewDirName(''); setDialogueErrorMsg(null) }} />
                                    </div>

                                    <div className="p-col-6 p-md-2 ">
                                        <Button label="Delete" icon="pi pi-trash" style={{ background: "white", color: "#9a0007", border: 'none' }} onClick={() => { setDialogueBox6(true); setDialogueErrorMsg(null) }} />
                                    </div>

                                    <div className="p-col-6 p-md-2">
                                        <Button label="Move" icon="pi pi-reply" style={{ background: "white", color: "#9a0007", border: 'none' }} onClick={() => { filterTargetOpt(); setDialogueErrorMsg(null) }} />
                                    </div>

                                    <div className="p-col-6 p-md-2">
                                        <Button label="Copy" icon="pi pi-copy" style={{ background: "white", color: "#9a0007", border: 'none' }} onClick={() => { setDialogueBox4(true); setDialogueErrorMsg(null) }} />
                                    </div>

                                    <div className="p-col-6 p-md-2">
                                        <Button label="Rename" icon="pi pi-pencil" style={{ background: "white", color: "#9a0007", border: 'none' }} onClick={() => { setDialogueBox1(true); setDialogueErrorMsg(null) }} />
                                    </div>
                                    <div className="p-col-6 p-md-2">
                                        <Button label="Upload" icon="pi pi-upload" style={{ background: "white", color: "#9a0007", border: 'none' }} onClick={() => { setDialogueBox5(true); setDialogueErrorMsg(null) }} />
                                    </div>
                                </div>

                                <div className="p-col-12">
                                    <div className="p-grid p-fluid">
                                        <div className="p-col-12 p-sm-4 p-md-3">
                                            <Tree value={treeNodes} selectionKeys={value} selectionMode='single' onSelect={(e: any) => { setIsFolder(true); setValue(e.node.key); setNewName(e.node.key == 'Image' ? '' : e.node.key); setDirName(e.node.key == 'Image' ? '' : e.node.key); setCurPath(e.node.key == 'Image' ? '' : e.node.key); setDelPath(e.node.key); setUpdate(true) }} />
                                        </div>

                                        <div className="p-col-12 p-sm-8 p-md-9" >
                                            <ScrollPanel style={{ width: '100%', height: '400px' }}>

                                                <div className="p-grid">
                                                    <div>{errorMsg ? <div style={{ color: 'red', margin: '10px' }}>{errorMsg}</div> : data?.length == 0 && <div style={{ margin: '10px' }}>No data available</div>}</div>

                                                    {data?.map((item: any) =>

                                                        <div className="p-col-6 p-sm-4 p-md-4 p-lg-3" >
                                                            <div className="p-grid card"  >
                                                                <div className="p-col-12">
                                                                    <img src={item?.path} onDoubleClick={() => { mainStore.imageURL = item?.path; mainStore.imagePath = item?.file; mainStore.fileManagerDialogue = false }} alt={item?.filename} style={{ height: "100%", width: "100%", border: imagePath == item?.file ? 'solid #9a0007 1px' : '' }} onClick={() => { setImagePath(item?.file); setIsFolder(false); setDelPath(item?.file); setCurPath(item?.file); setNewName(item?.filename.replace(/\..+$/, '')); setCurrentImgName(item?.filename.replace(/\..+$/, '')) }} />
                                                                </div>
                                                                <div className="p-col-12 " >{item?.filename}</div>
                                                            </div>
                                                        </div>
                                                    )}

                                                </div>

                                            </ScrollPanel>

                                        </div>

                                    </div>
                                </div>
                            </Fragment>
                    }

                </div>

                <Dialog header="Rename" visible={dialogueBox1} style={{ width: '300px' }} showHeader onHide={() => { setDialogueBox1(false); setNewName('') }}>
                    <div className='p-grid p-fluid'>
                        <div className='p-col-12'>
                            <div className="p-col-12">
                                {dialogueErrorMsg && <span style={{ color: 'red' }}>{dialogueErrorMsg}</span>}
                                <InputText value={newName} disabled={!newName} aria-describedby="edit-help" onChange={(e: any) => { setNewName(e.target.value); setDialogueError(false) }} />
                                {dialogueError && <small id="edit-help" style={{ color: "red" }}>Name already exist</small>}
                            </div>
                            <div className='p-col-12 p-justify-end'>
                                <div className="p-grid p-justify-end">
                                    <div className="p-col-4 p-md-4">
                                        <div className="box">
                                            <Button label='Save' disabled={!newName} onClick={handleEdit} />

                                        </div>
                                    </div>
                                    <div className="p-col-4 p-md-4">
                                        <div className="box">
                                            <Button label='Cancel' onClick={() => { setDialogueBox1(false); setNewName('') }} className='p-button-secondary' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog header="Move" visible={dialogueBox2} style={{ width: '300px' }} showHeader onHide={() => { setDialogueBox2(false); setNewName(''); setTargetPath([]) }}>
                    <div className='p-grid p-fluid'>
                        <div className='p-col-12'>
                            <div className="p-col-12">
                                {dialogueErrorMsg && <span style={{ color: 'red' }}>{dialogueErrorMsg}</span>}
                                From <InputText value={curPath == '' ? 'Image' : curPath} contentEditable={false} />
                            </div>
                            <div className="p-col-12">
                                To <Dropdown value={targetPath} options={pathOpt} aria-describedby="move-help" optionLabel='name' onChange={(e) => { setTargetPath(e.value); setDialogueError(false) }} placeholder="Select a target path" />
                                {dialogueError && <small id="move-help" style={{ color: "red" }}>File already exists</small>}
                            </div>

                            <div className='p-col-12 p-justify-end'>
                                <div className="p-grid p-justify-end">
                                    <div className="p-col-4 p-md-4">
                                        <div className="box">
                                            <Button label='Save' onClick={handleMove} />
                                        </div>
                                    </div>
                                    <div className="p-col-4 p-md-4">
                                        <div className="box">
                                            <Button label='Cancel' onClick={() => { setDialogueBox2(false); setNewName(''); setCurPath(''); setTargetPath('') }} className='p-button-secondary' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog header="New Folder" visible={dialogueBox3} style={{ width: '300px' }} showHeader onHide={() => { setDialogueBox3(false); setNewDirName('') }}>
                    <div className='p-grid p-fluid'>
                        <div className='p-col-12'>
                            <div className="p-col-12">
                                {dialogueErrorMsg && <span style={{ color: 'red' }}>{dialogueErrorMsg}</span>}
                                <InputText value={newDirName} onChange={(e: any) => { setNewDirName(e.target.value); setDialogueError(false) }} placeholder="Enter folder name" />
                            </div>
                            <div className='p-col-12'>
                                {dialogueError && <span style={{ color: 'red' }}>Folder already exist</span>}
                            </div>
                            <div className='p-col-12 p-justify-end'>
                                <div className="p-grid p-justify-end">
                                    <div className="p-col-4 p-md-4">
                                        <div className="box">
                                            <Button label='Save' disabled={!newDirName} onClick={handleCreate} />

                                        </div>
                                    </div>
                                    <div className="p-col-4 p-md-4">
                                        <div className="box">
                                            <Button label='Cancel' onClick={() => { setDialogueBox3(false); setNewDirName('') }} className='p-button-secondary' />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog header="Copy" visible={dialogueBox4} style={{ width: '300px' }} showHeader onHide={() => { setDialogueBox4(false); setCopyName('') }}>
                    <div className='p-grid p-fluid'>
                        <div className='p-col-12'>
                            <label className="p-col-12">Copied file/dir name: {curPath == '' ? 'Image' : curPath}</label>
                            <div className="p-col-12">
                                {dialogueErrorMsg && <span style={{ color: 'red' }}>{dialogueErrorMsg}</span>}
                                <InputText value={copyName} onChange={(e: any) => { setCopyName(e.target.value); setDialogueError(false) }} placeholder="New name of file or directory " />
                            </div>
                            <div className='p-col-12'>
                                {dialogueError && <span style={{ color: 'red' }}>Folder already exist</span>}
                            </div>
                            <div className='p-col-12 p-justify-end'>
                                <div className="p-grid p-justify-end">
                                    <div className="p-col-4 p-md-4">
                                        <div className="box">
                                            <Button label='Save' disabled={!copyName} onClick={handleCopy} />

                                        </div>
                                    </div>
                                    <div className="p-col-4 p-md-4">
                                        <div className="box">
                                            <Button label='Cancel' onClick={() => { setDialogueBox4(false); setCopyName('') }} className='p-button-secondary' />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog header="Upload" visible={dialogueBox5} style={{ width: '300px' }} showHeader onHide={() => { setDialogueBox5(false); setFile(''); setImgFileName('') }}>
                    <div className='p-grid p-fluid'>
                        <div className='p-col-12'>
                            <div className="p-col-12">{dialogueErrorMsg && <span style={{ color: 'red' }}>{dialogueErrorMsg}</span>}</div>
                            <form onSubmit={onSubmit}>
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-8">
                                            <InputText type="file" id='img' onChange={onChange} contentEditable={false} hidden={true} />
                                            <InputText style={{ fontWeight: 'bold' }} placeholder='File name' value={imgFileName} disabled={true} contentEditable={false} />
                                        </div>
                                        <div className="p-col-4">
                                            <button type="button" style={{ height: '30px', fontWeight: 'bold', border: 'none', width: '70px', color: '#FFFFFF', background: '#F96F43', outlineColor: '#F96F43' }}>
                                                <label htmlFor="img">Browse</label>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                <div className='p-col-12 p-justify-end'>
                                    <div className="p-grid p-justify-end">
                                        <div className="p-col-4 p-md-4">
                                            <div className="box">
                                                <Button type='submit' disabled={!file} label='Upload' />

                                            </div>
                                        </div>
                                        <div className="p-col-4 p-md-4">
                                            <div className="box">
                                                <Button type="button" label='Cancel' onClick={() => { setDialogueBox5(false); setFile(''); setImgFileName('') }} className='p-button-secondary' />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </Dialog>

                <Dialog header="Delete Confirmation" visible={dialogueBox6} style={{ width: '300px' }} showHeader onHide={() => { setDialogueBox6(false); setDelPath('') }}>
                    <div className='p-grid p-fluid'>

                        <div className='p-col-12'>
                            {/* <div className="p-col-12">{errorMsg && <span style={{ color: 'red' }}>{errorMsg}</span>}</div> */}
                            {!delPath ? <label style={{ color: "red" }}>Please select file or directory to delete</label> :
                                <Fragment>

                                    <div className="p-col-12">
                                        {dialogueErrorMsg && <span style={{ color: 'red' }}>{dialogueErrorMsg}</span>}
                                        <span>Are you sure to delete <b>{delPath}</b></span><br />

                                    </div>
                                    <div className='p-col-12 p-justify-end'>
                                        <div className="p-grid p-justify-end">
                                            <div className="p-col-4 p-md-4">
                                                <div className="box">
                                                    <Button type='submit' disabled={!delPath} label='Yes' onClick={handleDelete} />

                                                </div>
                                            </div>
                                            <div className="p-col-4 p-md-4">
                                                <div className="box">
                                                    <Button type="button" label='No' onClick={() => { setDialogueBox6(false); }} className='p-button-secondary' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </div>
                    </div>
                </Dialog>

            </div>
        </div >

    )
})