import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import App from "./App";
import { Login } from './pages/Login'
import ProtectedRoute from './ProtectedRoute'
import Error from "./pages/Error";
import NotFound from "./pages/NotFound";
import AccessDenied from "./pages/AccessDenied";

export const scrollToRef = (ref: any) => {
	//console.log(ref.current.offsetTop)
	window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop - 100 }) // 70px app topbar + 30 space
}

class AppWrapper extends Component {
	render() {
		return (
			<Switch>
				<Route path="/login">
					<Login />
				</Route>
				<Route path="/error">
					<Error />
				</Route>
				<Route path="/404">
					<NotFound />
				</Route>
				<Route path="/accessdenied">
					<AccessDenied />
				</Route>

				<ProtectedRoute>
					<App />
				</ProtectedRoute>
			</Switch>
		)
	}
}

export default AppWrapper;