import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useStore } from '../stores/useStore';
import { Card } from 'primereact/card';
import { FiPlus, FiXCircle } from 'react-icons/fi';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';

const FormErrorMsg = styled.span`
 color: red;
 display: 'inline-block';
`

export const HomeDelivery = observer(() => {
    const { mainStore } = useStore();
    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState(null)
    const [refresh, setRefresh] = useState(true)
    const [stores, setStores] = useState();
    const [storesOpt, setStoresOpt] = useState([]);
    const [checked, setChecked] = useState(false);
    const [formData, setFormData] = useState({});
    const [payments, setPayments] = useState<any>()
    const history = useHistory();
    const [products1, setProducts1] = useState<any>(null);
    const [products2, setProducts2] = useState<any>(null);
    const [storeChange, setStoreChange] = useState<boolean>(false);
    // console.log("stores", stores)

    useEffect(() => {
        if (refresh) {
            setLoading(true)
            mainStore.getDeliverySettings(Number(stores))
                .then(([success, data]) => {
                    if (success) {

                        // data?.settings?.home_delivery_monday_times?.map((item: any) => {
                        //     return (
                        //         item.start = new Date(`01-01-2000 ${item.start.trim()}:00`), item.end = new Date(`01-01-2000 ${item.end}:00`))
                        // })

                        setFormData(data)
                        let store = data?.stores?.map((item: any) => ({ name: item?.name.replace(/(<([^>]+)>)/ig, ''), value: item?.store_id }))
                        let payments = data?.payments?.map((item: any) => item)
                        setPayments(payments)
                        setStoresOpt(store)
                        setStores(store[0]?.value)
                    } else {
                        setErrorMsg(data)
                    }

                })
                .finally(() => {
                    setLoading(false)
                    setRefresh(false)
                })
        } else if (storeChange) {
            setLoading(true)
            mainStore.getDeliverySettings(Number(stores))
                .then(([success, data]) => {
                    if (success) {
                        setFormData(data)
                        setPayments(payments)

                    } else {
                        setErrorMsg(data)
                    }

                }).finally(() => {
                    setLoading(false)
                    setStoreChange(false)

                })
        }
    }, [refresh, storeChange])

    // const dateToTimeString = (values: any) => {
    //     if (values) {
    //         values?.settings?.home_delivery_monday_times?.map((item: any) => {
    //             return (
    //                 item.start = moment(item.start).format("HH:mm"), item.end = moment(item.end).format("HH:mm"))
    //         })           
    //     }
    // }

    const dataTableFuncMap: any = {
        'products1': setProducts1,
        'products2': setProducts2,
    };

    const onEditorValueChange = (productKey: any, props: any, value: any) => {
        let updatedProducts = [...props.value];
        updatedProducts[props.rowIndex][props.field] = value;
        dataTableFuncMap[`${productKey}`](updatedProducts);
    }

    const inputTextEditor = (productKey: any, props: any, field: any) => {
        return <InputText className="p-invalid p-d-block" type="text" value={props.rowData[field]} onChange={(e: any) => onEditorValueChange(productKey, props, e.target.value)} />;
    }

    const codeEditor = (productKey: any, props: any) => {

        console.log("props", props)
        return inputTextEditor(productKey, props, props.field);
    }

    const deliveryPostcodeOptions = (formik: any) => {
        formik.values.settings.home_delivery_postcode_options.push({
            area: "",
            cartamount: "",
            extra_shippingamount: "",
            extramile_charge: "",
            postcode: "",
            postcode_distance: "",
            shippingamount: ""
        })
    }

    const deliveryOptions = (formik: any) => {
        formik.values.settings.home_delivery_options.push({
            cartamount: "",
            distance: "",
            extra_shippingamount: "",
            shippingamount: ""
        })

    }

    const addTimes = (data: any, formik: any) => {
        switch (data) {
            case 'monday':
                formik.values.settings.home_delivery_monday_times.push({
                    start: "",
                    end: ""
                })
                break;

            case 'tuesday':
                formik.values.settings.home_delivery_tuesday_times.push({
                    start: "",
                    end: ""
                })
                break;

            case 'wednesday':
                formik.values.settings.home_delivery_wednesday_times.push({
                    start: "",
                    end: ""
                })
                break;

            case 'thursday':
                formik.values.settings.home_delivery_thursday_times.push({
                    start: "",
                    end: ""
                })
                break;

            case 'friday':
                formik.values.settings.home_delivery_friday_times.push({
                    start: "",
                    end: ""
                })
                break;

            case 'saturday':
                formik.values.settings.home_delivery_saturday_times.push({
                    start: "",
                    end: ""
                })
                break;

            case 'sunday':
                formik.values.settings.home_delivery_sunday_times.push({
                    start: "",
                    end: ""
                })
                break;
            default:
                return null;
        }
    }

    const actionTemplate = (rowData: any, formik: any) => {
        return <div>
            <FiXCircle size="20px" color="red" onClick={() => { formik?.value?.splice(formik?.rowIndex, 1) }} />
        </div>
    }

    const handleAddingTime = (formik: any, data: any, index: any) => {
        // let count = parseInt("12:00")
        // console.log("count", count)
        // console.log("formik in", formik)
        // console.log("data in", data)
        // console.log("index in", index)
        // if (formik.home_delivery_monday_times.count.start)
        // formik?.setFieldValue(`settings.home_delivery_monday_times.${index}.start`, moment(e?.value).format("HH:mm"))
    }

    return (
        <div className="p-grid">

            <div className="p-col-12 p-md-4 p-lg-6 ">
                <div className='page-heading'>Home Delivery</div>
            </div>

            <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-fluid" >
                <div className="p-grid p-fluid">
                    <div className="p-col-3 p-sm-3 p-md-3 p-lg-2 ">
                        <div>Store</div>
                    </div>
                    <div className="p-col-9 p-sm-9 p-md-8 p-lg-8">
                        <Dropdown value={stores} options={storesOpt} optionLabel="name" onChange={(e) => { setStores(e.value); setStoreChange(true) }} />
                    </div>
                </div>
            </div>

            <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-fluid">
                <div className="p-col">
                    <Checkbox inputId="chkbx" checked={checked} onChange={e => setChecked(e.checked)} />
                    <label htmlFor="chkbx" style={{ padding: '10px' }} >Import Postcodes Forcefully</label>
                </div>
            </div>


            <div className="p-col-12 card">
                {loading ? <div>Loading...</div> :
                    errorMsg ? <div>{errorMsg}</div> :

                        <Formik
                            initialValues={formData}
                            enableReinitialize={true}
                            // validationSchema={yup.object({
                            //     settings: yup.object({
                            //         home_delivery_monday_times:
                            //             yup.array().of(yup.object({ start: yup.string().required('Required') }))
                            //     })
                            // })}
                            onSubmit={(values: any, { setSubmitting }) => {

                                // console.log("values", values)

                                // mainStore.updateDeliverySettings(values)
                                //     .then(([success, data]) => {
                                //         if (success) {
                                //             history.push('/homedelivery')
                                //         }
                                //         else {
                                //             setErrorMsg(data.error)
                                //         }
                                //     })
                            }}
                        >
                            {formik =>
                                <Form>
                                    {/* {console.log("loading", loading)} */}


                                    {/* <Fragment> */}
                                    {/* {console.log("formik", formik?.values)} */}
                                    < TabView >

                                        <TabPanel header="General">

                                            <div className="p-grid p-fluid">
                                                <div className="p-col-12">
                                                    <div className="p-grid">

                                                        <div className="p-col-12">
                                                            <div className="p-grid">
                                                                <div className="p-col-10 p-sm-6 p-md-6 p-lg-4">
                                                                    <label>Cart Amount</label> <br />
                                                                    <label style={{ fontSize: "10px" }}>Include discounted prizes in cart Amount for Delivery</label>
                                                                </div>
                                                                <div className="p-col-2 p-md-6">
                                                                    <Field name='settings.home_other_prizes_include' as={Checkbox} type='checkbox' checked={formik?.values?.settings?.home_other_prizes_include == "1" ? true : false} onChange={(e: any) => { formik.setFieldValue(`settings.home_other_prizes_include`, e?.target?.checked == true ? "1" : "0") }} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12">
                                                            <div className="p-grid">
                                                                <div className="p-col-4 p-md-2">
                                                                    <label>Status</label>
                                                                </div>
                                                                <div className="p-col-6 p-md-3">
                                                                    <Field name='settings.home_status' as={InputSwitch} checked={formik?.values?.settings?.home_status == "1" ? true : false} onChange={(e: any) => { formik.setFieldValue(`settings.home_status`, e?.target?.value == true ? "1" : "0") }} />
                                                                    <label style={{ marginLeft: '20px' }} className='status-label'>{formik?.values?.settings?.home_status == "1" ? <span style={{ color: 'green' }}>Enabled</span> : <span>Disabled</span>}</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12">
                                                            <div className="p-grid">
                                                                <div className="p-col-6 p-md-2">
                                                                    <label>Sort Order</label>
                                                                </div>
                                                                {/* formik?.values?.settings? */}
                                                                <div className="p-col-6 p-md-3">
                                                                    <Field name='settings.home_sort_order' as={InputText} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12">
                                                            <div className="p-grid">
                                                                <div className="p-col-12 p-sm-6 p-md-4">
                                                                    <label>Payment Method</label><br />
                                                                    <label style={{ fontSize: "10px" }}>Selecting allow this delivery available for selected Payments only ,Left Unselected for all Payments</label>
                                                                </div>

                                                                <div className="p-col-12 p-sm-6 p-md-4">
                                                                    <Field name='settings.home_payment_methodes' as={MultiSelect} optionValue='code' options={payments} optionLabel="name" placeholder="Select a payments" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </TabPanel>

                                        <TabPanel header="Area Wise">

                                            <div className="p-grid p-fluid">
                                                <div className="p-col-12">
                                                    <div className="p-grid">

                                                        <div className="p-col-12">
                                                            <div className="p-grid">
                                                                <div className="p-col-12">
                                                                    <Field as={DataTable} value={formik?.values?.settings?.home_delivery_postcode_options} className="p-datatable-customers" editMode="cell" rowHover responsive={true} emptyMessage='No data available' >
                                                                        {/* <Column selectionMode="single" style={{ width: '3em' }} /> */}
                                                                        <Column field="area" header="Area" editor={(props) => codeEditor('products2', props)} />
                                                                        <Column field="postcode" header="Postcode" editor={(props) => codeEditor('products2', props)} />
                                                                        <Column field="postcode_distance" header="Distance" editor={(props) => codeEditor('products2', props)} />
                                                                        <Column field="cartamount" header="Cart Amount" editor={(props) => codeEditor('products2', props)} />
                                                                        <Column field="shippingamount" header="Shipping Rate" editor={(props) => codeEditor('products2', props)} />
                                                                        <Column field="extra_shippingamount" header="Extra Shipping for lesser cart Amoun" editor={(props) => codeEditor('products2', props)} />
                                                                        <Column field="extramile_charge" header="Extra mile Shipping Rate" editor={(props) => codeEditor('products2', props)} />
                                                                        <Column body={actionTemplate} />
                                                                        <Column headerStyle={{ width: '8em', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} />
                                                                    </Field>
                                                                </div>

                                                                <div className="p-col-12">
                                                                    <div className='p-grid p-fluid p-justify-start' style={{ marginTop: '5px' }}>
                                                                        <div style={{ marginLeft: '5px' }}>
                                                                            Add New Condition
                                                                            </div>
                                                                        <div style={{ marginLeft: '10px' }}>
                                                                            <FiPlus name="name" color="red" size="20px" onClick={() => deliveryPostcodeOptions(formik)} />
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </TabPanel>

                                        <TabPanel header="Distance Wise">

                                            <div className="p-grid p-fluid">
                                                <div className="p-col-12">
                                                    <div className="p-grid">

                                                        <div className="p-col-12">
                                                            <div className="p-grid">
                                                                <div className="p-col-12">
                                                                    <Field name="settings.home_delivery_options" as={DataTable} value={formik?.values?.settings?.home_delivery_options} editMode="cell" emptyMessage='No data available' rowHover responsive={true} >
                                                                        {/* <Column selectionMode="multiple" style={{ width: '3em' }} /> */}
                                                                        <Column field="distance" header="Upto(Distance)" editor={(props) => codeEditor('products1', props)} />
                                                                        <Column field="cartamount" header="Cart Amount" editor={(props) => codeEditor('products1', props)} />
                                                                        <Column field="shippingamount" header="Shipping rate" editor={(props) => codeEditor('products1', props)} />
                                                                        <Column field="extra_shippingamount" header="Extra Shipping for lesser cart Amount" editor={(props) => codeEditor('products1', props)} />
                                                                        <Column body={actionTemplate} />
                                                                        <Column headerStyle={{ width: '8em', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} />
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12">
                                                            <div className='p-grid p-fluid p-justify-start' style={{ marginTop: '5px' }}>
                                                                <div style={{ marginLeft: '5px' }}>
                                                                    Add New Condition
                                                                    </div>
                                                                <div style={{ marginLeft: '10px' }}>
                                                                    <FiPlus name="name" color="red" size="20px" onClick={() => deliveryOptions(formik)} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12">
                                                            <div className="p-grid">
                                                                <div className="p-col-10 p-sm-6 p-md-6 p-lg-4">
                                                                    <label style={{ paddingRight: '10px' }}>Extra Mile Shipping Rates</label>
                                                                    <Field as={Checkbox} name='settings.home_extra_shipping_status' type='checkbox' checked={formik?.values?.settings?.home_extra_shipping_status == "1" ? true : false} onChange={(e: any) => { formik.setFieldValue(`settings.home_extra_shipping_status`, e?.target?.checked == true ? "1" : "0") }} />
                                                                </div><br />

                                                                {formik?.values?.settings?.home_extra_shipping_status == "1" &&

                                                                    <div className="p-col-12">
                                                                        <div className="p-grid">
                                                                            <div className="p-col-12 p-sm-4 p-md-4 p-lg-4">
                                                                                <label>Rate</label>
                                                                                <Field name='settings.home_extra_shipping_rate' as={InputText} />
                                                                            </div>
                                                                            <div className="p-col-12 p-sm-4 p-md-4 p-lg-4">
                                                                                <label> Maximum Distance</label>
                                                                                <Field name='settings.home_extra_mile_max_distance' as={InputText} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12">
                                                            <div className="p-grid">
                                                                <div className="p-col-12">
                                                                    <label style={{ color: '#f96f43', fontWeight: 'bold' }}>DELIVERY AREA LIMIT!</label>
                                                                </div>

                                                                <div className="p-col-12" style={{ marginLeft: '20px' }}>
                                                                    <div className="p-grid">

                                                                        <div className="p-col-12">
                                                                            <RadioButton name='settings?.home_delivery_area' onChange={() => formik.setFieldValue(`settings.home_delivery_area`, "1")} checked={formik?.values?.settings?.home_delivery_area == "1"} />
                                                                            <label style={{ paddingLeft: '10px' }}>With in post code area</label><br />
                                                                            <label style={{ fontSize: "10px" }}>Home Delivery with in CM12 postcode limit only</label>
                                                                        </div>

                                                                        <div className="p-col-12">
                                                                            <RadioButton name='settings?.home_delivery_area' onChange={() => formik.setFieldValue(`settings.home_delivery_area`, "2")} checked={formik?.values?.settings?.home_delivery_area == "2"} />
                                                                            <label style={{ paddingLeft: '10px' }}>With in city area</label><br />
                                                                            <label style={{ fontSize: "10px" }}>Home Delivery with in CM city limit only</label>
                                                                        </div>

                                                                        <div className="p-col-12">
                                                                            <RadioButton name='settings?.home_delivery_area' onChange={() => formik.setFieldValue(`settings.home_delivery_area`, "3")} checked={formik?.values?.settings?.home_delivery_area == "3"} />
                                                                            <label style={{ paddingLeft: '10px' }}>Specific Areas</label><br />
                                                                            <label style={{ fontSize: "10px" }}>Enter Post Codes Separated by Pressing ENTER key</label>
                                                                            <div className="p-col-10 p-sm-6 p-md-6 p-lg-4">
                                                                                <Field name='settings.config_specific_postcode_area' as={InputTextarea} disabled={formik?.values?.settings?.home_delivery_area != "3"} rows={3} autoResize />
                                                                            </div>
                                                                        </div>

                                                                        <div className="p-col-12">
                                                                            <RadioButton name='settings?.home_delivery_area' onChange={() => formik.setFieldValue(`settings.home_delivery_area`, "0")} checked={formik?.values?.settings?.home_delivery_area == "0"} />
                                                                            <label style={{ paddingLeft: '10px' }}>No Area Limit</label>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>

                                        </TabPanel>

                                        <TabPanel header="Delivery Times">

                                            <div className="p-grid p-fluid">
                                                <div className="p-col-12">
                                                    <div className="p-grid">

                                                        <div className="p-col-12">
                                                            <div className="p-grid">
                                                                <div className="p-col-10 p-sm-6 p-md-4">
                                                                    <label>Same as default store Time</label> <br />
                                                                </div>

                                                                <div className="p-col-2 p-md-2">
                                                                    <Field as={Checkbox} name='settings.home_delivery_store_default_time' type='checkbox' checked={formik?.values?.settings?.home_delivery_store_default_time == "1" ? true : false} onChange={(e: any) => { formik.setFieldValue(`settings.home_delivery_store_default_time`, e?.target?.checked == true ? "1" : "0") }} />

                                                                </div>

                                                                <div className="p-col-12">
                                                                    <div className="p-grid">
                                                                        <div className="p-col-6 p-sm-4 p-md-4">
                                                                            <label>Home Delivery Time</label> <br />
                                                                        </div>

                                                                        <div className="p-col-6 p-sm-4 p-md-4">
                                                                            <Field name='settings.home_delivery_storetime' as={InputText} />
                                                                        </div>
                                                                    </div>

                                                                </div>



                                                            </div>
                                                        </div>

                                                        <div className="p-col-12">
                                                            <div className="p-grid">

                                                                <div className="p-col-12" style={{ borderBottomWidth: 'thin', borderBottomColor: 'grey', borderBottomStyle: 'dotted' }}>
                                                                    <div className="p-grid">
                                                                        <div className="p-col-12 p-sm-2 p-md-2">
                                                                            <label>Monday</label> <br />
                                                                        </div>

                                                                        {formik?.values?.settings?.home_delivery_monday_times?.map((item: any, index: any) => (
                                                                            <>
                                                                                {index != 0 &&
                                                                                    <div className="p-col-12 p-sm-2 p-md-2" />
                                                                                }

                                                                                <div className="p-col-5 p-sm-4 p-md-4">
                                                                                    <label>Start</label>
                                                                                    <Field as={Calendar} value={item?.start ? new Date(`01-01-2000 ${item?.start}:00`) : ''} onSelect={(e: any) => handleAddingTime(formik?.values?.settings, moment(e?.value).format("HH:mm"), index)} name={`settings.home_delivery_monday_times.${index}.start`} readOnlyInput={true} timeOnly />
                                                                                    <div className='p-col-12'>
                                                                                        <ErrorMessage name={`settings.home_delivery_monday_times.${index}.start`} component={FormErrorMsg} />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="p-col-5 p-sm-4 p-md-4">
                                                                                    <label>End</label> <br />
                                                                                    <Field as={Calendar} value={item?.end ? new Date(`01-01-2000 ${item?.end?.trim()}:00`) : ''} onSelect={(e: any) => { formik?.setFieldValue(`settings.home_delivery_monday_times.${index}.end`, moment(e?.value).format("HH:mm")) }} name={`settings.home_delivery_monday_times.${index}.end`} readOnlyInput={true} timeOnly />
                                                                                </div>
                                                                                <div className="p-col-1 p-sm-2 p-md-2">
                                                                                    {index != 0 &&
                                                                                        <FiXCircle name="name" color="red" size="20px" onClick={() => formik?.values?.settings?.home_delivery_monday_times?.splice(index, 1)} />
                                                                                    }
                                                                                </div>
                                                                            </>

                                                                        ))}
                                                                        {formik?.values?.settings?.home_delivery_monday_times?.length == 0 && addTimes('monday', formik)}

                                                                        <div className="p-col-12">
                                                                            <div className='p-grid p-fluid p-justify-end'>
                                                                                <div style={{ marginLeft: '5px' }}>
                                                                                    Add Time
                                                                            </div>
                                                                                <div style={{ marginLeft: '10px' }}>
                                                                                    <FiPlus name="name" color="red" size="20px" onClick={() => addTimes('monday', formik)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div className="p-col-12" style={{ borderBottomWidth: 'thin', borderBottomColor: 'grey', borderBottomStyle: 'dotted' }}>
                                                                    <div className="p-grid">
                                                                        <div className="p-col-12 p-sm-2 p-md-2">
                                                                            <label>Tuesday</label> <br />
                                                                        </div>
                                                                        {formik?.values?.settings?.home_delivery_tuesday_times?.map((item: any, index: any) => (
                                                                            <>
                                                                                {index != 0 &&
                                                                                    <div className="p-col-12 p-sm-2 p-md-2" />
                                                                                }

                                                                                <div className="p-col-5 p-sm-4 p-md-4">
                                                                                    <label>Start</label>
                                                                                    <Field as={Calendar} value={item?.start ? new Date(`01-01-2000 ${item?.start?.trim()}:00`) : ''} onSelect={(e: any) => { formik?.setFieldValue(`settings.home_delivery_tuesday_times.${index}.start`, moment(e?.value).format("HH:mm")) }} name={`settings.home_delivery_tuesday_times.${index}.start`} readOnlyInput={true} timeOnly />

                                                                                </div>
                                                                                <div className="p-col-5 p-sm-4 p-md-4">
                                                                                    <label>End</label> <br />
                                                                                    <Field as={Calendar} value={item?.end ? new Date(`01-01-2000 ${item?.end?.trim()}:00`) : ''} onSelect={(e: any) => { formik?.setFieldValue(`settings.home_delivery_tuesday_times.${index}.end`, moment(e?.value).format("HH:mm")) }} name={`settings.home_delivery_tuesday_times.${index}.end`} readOnlyInput={true} timeOnly />
                                                                                </div>
                                                                                <div className="p-col-1 p-sm-2 p-md-2">
                                                                                    {index != 0 &&
                                                                                        <FiXCircle name="name" color="red" size="20px" onClick={() => formik?.values?.settings?.home_delivery_tuesday_times?.splice(index, 1)} />
                                                                                    }
                                                                                </div>
                                                                            </>

                                                                        ))}

                                                                        <div className="p-col-12">
                                                                            <div className='p-grid p-fluid p-justify-end'>
                                                                                <div style={{ marginLeft: '5px' }}>
                                                                                    Add Time
                                                                            </div>
                                                                                <div style={{ marginLeft: '10px' }}>
                                                                                    <FiPlus name="name" color="red" size="20px" onClick={() => addTimes('tuesday', formik)} />

                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                                {/* {console.log("day", formik?.values?.settings?.settings?.home_delivery_wednesday_times)} */}



                                                                <div className="p-col-12" style={{ borderBottomWidth: 'thin', borderBottomColor: 'grey', borderBottomStyle: 'dotted' }}>
                                                                    <div className="p-grid">
                                                                        <div className="p-col-12 p-sm-2 p-md-2">
                                                                            <label>Wednesday</label> <br />
                                                                        </div>

                                                                        {formik?.values?.settings?.home_delivery_wednesday_times?.map((item: any, index: any) => (

                                                                            <>
                                                                                {index != 0 &&
                                                                                    <div className="p-col-12 p-sm-2 p-md-2" />
                                                                                }

                                                                                <div className="p-col-5 p-sm-4 p-md-4">
                                                                                    <label>Start</label>
                                                                                    <Field as={Calendar} value={item?.start ? new Date(`01-01-2000 ${item?.start?.trim()}:00`) : ''} onSelect={(e: any) => { formik?.setFieldValue(`settings.home_delivery_wednesday_times.${index}.start`, moment(e?.value).format("HH:mm")) }} name={`settings.home_delivery_wednesday_times.${index}.start`} readOnlyInput={true} timeOnly />
                                                                                </div>
                                                                                <div className="p-col-5 p-sm-4 p-md-4">
                                                                                    <label>End</label> <br />
                                                                                    <Field as={Calendar} value={item?.end ? new Date(`01-01-2000 ${item?.end?.trim()}:00`) : ''} onSelect={(e: any) => { formik?.setFieldValue(`settings.home_delivery_wednesday_times.${index}.end`, moment(e?.value).format("HH:mm")) }} name={`settings.home_delivery_wednesday_times.${index}.end`} readOnlyInput={true} timeOnly />
                                                                                </div>
                                                                                <div className="p-col-1 p-sm-2 p-md-2">
                                                                                    {index != 0 &&
                                                                                        <FiXCircle name="name" color="red" size="20px" onClick={() => formik?.values?.settings?.home_delivery_wednesday_times?.splice(index, 1)} />
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        ))}


                                                                        <div className="p-col-12">
                                                                            <div className='p-grid p-fluid p-justify-end'>
                                                                                <div style={{ marginLeft: '5px' }}>
                                                                                    Add Time
                                                                            </div>
                                                                                <div style={{ marginLeft: '10px' }}>
                                                                                    <FiPlus name="name" color="red" size="20px" onClick={() => addTimes('wednesday', formik)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>


                                                                <div className="p-col-12" style={{ borderBottomWidth: 'thin', borderBottomColor: 'grey', borderBottomStyle: 'dotted' }}>
                                                                    <div className="p-grid">
                                                                        <div className="p-col-12 p-sm-2 p-md-2">
                                                                            <label>Thursday</label> <br />
                                                                        </div>
                                                                        {formik?.values?.settings?.home_delivery_thursday_times?.map((item: any, index: any) => (
                                                                            <>
                                                                                {index != 0 &&
                                                                                    <div className="p-col-12 p-sm-2 p-md-2" />
                                                                                }

                                                                                <div className="p-col-5 p-sm-4 p-md-4">
                                                                                    <label>Start</label>
                                                                                    <Field as={Calendar} value={item?.start ? new Date(`01-01-2000 ${item?.start?.trim()}:00`) : ''} onSelect={(e: any) => { formik?.setFieldValue(`settings.home_delivery_thursday_times.${index}.start`, moment(e?.value).format("HH:mm")) }} name={`settings.home_delivery_thursday_times.${index}.start`} readOnlyInput={true} timeOnly />
                                                                                </div>
                                                                                <div className="p-col-5 p-sm-4 p-md-4">
                                                                                    <label>End</label> <br />
                                                                                    <Field as={Calendar} value={item?.end ? new Date(`01-01-2000 ${item?.end?.trim()}:00`) : ''} onSelect={(e: any) => { formik?.setFieldValue(`settings.home_delivery_thursday_times.${index}.end`, moment(e?.value).format("HH:mm")) }} name={`settings.home_delivery_thursday_times.${index}.end`} readOnlyInput={true} timeOnly />
                                                                                </div>
                                                                                <div className="p-col-1 p-sm-2 p-md-2">
                                                                                    {index != 0 &&
                                                                                        <FiXCircle name="name" color="red" size="20px" onClick={() => formik?.values?.settings?.home_delivery_thursday_times?.splice(index, 1)} />
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        ))}

                                                                        <div className="p-col-12">
                                                                            <div className='p-grid p-fluid p-justify-end'>
                                                                                <div style={{ marginLeft: '5px' }}>
                                                                                    Add Time
                                                                            </div>
                                                                                <div style={{ marginLeft: '10px' }}>
                                                                                    <FiPlus name="name" color="red" size="20px" onClick={() => addTimes('thursday', formik)} />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="p-col-12" style={{ borderBottomWidth: 'thin', borderBottomColor: 'grey', borderBottomStyle: 'dotted' }}>
                                                                    <div className="p-grid">
                                                                        <div className="p-col-12 p-sm-2 p-md-2">
                                                                            <label>Friday</label> <br />
                                                                        </div>

                                                                        {formik?.values?.settings?.home_delivery_friday_times?.map((item: any, index: any) => (

                                                                            <>
                                                                                {index != 0 &&
                                                                                    <div className="p-col-12 p-sm-2 p-md-2" />
                                                                                }

                                                                                <div className="p-col-5 p-sm-4 p-md-4">
                                                                                    <label>Start</label>
                                                                                    <Field as={Calendar} value={item?.start ? new Date(`01-01-2000 ${item?.start?.trim()}:00`) : ''} onSelect={(e: any) => { formik?.setFieldValue(`settings.home_delivery_friday_times.${index}.start`, moment(e?.value).format("HH:mm")) }} name={`settings.home_delivery_friday_times.${index}.start`} readOnlyInput={true} timeOnly />
                                                                                </div>
                                                                                <div className="p-col-5 p-sm-4 p-md-4">
                                                                                    <label>End</label> <br />
                                                                                    <Field as={Calendar} value={item?.end ? new Date(`01-01-2000 ${item?.end?.trim()}:00`) : ''} onSelect={(e: any) => { formik?.setFieldValue(`settings.home_delivery_friday_times.${index}.end`, moment(e?.value).format("HH:mm")) }} name={`settings.home_delivery_friday_times.${index}.end`} readOnlyInput={true} timeOnly />
                                                                                </div>
                                                                                <div className="p-col-1 p-sm-2 p-md-2">
                                                                                    {index != 0 &&
                                                                                        <FiXCircle name="name" color="red" size="20px" onClick={() => formik?.values?.settings?.home_delivery_friday_times?.splice(index, 1)} />
                                                                                    }
                                                                                </div>

                                                                            </>
                                                                        ))}

                                                                        <div className="p-col-12">
                                                                            <div className='p-grid p-fluid p-justify-end'>
                                                                                <div style={{ marginLeft: '5px' }}>
                                                                                    Add Time
                                                                            </div>
                                                                                <div style={{ marginLeft: '10px' }}>
                                                                                    <FiPlus name="name" color="red" size="20px" onClick={() => addTimes('friday', formik)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>

                                                                <div className="p-col-12" style={{ borderBottomWidth: 'thin', borderBottomColor: 'grey', borderBottomStyle: 'dotted' }}>
                                                                    <div className="p-grid">
                                                                        <div className="p-col-12 p-sm-2 p-md-2">
                                                                            <label>Saturday</label> <br />
                                                                        </div>

                                                                        {formik?.values?.settings?.home_delivery_saturday_times?.map((item: any, index: any) => (

                                                                            <>
                                                                                {index != 0 &&
                                                                                    <div className="p-col-12 p-sm-2 p-md-2" />
                                                                                }

                                                                                <div className="p-col-5 p-sm-4 p-md-4">
                                                                                    <label>Start</label>
                                                                                    <Field as={Calendar} value={item?.start ? new Date(`01-01-2000 ${item?.start?.trim()}:00`) : ''} onSelect={(e: any) => { formik?.setFieldValue(`settings.home_delivery_saturday_times.${index}.start`, moment(e?.value).format("HH:mm")) }} name={`settings.home_delivery_saturday_times.${index}.start`} readOnlyInput={true} timeOnly />
                                                                                </div>
                                                                                <div className="p-col-5 p-sm-4 p-md-4">
                                                                                    <label>End</label> <br />
                                                                                    <Field as={Calendar} value={item?.end ? new Date(`01-01-2000 ${item?.end?.trim()}:00`) : ''} onSelect={(e: any) => { formik?.setFieldValue(`settings.home_delivery_saturday_times.${index}.end`, moment(e?.value).format("HH:mm")) }} name={`settings.home_delivery_saturday_times.${index}.end`} readOnlyInput={true} timeOnly />
                                                                                </div>
                                                                                <div className="p-col-1 p-sm-2 p-md-2">
                                                                                    {index != 0 &&
                                                                                        <FiXCircle name="name" color="red" size="20px" onClick={() => formik?.values?.settings?.home_delivery_saturday_times?.splice(index, 1)} />
                                                                                    }
                                                                                </div>

                                                                            </>
                                                                        ))}

                                                                        <div className="p-col-12">
                                                                            <div className='p-grid p-fluid p-justify-end'>
                                                                                <div style={{ marginLeft: '5px' }}>
                                                                                    Add Time
                                                                            </div>
                                                                                <div style={{ marginLeft: '10px' }}>
                                                                                    <FiPlus name="name" color="red" size="20px" onClick={() => addTimes('saturday', formik)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div className="p-col-12" >
                                                                    <div className="p-grid">
                                                                        <div className="p-col-12 p-sm-2 p-md-2">
                                                                            <label>Sunday</label> <br />
                                                                        </div>

                                                                        {formik?.values?.settings?.home_delivery_sunday_times?.map((item: any, index: any) => (

                                                                            <>
                                                                                {index != 0 &&
                                                                                    <div className="p-col-12 p-sm-2 p-md-2" />
                                                                                }

                                                                                <div className="p-col-5 p-sm-4 p-md-4">
                                                                                    <label>Start</label>
                                                                                    <Field as={Calendar} value={item?.start ? new Date(`01-01-2000 ${item?.start?.trim()}:00`) : ''} onSelect={(e: any) => { formik?.setFieldValue(`settings.home_delivery_sunday_times.${index}.start`, moment(e?.value).format("HH:mm")) }} name={`settings.home_delivery_sunday_times.${index}.start`} readOnlyInput={true} timeOnly />
                                                                                </div>
                                                                                <div className="p-col-5 p-sm-4 p-md-4">
                                                                                    <label>End</label> <br />
                                                                                    <Field as={Calendar} value={item?.end ? new Date(`01-01-2000 ${item?.end?.trim()}:00`) : ''} onSelect={(e: any) => { formik?.setFieldValue(`settings.home_delivery_sunday_times.${index}.end`, moment(e?.value).format("HH:mm")) }} name={`settings.home_delivery_sunday_times.${index}.end`} readOnlyInput={true} timeOnly />
                                                                                </div>
                                                                                <div className="p-col-1 p-sm-2 p-md-2">
                                                                                    {index != 0 &&
                                                                                        <FiXCircle name="name" color="red" size="20px" onClick={() => formik?.values?.settings?.home_delivery_sunday_times?.splice(index, 1)} />
                                                                                    }
                                                                                </div>

                                                                            </>
                                                                        ))}

                                                                        <div className="p-col-12">
                                                                            <div className='p-grid p-fluid p-justify-end'>
                                                                                <div style={{ marginLeft: '5px' }}>
                                                                                    Add Time
                                                                            </div>
                                                                                <div style={{ marginLeft: '10px' }}>
                                                                                    <FiPlus name="name" color="red" size="20px" onClick={() => addTimes('sunday', formik)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="p-col-12">
                                                            <div className="p-grid">
                                                                <div className="p-col-2 right">
                                                                    <Button type='submit' label="Save" />
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </TabPanel>
                                    </TabView>
                                    {/* </Fragment> */}

                                </Form>
                            }

                        </Formik>
                }

            </div>

        </div >
    )
})