import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { ListBox } from 'primereact/listbox';
import React, { useEffect, useState } from 'react';
import usePrevious from 'react-hooks-use-previous';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useStore } from '../stores/useStore';

export const BasicOrderDetails = observer((props: any) => {
    const { mainStore } = useStore()
    const [selectedDate, setSelectedDate] = useState<any>();
    const prevValue = usePrevious<number>(selectedDate, selectedDate);
    const [data, setData] = useState<any>()
    const [loading, setLoading] = useState(true)
    const [errorMsg, setErrorMsg] = useState(null)
    const [activeIndex, setActiveIndex] = useState(0);
    const [products, setProducts] = useState<any>();
    const [allOrderStatus, setAllOrderStatus] = useState<any>([]);
    const [formData, setFormData] = useState({});
    const [refresh, setRefresh] = useState(false)
    const [productTotal, setProductTotal] = useState([]);
    const [orderHistory, setOrderHistory] = useState<any>([]);
    const [selectedStatus, setSelectedStatus] = useState<any>();
    const [checked, setChecked] = useState<any>(false);
    const [text, setText] = useState<string>();
    const [updateData, setUpdateData] = useState(false);
    const { id } = useParams<any>();
    const history = useHistory();
    const [visible, setVisible] = useState(false)
    const [rejectReason, setRejectReason] = useState<any>();

    useEffect(() => {
        mainStore.getOrder(Number(id))
            .then(([success, data]) => {
                if (success) {
                    handleStatusChangedBadge(data?.order_id, data?.order_status)
                    setData(data)
                    setFormData(data)
                    const products = data?.products?.map((item: any) => item)
                    const productTotal = data?.totals
                    setProductTotal(productTotal)
                    var status = data?.order_status_id
                    setSelectedStatus(status)
                    setProducts(products)
                    let delivery_time = data.delivery_time
                    if (delivery_time === 'AS SOON AS POSSIBLE') {
                        setSelectedDate(moment().format('YYYY-MM-DD HH:mm:ss'))
                    }
                    else {
                        setSelectedDate(delivery_time)
                    }
                } else {
                    setErrorMsg(data)
                }
            })

            .then(() => {
                mainStore.getStatus()
                    .then(([success, data]) => {
                        if (success) {
                            let allOrderStatus = data.data.all_status.map((item: any) => ({ id: item.id, name: item.name }))
                            setAllOrderStatus(allOrderStatus)
                        }
                        else {
                            setErrorMsg(data)
                        }
                    })
            })

            .then(() => {
                mainStore.getOrderHistory(Number(id))
                    .then(([success, data]) => {
                        if (success) {
                            const orderHistory = data.data.map((item: any) => item)
                            setOrderHistory(orderHistory)
                        }
                        else {
                            setErrorMsg(data)
                        }
                    })
            })

            .finally(() => {
                setLoading(false)
                setRefresh(false)
            })

    }, [refresh])

    const rejectReasons = [
        { label: 'Reject', value: 'Reject' },
        { label: 'Too Busy', value: 'Too Busy' },
        { label: 'Food unavailable', value: 'Food unavailable' },
        { label: 'Unable to deliver', value: 'Unable to deliver' },
        { label: 'Dont deliver to area', value: 'Dont deliver to area' },
        { label: 'Unknown address', value: 'Unknown address' },
        { label: 'Time unavailable', value: 'Time unavailable' },
        { label: 'Jam-please reorder', value: 'Jam-please reorder' }

    ];


    function updateCustomerDetails() {
        let notify = checked === true ? 1 : 0
        let comment = (text === undefined) || (text === null) ? "" : text
        let dateValue = selectedDate
        if ((dateValue == prevValue) || (prevValue == undefined)) {
            mainStore.notUpdateOrderTime(id, selectedStatus, comment, notify,)
                .then(([success, res]) => {
                    if (success) {
                        history.push('/orderlist')

                    }
                    else {
                        setErrorMsg(res)
                    }
                })
        } else {
            mainStore.updateOrderTime(id, selectedStatus, comment, notify, dateValue)
                .then(([success, res]) => {
                    if (success) {
                        history.push('/orderlist')

                    }
                    else {
                        setErrorMsg(res)
                    }
                })
        }
    }

    function acceptAndReject(status: any, reason: any) {
        var newOrderList: any = [];
        let newOrderID = localStorage.getItem('newOrderList')
        if (typeof newOrderID === 'string') {
            newOrderList = JSON.parse(newOrderID);
        }
        let notify = checked === true ? 1 : 0
        let comment = (text === undefined) || (text === null) ? "" : text
        let dateValue = selectedDate

        if ((dateValue == prevValue) || (prevValue == undefined)) {
            mainStore.acceptOrderIdTimeNotUpdated(id, selectedStatus, comment + reason, notify, status)
                .then(([success, res]) => {
                    if (success) {
                        newOrderList.splice(newOrderList.indexOf(id), 1)
                        localStorage.setItem('newOrderList', JSON.stringify(newOrderList))
                        history.push('/orderlist')
                    }
                    else {
                        setErrorMsg(res)
                    }
                })
        }
        else {
            // timeupdated

            mainStore.acceptOrderIdTimeUpdated(id, selectedStatus, comment + reason, notify, dateValue, status)
                .then(([success, res]) => {
                    if (success) {
                        newOrderList.splice(newOrderList.indexOf(id), 1)
                        localStorage.setItem('newOrderList', JSON.stringify(newOrderList))
                        history.push('/orderlist')
                    }
                    else {
                        setErrorMsg(res)
                    }
                })
        }

    }

    const productTemplate = (rowData: any) => {
        return <>
            <Link to={`/productform/edit/${rowData.product_id}`} onClick={() => { mainStore.stopLoading = false; mainStore.isCategories = false; mainStore.isDressing = false; mainStore.isTopping = false; mainStore.isApiCall = true; mainStore.isOption = false }} >{rowData.name}</Link>
            <p>{rowData.option.map((item: any) => <li> {item.name + "  " + ":" + "  " + item.value}</li>)}</p>
        </>
    }

    const handleStatusChangedBadge = (order_id: any, status: any) => {
        let orders_id: any = []
        if (localStorage.getItem('orders_id')) {
            orders_id = JSON.parse(localStorage.getItem('orders_id') || '')
            if (orders_id.every((item: any) => item !== order_id) && status != 'Pending') {
                orders_id.push(order_id)
                localStorage.setItem('orders_id', JSON.stringify(orders_id))
            }

        } else if (status !== 'Pending') {
            localStorage.setItem('orders_id', JSON.stringify([order_id]))
        }
    }

    const handleBadge = (data: any) => {
        let orders_id: any = []
        if (localStorage.getItem('orders_id')) {
            orders_id = JSON.parse(localStorage.getItem('orders_id') || '')
            if (orders_id.every((item: any) => item !== data)) {
                orders_id.push(data)
                localStorage.setItem('orders_id', JSON.stringify(orders_id))
            }

        } else {
            localStorage.setItem('orders_id', JSON.stringify([data]))
        }
    }

    var newOrderList: any = []
    let newOrderID = localStorage.getItem('orders_id')
    if (typeof newOrderID === 'string') {
        newOrderList = JSON.parse(newOrderID);
    }

    var newTableOrderList: any = []
    let newTableOrderID = localStorage.getItem('orders_id')
    if (typeof newTableOrderID === 'string') {
        newTableOrderList = JSON.parse(newTableOrderID);
    }

    return (

        <div className="p-grid dashboard">
            {loading ? <div className="p-col-12">Loading...</div> :
                errorMsg ? <div className='p-col-12 error-msg'>{errorMsg}</div> :

                    <>
                        <div className="p-col-12">
                            <div className="p-grid">
                                <div className="p-col">
                                    <div className='page-heading'>
                                        <i className="pi pi-arrow-left" style={{ 'fontSize': '1em' }} onClick={() => history.goBack()}></i>
                                        <div className='inline-spacer' />#{data.order_id}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-md-6">
                            {/* <Formik
                                initialValues={formData}
                                enableReinitialize={true}
                                validationSchema={yup.object({
                                    order_status_id: yup.number(),
                                    notify: yup.boolean(),
                                    comment: yup.string(),
                                    delivery_time: yup.date()
                                })}
                                onSubmit={(values: any, { setSubmitting }) => {
                                    // values.notify = values.notify === true ? "1" : "0"
                                    // values.order_id = data.order_id
                                    // values.loyalty_card = data.loyalty_card
                                    // values.total_loyalty_points = data.loyalty_points_redeemed
                                    mainStore.updateOrder(values)
                                        .then(([success, data]) => {
                                            if (success) {
                                                setRefresh(true)
                                            } else {
                                                setErrorMsg(data)
                                            }
                                        })
                                }}
                            >
                                {formik =>
                                    <Form> */}
                            <Card style={{ height: '100%' }}>

                                <div className='p-grid'>

                                    <div className="p-col-12">
                                        <div className="p-grid p-justify-between">
                                            <div className="p-col">
                                                <div className='title'>Order Details</div>
                                            </div>

                                            <div className="p-col right" >
                                                <Link to={`/orderdetails/${data.order_id}`}> All Details</Link>
                                            </ div>
                                        </div>
                                    </div>
                                    <div className='p-col-12'>
                                        <div className='p-grid p-fluid form-control-group'>
                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                <label>Customer</label>
                                            </div>
                                            <div className='p-col-8'>
                                                <label>{data?.shipping_firstname}{data?.shipping_lastname}</label>
                                            </div>

                                        </div>
                                    </div>

                                    <div className='p-col-12'>
                                        <div className='p-grid p-fluid form-control-group'>
                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                <label>Address 1</label>
                                            </div>
                                            <div className='p-col-8'>
                                                <label>{data?.shipping_address_1}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='p-col-12'>
                                        <div className='p-grid p-fluid form-control-group'>
                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                <label>Address 2</label>
                                            </div>
                                            <div className='p-col-8'>
                                                <label>{data?.shipping_address_2}</label>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='p-col-12'>
                                        <div className='p-grid p-fluid form-control-group'>
                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                <label>Post Code</label>
                                            </div>
                                            <div className='p-col-8'>
                                                <label>{data?.shipping_postcode}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='p-col-12'>
                                        <div className='p-grid p-fluid form-control-group'>
                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                <label>Country</label>
                                            </div>
                                            <div className='p-col-8'>
                                                <label>{data?.shipping_country}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='p-col-12'>
                                        <div className='p-grid p-fluid form-control-group'>
                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                <label>Telephone</label>
                                            </div>
                                            <div className='p-col-4'>
                                                <label>{data?.telephone}</label></div>
                                            <div className='p-col-2'>
                                                <a href={`tel:+${data?.telephone}`}> <Button label="Call" className="p-button-raised p-button-rounded" style={{ height: "30px", width: "50px" }} /></a>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='p-col-12'>
                                        <div className='p-grid p-fluid form-control-group'>
                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                <label>Comment</label>
                                            </div>
                                            <div className='p-col-8'>
                                                <label>{data.comment}</label>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='p-col-12'>
                                        <div className='p-grid p-fluid form-control-group'>
                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                <label>Requested Time</label>
                                            </div>
                                            <div className='p-col-8'>
                                                <label>{data.requested_time === "AS SOON AS POSSIBLE" ? 'ASAP' : data?.requested_time?.split(" ").slice(1)}</label>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='p-col-12'>
                                        <div className='p-grid p-fluid form-control-group'>
                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                <label>Notify</label>
                                            </div>
                                            <div className='p-col-8'>
                                                {/* <Field name="notify" type='checkbox' as={Checkbox} /> */}
                                                <Checkbox onChange={(e: any) => setChecked(e.checked)} checked={checked}></Checkbox>
                                            </div>

                                        </div>
                                    </div>
                                    {!newOrderList.includes(data.order_id) ?
                                        <div className='p-col-12'>
                                            <div className='p-grid p-fluid form-control-group'>
                                                <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                    {data?.shipping_code == "collect.collect" ?

                                                        <label>Change Collection Time</label> :
                                                        <label>Change Delivery Time</label>}
                                                </div>
                                                <div className='p-col-8'>
                                                    <Calendar name="delivery_time" value={selectedDate} onChange={(e: any) => setSelectedDate(e.value)} timeOnly hourFormat="24" />
                                                    {/* <Field name="delivery_time" value={selectedDate}
                                                                onSelect={(event: any) => {
                                                                    setSelectedDate(moment(event.target).format('YYYY-MM-DD H:mm:ss'))
                                                                }} as={Calendar} timeOnly hourFormat="24" /> */}

                                                </div>

                                            </div>
                                        </div> : ""}
                                    {newOrderList.includes(data.order_id) ?
                                        <div className='p-col-12'>
                                            <div className='p-grid p-fluid form-control-group'>
                                                <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                    <label>Change Status</label>
                                                </div>
                                                <div className='p-col-8'>
                                                    {/* <Field name="order_status_id" as={Dropdown} optionLabel="name" optionValue="id" options={allOrderStatus} /> */}
                                                    <Dropdown name="order_status_id" value={selectedStatus} options={allOrderStatus} onChange={(e) => setSelectedStatus(e.value)} optionValue="id" optionLabel="name" />

                                                </div>

                                            </div>
                                        </div> : ""}
                                    <div className='p-col-12'>
                                        <div className='p-grid p-fluid form-control-group'>
                                            <div className='p-col-4' style={{ color: '#252529c9' }}>
                                                <label>Comments Here</label>
                                            </div>
                                            <div className='p-col-8'>
                                                <InputTextarea value={text} onChange={(e: any) => setText(e.target.value)} />
                                                {/* <Field name="comment" as={InputTextarea} /> */}
                                            </div>

                                        </div>
                                    </div>
                                    {!newOrderList.includes(data.order_id) || !newTableOrderList.includes(data.order_id) ?
                                        <div className='p-col-12'>
                                            <div className='p-grid p-fluid form-control-group'>
                                                <div className='p-col-6'>
                                                    <Button label="Reject" onClick={() => { setVisible(true); handleBadge(data.order_id) }} className="p-button-warning" />
                                                </div>
                                                <div className='p-col-6' >
                                                    <Button label="Accept" type='submit' onClick={() => { acceptAndReject('Accepted', ''); handleBadge(data.order_id) }} className="p-button-primary" />
                                                </div>



                                            </div>
                                            <Dialog header="Reason for Rejection" visible={visible} onHide={() => setVisible(false)} style={{ position: 'center', width: '25vw' }} >
                                                <ListBox value={rejectReason} options={rejectReasons} onChange={(e: any) => setRejectReason(e.value)} style={{ width: "100%" }} />
                                                <Button label="Reject" onClick={() => { acceptAndReject('Rejected', rejectReason); }}
                                                    style={{ margin: "5px", float: "right" }} className="p-button-primary" />
                                            </Dialog>
                                        </div> :
                                        <div className='p-col-12'>
                                            <div className='p-grid p-fluid form-control-group'>
                                                <div className='p-col-12 p-md-4' >
                                                    <Button label="Update" type='submit'
                                                        onClick={() => {
                                                            updateCustomerDetails()
                                                        }} className="p-button-primary" />
                                                </div>
                                            </div>
                                        </div>
                                    }


                                </div>
                            </Card>
                            {/* </Form>}
                            </Formik> */}

                        </div>
                        <div className="p-col-12 p-md-6">
                            <Card style={{ height: '100%' }}>
                                <div className='p-grid'>
                                    <div className='p-col-12'>
                                        <div className='title'>Product Details</div>
                                    </div>
                                    <div className='p-col-12'>
                                        <DataTable value={products} responsive={true} emptyMessage='No Products available' >
                                            <Column field="name" header="Product" body={productTemplate} style={{ width: '50%' }} />
                                            <Column field="quantity" header="Quantity" />
                                            <Column field="price" header="Unit Price" style={{ textAlign: 'right' }} />
                                            <Column field="total" header="Total" style={{ textAlign: 'right' }} />
                                        </DataTable>
                                        {productTotal?.map((item: any) => <div className='p-grid p-justify-end' style={{ margin: '20px' }}>{item.title}    :<b>{item.text}</b></div>)}

                                    </div>
                                </div>
                            </Card>
                        </div>

                    </>

            }
        </div >
    )
})


