import { ErrorMessage, Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { useStore } from '../stores/useStore';
import { Paginator } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { MainStore } from '../stores/MainStore';

const FormErrorMsg = styled.span`
 color: red;
 display: 'inline-block';
`
const ProductList = observer((props: any) => {
    const { mainStore } = useStore()
    const [loading, setLoading] = useState(true)
    const [errorMsg, setErrorMsg] = useState<any>(null)
    const [refresh, setRefresh] = useState(true)
    const [data, setData] = useState<any>([])
    const [filter, setFilter] = useState('')
    const [filteredData, setFilteredData] = useState<any>([])
    const [first, setFirst] = useState(0)
    const [searchBy, setSearchBy] = useState<any>('pdtName')

    useEffect(() => {
        mainStore.isApiCall = true
        if (refresh) {
            setLoading(true)
            mainStore.getProducts()
                .then(([success, data]) => {
                    if (success) {
                        setData(data.products)
                        setFilteredData(data.products)
                    } else {
                        setErrorMsg(data)
                    }
                })
                .finally(() => {
                    setLoading(false)
                    setRefresh(false)
                })
        }
    }, [refresh])

    useEffect(() => {
        if (filter) {
            let result;
            if (searchBy == 'pdtName') {
                result = data?.filter((item: any) => item.name.toLowerCase().includes(filter.toLowerCase()))
            } else {
                result = data?.filter((item: any) => item.ec_product_id.toLowerCase().includes(filter.toLowerCase()))
            }

            if (result?.length == 0) {
                setErrorMsg('No products found, try different search keyword')
            } else {
                setErrorMsg(null)
                setFilteredData(result)
            }
        } else {
            setFilteredData(data)
        }
    }, [filter, refresh, searchBy])

    const searchOpt = [
        { label: 'Product Name', value: 'pdtName' },
        { label: 'Product Id for API', value: 'apiId' },

    ];

    return (
        <div className="p-grid product-list">
            <div className="p-col-12">
                <div className="p-grid p-justify-between">
                    <div className="p-col-12 p-md-3">
                        <div className='page-heading'>Products</div>
                    </div>
                    <div className="p-col-4 p-md-3 p-fluid">
                        <InputText placeholder='Search' name='search' onChange={(e: any) => setFilter(e.target.value)} />
                    </div>
                    <div className="p-col-6 p-md-3 p-fluid">
                        <div className="p-grid">
                            <div style={{ marginTop: "8px" }} className="p-col-4 p-md-4">
                                <label >Search by</label>
                            </div>
                            <div className="p-col-8 p-md-8">
                                <Dropdown value={searchBy} options={searchOpt} onChange={(e) => setSearchBy(e.value)} placeholder="Search By" />
                            </div>
                        </div>

                    </div>

                    <div className="p-col-2 right" >
                        <Link to="/productform/new">
                            <Button label="New" onClick={() => { mainStore.isApiCall = true; mainStore.stopLoading = false; mainStore.isCategories = false; mainStore.isDressing = false; mainStore.isTopping = false }} className="p-button-primary" />
                        </Link>
                    </div>
                </div>
            </div>
            {loading ? <div className="p-col-12">Loading...</div> :
                errorMsg ? <div className='p-col-12 error-msg'>{errorMsg}</div> :
                    <div className="p-col-12">
                        {filteredData.slice(first, first + 10).map((item: any, index: number) => (
                            <ProductListItem key={index} data={item} mainStore={mainStore} setRefresh={setRefresh} />
                        ))}
                        <Paginator totalRecords={filteredData.length} rows={10} first={first} onPageChange={(e) => setFirst(e.first)} />
                    </div>
            }
        </div>
    );
})

const ProductListItem = observer((props: any) => {
    const myRef = useRef(null)
    const { mainStore } = useStore()
    const [editMode, setEditMode] = useState(false)
    const [deleteConfirm, setDeleteConfirm] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)
    const [quickData, setQuickData] = useState<any>(null)


    useEffect(() => {
        let formData = {
            product_id: props.data.product_id,
            price: props.data.price,
            model: props.data.model,
            product_description: { 1: { name: props.data.name, shortcode: props.data.shortcode } },
            status: props.data.status,
            deal: props.data.deal,
            ec_product_id: props.data.ec_product_id
        }
        setQuickData(formData)
    }, [props.data])

    function deleteProduct(id: number) {
        props.mainStore.deleteProduct({ product_id: [id] })
            .then((res: any) => {
                if (res[0]) {
                    props.setRefresh(true)
                } else {
                    setErrorMsg(res[1])
                }
            })
    }

    return (
        <Formik
            initialValues={quickData}
            enableReinitialize={true}
            validationSchema={yup.object({
                product_description: yup.object({ 1: yup.object({ name: yup.string().required('Required') }) }),
                price: yup.number().required('Required')
            })}
            onSubmit={(values: any, { setSubmitting }) => {
                props.mainStore.quickProductUpdate(values)
                    .then((res: any) => {
                        if (res[0]) {
                            setEditMode(!editMode)
                            setQuickData(values)
                        } else {
                            setErrorMsg(res[1])
                        }
                    })
            }}>
            {formik =>
                <Form>
                    <Card>
                        <div ref={myRef} className='p-grid p-align-center'>
                            {errorMsg ? <div className='p-col-12 error-msg'>{errorMsg}</div> :
                                <>
                                    <div className='p-col-12 p-md-9'>
                                        {editMode ? (
                                            <div className='p-grid'>
                                                <div className='p-col-12 p-md-2 p-fluid'>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Name</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='product_description.1.name' as={InputText} />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='product_description.1.name' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* {quickData?.deal > 0 ? <div></div> : */}
                                                <div className='p-col-12 p-md-2 p-fluid'>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Product Id for API</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='ec_product_id' as={InputText} />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='ec_product_id' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* } */}

                                                <div className='p-col-12 p-md-2 p-fluid'>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Short Code</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='product_description.1.shortcode' as={InputText} />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='product_description.1.shortcode' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='p-col-12 p-md-3 p-fluid'>
                                                    <div className='p-grid  form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Product Code</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='model' as={InputText} />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='model' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='p-col-12 p-md-2 p-fluid'>
                                                    <div className='p-grid  form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Price</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <Field name='price' as={InputText} className='right' />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='price' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='p-col-12 p-md-1 p-fluid'>
                                                    <div className='p-grid form-control-group'>
                                                        <div className='p-col-12'>
                                                            <label>Enabled</label>
                                                        </div>
                                                        <div className='p-col-12'>
                                                        </div>
                                                        <div className='p-col-12 p-col-align-center'>
                                                            <Field name='status' type='checkbox' as={Checkbox} value={formik.values['status'] ? '1' : '0'} checked={formik.values['status'] == '1' ? true : false} />
                                                        </div>
                                                        <div className='p-col-12'>
                                                            <ErrorMessage name='status' component={FormErrorMsg} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : deleteConfirm ? (
                                            <div className='p-grid p-align-center'>
                                                <div className='p-col-12 p-md-8 delete-question'>
                                                    Are you sure you want to delete "{quickData?.product_description[1].name}"?
                                            </div>
                                                <div className='p-col-12 p-md-4 quick-action-section'>
                                                    <Button label='Yes' className='p-button-secondary' onClick={(e) => deleteProduct(props.data.product_id)} />
                                                    <Button label='Cancel' className='p-button-secondary' onClick={(e) => setDeleteConfirm(false)} />
                                                </div>
                                            </div>
                                        ) : (

                                            <div className='p-grid p-align-center'>
                                                <div className='p-col-12 p-md-2'>
                                                    <div>{quickData?.product_description[1].name} <b>{quickData?.deal > 0 ? "(Deal)" : ''}</b></div>
                                                </div>
                                                <div className='p-col-6 p-md-2'>
                                                    <div>{quickData?.ec_product_id} </div>
                                                </div>
                                                <div className='p-col-6 p-md-2'>
                                                    <div>{quickData?.product_description[1].shortcode}</div>
                                                </div>
                                                <div className='p-col-6 p-md-2'>
                                                    <div>{quickData?.model}</div>
                                                </div>
                                                <div className='p-col-6 p-md-2'>
                                                    <div>{quickData?.price}</div>
                                                </div>
                                                <div className='p-col-6 p-md-2'>
                                                    <div>{quickData?.status == '1' ? <span style={{ color: 'green' }}>Enabled</span> : <span style={{ color: 'gray' }}>Disabled</span>}</div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='p-col-12 p-md-3'>
                                        <div className='quick-action-section'>
                                            {editMode ? <>
                                                <Button label='Save' type='submit' className='p-button-primary' />
                                                <Button label='Cancel' className='p-button-secondary' onClick={(e) => setEditMode(!editMode)} />
                                            </> : deleteConfirm ? null : <>
                                                <a onClick={() => { setEditMode(!editMode); return false }}>Quick Edit</a>
                                                <div className='inline-spacer' />
                                                <a onClick={() => { setDeleteConfirm(true); return false }}>Delete</a>
                                                <div className='inline-spacer' />
                                                {/* <Link to={quickData?.deal ?
                                                    quickData?.deal == 0 || '' ? `/productform/edit/${props?.data?.product_id}` : `/deals/edit/${props?.data?.product_id}` :
                                                    `/productform/edit/${props?.data?.product_id}`

                                                } >Edit</Link> */}

                                                <Link to={quickData?.deal > 0 ? `/deals/edit/${props?.data?.product_id}` : `/productform/edit/${props?.data?.product_id}`} >
                                                    Edit
                                                </Link>

                                            </>
                                            }
                                        </div>
                                    </div>
                                </>

                            }
                        </div>
                    </Card>
                </Form>
            }
        </Formik >
    )
})

export { ProductList };
